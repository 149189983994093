import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import Sheet from "react-modal-sheet";

import "react-datepicker/dist/react-datepicker.css";

import {
  CheckIcon,
  SearchIcon,
  ArrowSmRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import { toggleLabelManager } from "../../../redux/appSlice";

import { updateTask } from "../../../redux/tasksSlice";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";

export default function LabelPicker({
  item,
  isOpen,
  setIsOpen,
  updateItemLocal,
}) {
  const dispatch = useDispatch();

  const { data: labels } = useSelector((state) => state.labels);

  function updateLabel(newValue) {
    if (updateItemLocal) {
      updateItemLocal(newValue);
      setIsOpen(false);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            label: newValue,
          },
        })
      );
    }
  }

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <LabelSelectorContentMobile
            label={item.label}
            updateLabel={updateLabel}
            labels={labels}
            setLabelPickerActive={setIsOpen}
          />
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}

export function LabelSelectorContentMobile({
  label,
  updateLabel,
  labelPickerActive,
  setLabelPickerActive,
  labels,
  loading,
}) {
  const searchRef = useRef();

  const [createLabelActive, setCreateLabelActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="label-selector-container"
    >
      <div className="label-selector-search">
        <MagnifyingGlassIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
          ref={searchRef}
        />
      </div>
      <div className="label-selector-options">
        {loading && <div>Loading Labels</div>}

        {!createLabelActive && !loading && labels && (
          <div>
            {Object.values(labels)
              .filter(function (label) {
                if (
                  label.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
              .map((labelOption, index) => {
                return (
                  <div
                    key={labelOption.id + "label_option"}
                    className={"label-selector-option"}
                    onClick={() => {
                      updateLabel(labelOption.id);
                      setLabelPickerActive(false);
                    }}
                  >
                    <div
                      className="label-box"
                      style={{ backgroundColor: labelOption.color }}
                    />

                    <span> {labelOption.name}</span>
                  </div>
                );
              })}

            <div
              className="create-label-button-container"
              onClick={() => {
                dispatch(toggleLabelManager());
                setLabelPickerActive(false);
              }}
            >
              Edit Labels
              <ArrowSmallRightIcon className="edit-labels-arrow-icon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
