import "./Auth.css";

import { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import logo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (email) => {
    const auth = getAuth();
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // ...
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("erorr: ", error);
        setLoading(false);
        alert(errorMessage || errorCode);
      });
  };

  const handlePassReset = async () => {
    setLoading(true);

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setLoading(false);
        setForgotPasswordActive(false);
        alert("We just sent an email to reset your password 🙌");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setLoading(false);
        setForgotPasswordActive(false);
        alert(errorMessage || errorCode);
      });
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (forgotPasswordActive) {
        handlePassReset();
      } else {
        handleLogin(email);
      }
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-left">
        <div className="auth-left-container">
          <img src={logo} className="auth-logo" alt="Ellie Logo" />
          <div className="auth-modal-container">
            {forgotPasswordActive ? (
              <h1 className="header">Reset Password 🤫</h1>
            ) : (
              <h1 className="header">Welcome back 👋</h1>
            )}

            {forgotPasswordActive ? (
              <>
                <p className="description">
                  Forgot your password? No worries, we'll send you an email with
                  a link to reset it!
                </p>
                <div>
                  <input
                    className="auth-input"
                    type="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>

                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassReset();
                    }}
                    className="auth-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>Loading</span>
                    ) : (
                      <span>Reset Password</span>
                    )}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setForgotPasswordActive(false)}
                    className="auth-button-thin"
                  >
                    Back to Login
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="description">
                  Sign in via your email and password to continue
                </p>
                <div>
                  <input
                    className="auth-input"
                    type="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>
                <div>
                  <input
                    className="auth-input"
                    type="password"
                    placeholder="Your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={keyPress}
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogin(email);
                    }}
                    className="auth-button"
                    disabled={loading}
                  >
                    {loading ? <span>Loading</span> : <span>Log in</span>}
                  </button>
                </div>
                <div className="auth-meta">
                  <button
                    onClick={() => setForgotPasswordActive(true)}
                    className="auth-button-thin"
                  >
                    Forgot password?
                  </button>
                  <span className="or-spacer">or</span>

                  <button
                    onClick={() => navigate("/signup")}
                    className="auth-button-thin"
                    style={{ marginTop: "0px" }}
                  >
                    Create Account
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="auth-right"></div>
    </div>
  );
}
