import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.css";
import "./DatePickerDropdown.css";

import { useSelector, useDispatch } from "react-redux";

import {
  changeStartDate,
  loadDatesFromStartDate,
  refreshKabanCursor,
} from "../../../redux/appSlice";
import { TbCalendar, TbTag } from "react-icons/tb";
import { Popover } from "antd";

export default function DatePickerDropdown({
  isVisible,
  setIsVisible,
  dateSelected,
  onDateChange,
}) {
  // Check if start_date is instanceof firebase timestamp, if so, convert to date
  if (dateSelected.toDate) {
    dateSelected = dateSelected.toDate();
  }

  const dateText = moment(dateSelected).format("MMM Do YYYY");

  // If dateSelected is today, set text to "Today", if tomorrow, set text to "Tomorrow", if yesterday, set text to "Yesterday", else set text to dateSelected
  const dateTextToDisplay = moment(dateSelected).isSame(moment(), "day")
    ? `Today, ${moment(dateSelected).format("h:mma")}`
    : moment(dateSelected).isSame(moment().add(1, "day"), "day")
    ? `Tomorrow, ${moment(dateSelected).format("h:mma")}`
    : moment(dateSelected).isSame(moment().subtract(1, "day"), "day")
    ? `Yesterday, ${moment(dateSelected).format("h:mma")}`
    : moment(dateSelected).format("MMM D, h:mma");

  // If date selected is a firestore timestamp, convert to date
  const dateSelectedToDisplay = moment(dateSelected).isValid()
    ? moment(dateSelected).toDate()
    : dateSelected;

  return (
    <Popover
      visible={isVisible}
      onVisibleChange={(visible) => {
        setIsVisible(visible);
      }}
      destroyTooltipOnHide={true}
      title={null}
      content={
        <div>
          <DatePicker
            selected={moment(dateSelected || new Date(), "YYYY-MM-DD").toDate()}
            onChange={onDateChange}
            selectsRange={false}
            inline
            timeInputLabel={null}
            showTimeInput
          />
        </div>
      }
      placement="bottom"
      trigger="click"
    >
      <div setIsVisible={true} className="mcf-button">
        {dateTextToDisplay}
      </div>
    </Popover>
  );
}
