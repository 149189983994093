import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { db } from "../firebase";
import { RRule, RRuleSet, rrulestr } from "rrule";
import {
  doc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
  writeBatch,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { updateCurrentUser } from "./appSlice";

import { isGhostTask } from "../utils";
import {
  createRecurringTask,
  deleteRecurringTask,
} from "./recurringTasksSlice";

import { addMinutes } from "date-fns";

const initialState = {
  data: {},
  recurringTasks: {},
  order: {},
  loading: true,
  orderLoading: true,
  brainDumpLoading: false,
  ghostTasksLoaded: false,
  dateRangesLoaded: {
    taskOrders: [],
    ghostTasks: [],
  },
  calendarDate: moment().format("YYYY-MM-DD"),
};

export const updateTaskOrder = createAsyncThunk(
  "tasks/updateTaskOrder",
  async (
    { date, order, previousOrder },
    { dispatch, getState, rejectWithValue }
  ) => {
    const userId = getState().app.uid;

    try {
      // We need to check if there are any "ghost" recurring tasks that need to be created
      // Let's iterate through the order and see if there are any recurring tasks

      if (date !== "brain_dump") {
        // First, let's get the recurring tasks
        const recurringTasks = getState().tasks.recurringTasks;

        // Let's get all tasks
        const tasks = getState().tasks.data;

        // Now, let's iterate through the order and see if there are any recurring tasks
        order.forEach((taskId) => {
          var currentTask = tasks[taskId];
          if (currentTask && currentTask.recurring) {
            var recurringTask = recurringTasks[currentTask.recurring_id];

            if (
              recurringTask &&
              (!recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(taskId))
            ) {
              // This is a recurring task that has not been branched off yet
              // Lets branch it off

              dispatch(
                convertGhostTaskToTask({
                  ghostTask: currentTask,
                  saveOrder: false,
                })
              );
            }
          }
        });
      }

      await setDoc(
        doc(db, "users", userId, "task_order", date),
        {
          order: order,
          date:
            date !== "brain_dump" ? moment(date, "YYYY-MM-DD").toDate() : null,
        },
        {
          merge: true,
        }
      );

      return { date, order, previousOrder };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkUpdateTaskOrder = createAsyncThunk(
  "tasks/bulkUpdateTaskOrder",
  async (
    { newOrder, previousOrder },
    { dispatch, getState, rejectWithValue }
  ) => {
    const userId = getState().app.uid;

    try {
      const batch = writeBatch(db);

      const recurringTasks = getState().tasks.recurringTasks;

      // Let's get all tasks
      const tasks = getState().tasks.data;

      newOrder.forEach((updatedOrder) => {
        // Get date in format YYYY-MM-DD

        // We need to check if there are any "ghost" recurring tasks that need to be materialized
        updatedOrder.order.forEach((taskId) => {
          var currentTask = tasks[taskId];
          if (currentTask && currentTask.recurring) {
            var recurringTask = recurringTasks[currentTask.recurring_id];

            if (
              recurringTask &&
              (!recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(taskId))
            ) {
              // This is a recurring task that has not been branched off yet
              // Lets branch it off

              dispatch(
                convertGhostTaskToTask({
                  ghostTask: currentTask,
                  saveOrder: false,
                })
              );
            }
          }
        });

        const dateString =
          updatedOrder.date !== "brain_dump"
            ? moment(updatedOrder.date, "YYYY-MM-DD").format("YYYY-MM-DD")
            : "brain_dump";

        const taskOrderRef = doc(db, "users", userId, "task_order", dateString);

        if (dateString === "brain_dump") {
          batch.set(
            taskOrderRef,
            {
              order: updatedOrder.order,
              date: null,
            },
            { merge: true }
          );
        } else {
          batch.set(
            taskOrderRef,
            {
              ...updatedOrder,
            },
            { merge: true }
          );
        }
      });

      await batch.commit();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async (
    {
      taskId,
      currentTask,
      newData,
      saveGhostOrder = true,
      updateOrder = false,
    },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      // Might need to convert the date to timestamp
      // Also handle null case for braindump

      const userId = getState().app.uid;

      // Let's also add newData to current task
      var currentTaskClone = { ...currentTask, ...newData };

      // If this is a recurring task, lets check if it has been branched off yet
      if (currentTask.recurring) {
        var recurringTask =
          getState().tasks.recurringTasks[currentTask.recurring_id];

        // Check if the "date" key exists in newData and is null
        if (newData.date === null) {
          // This means we are removing the date and moving to brain dump
          // Let's go ahead and also remove the "recurring" and "recurring_id"
          // keys from the current task
          delete currentTaskClone.recurring;
          newData = {
            ...newData,
            recurring: null,
            recurring_id: null,
          };
        }

        if (
          recurringTask &&
          (!recurringTask.branched_tasks ||
            !recurringTask.branched_tasks?.includes(taskId))
        ) {
          // This is a recurring task that has not been branched off yet
          // Lets branch it off

          dispatch(
            convertGhostTaskToTask({
              ghostTask: currentTaskClone,
              saveOrder: saveGhostOrder,
              customExclusionDate:
                newData.date || newData.date === null ? currentTask.date : null,
            })
          );
        }
      }

      // Let's check if we need to update the order
      if (updateOrder) {
        // Get the previous date (date object) and convert to YYYY-MM-DD format
        const previousDateString = currentTask.date
          ? moment(currentTask.date).format("YYYY-MM-DD")
          : "brain_dump";

        // Get the new date (date object) and convert to YYYY-MM-DD format
        const newDateString = newData.date
          ? moment(newData.date).format("YYYY-MM-DD")
          : "brain_dump";

        let newOrder = _.cloneDeep(
          getState().tasks.order[newDateString]?.order || []
        );

        // If the previous date is different from the new date, we need to update the order
        if (previousDateString !== newDateString) {
          // Let's remove the task from the previous order

          let newOldOrder = _.cloneDeep(
            getState().tasks.order[previousDateString]?.order || []
          );

          newOldOrder = newOldOrder.filter((id) => id !== taskId);

          dispatch(
            updateTaskOrder({
              date: previousDateString,
              order: newOldOrder,
            })
          );
        } else {
          // Let's remove it from the new order so we can re-order
          newOrder = newOrder.filter((id) => id !== taskId);
        }

        // Let's add the task to the new order
        if (newDateString) {
          // Go through the new order and insert it between the tasks where start time is before and after it
          // start is a javascript date object
          var inserted = false;

          // Go through newOrder and remove any tasks that are null in getState().tasks.data
          newOrder = newOrder.filter((id) => getState().tasks.data[id]);

          // Go through each one and to find where to insert it
          for (var i = 0; i < newOrder.length; i++) {
            var taskToCompare = getState().tasks.data[newOrder[i]];

            // Get the start time of the task to compare
            var taskToCompareStartTime = taskToCompare.start;

            // If its null, let's skip it
            if (!taskToCompareStartTime) {
              continue;
            }

            // If date selected is a firestore timestamp, convert to date
            if (taskToCompareStartTime.toDate) {
              taskToCompareStartTime = taskToCompareStartTime.toDate();
            }

            // Get the start time of the new task
            var newTaskStartTime = newData.start;

            // If the new task start time is after the task to compare
            if (newTaskStartTime > taskToCompareStartTime) {
              // Let's check if the newTaskStartTime is before the start time of the next task with a valid start time
              // If it is, we can insert it here
              var nextTaskStartTime = null;

              // Go through the rest of the tasks and find the next one with a valid start time
              for (var j = i + 1; j < newOrder.length; j++) {
                var nextTaskToCompare = getState().tasks.data[newOrder[j]];

                // Get the start time of the task to compare
                nextTaskStartTime = nextTaskToCompare.start;

                // If date selected is a firestore timestamp, convert to date
                if (nextTaskStartTime && nextTaskStartTime.toDate) {
                  nextTaskStartTime = nextTaskStartTime.toDate();
                }

                // If its not null, we can break out of the loop
                if (nextTaskStartTime) {
                  break;
                }

                // If we get to the end of the loop and nextTaskStartTime is still null, we can insert it here
                if (j === newOrder.length - 1) {
                  nextTaskStartTime = null;
                }
              }

              // If the newTaskStartTime is null, we can insert it here
              if (!nextTaskStartTime) {
                newOrder.splice(i + 1, 0, taskId);
                inserted = true;
                break;
              }

              // If the newTaskStartTime is before the nextTaskStartTime, we can insert it after
              if (newTaskStartTime < nextTaskStartTime) {
                newOrder.splice(i + 1, 0, taskId);
                inserted = true;
                break;
              }
            }
          }

          if (!inserted) {
            newOrder.unshift(taskId);
          }

          dispatch(
            updateTaskOrder({
              date: newDateString,
              order: newOrder,
            })
          );
        }
      }

      window.mixpanel.track("Task updated", {
        task_id: taskId,
        fields_updated: Object.keys(newData),
      });

      await setDoc(doc(db, "users", userId, "tasks", taskId), newData, {
        merge: true,
      });

      return fulfillWithValue({ taskId, newTask: currentTaskClone });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const convertGhostTaskToTask = createAsyncThunk(
  "tasks/convertGhostTaskToTask",
  async (
    { ghostTask, saveOrder, customExclusionDate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const ghostTaskClone = _.cloneDeep(ghostTask);
      const userId = getState().app.uid;

      var recurringTask =
        getState().tasks.recurringTasks[ghostTaskClone.recurring_id];

      // So lets branch it off and append the taskId to the branched_tasks array
      const newBranchedTasks = (recurringTask.branched_tasks || []).concat(
        ghostTaskClone.id
      );

      // Let's update exclusions to include this
      // Get date in format YYYY-MM-DD
      const dateString = moment(
        customExclusionDate ? customExclusionDate : ghostTaskClone.date
      ).format("YYYY-MM-DD");

      const newExclusions = (recurringTask.exclusions || []).concat(dateString);

      dispatch(
        updateRecurringTask({
          recurringTaskId: recurringTask.id,
          currentRecurringTask: recurringTask,
          newData: {
            branched_tasks: newBranchedTasks,
            exclusions: newExclusions,
          },
        })
      );

      if (saveOrder) {
        // Let's also add it to the relevant task order by saving
        // Get date in format YYYY-MM-DD
        const dateString = moment(ghostTaskClone.date).format("YYYY-MM-DD");
        let newOrder = _.cloneDeep(
          getState().tasks.order[dateString]?.order || []
        );

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: newOrder,
            previousOrder: newOrder,
          })
        );
      }

      await setDoc(
        doc(db, "users", userId, "tasks", ghostTaskClone.id),
        ghostTaskClone,
        {
          merge: true,
        }
      );
    } catch (error) {
      console.log("error is ", error);
      return rejectWithValue(error);
    }
  }
);

export const updateRecurringTask = createAsyncThunk(
  "tasks/updateRecurringTask",
  async (
    { recurringTaskId, currentRecurringTask, newData },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const userId = getState().app.uid;

      await setDoc(
        doc(db, "users", userId, "recurring_tasks", recurringTaskId),
        newData,
        {
          merge: true,
        }
      );

      const dates = getState().app.dates;
      return fulfillWithValue({ recurringTaskId, dates });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const bulkUpdateTasks = createAsyncThunk(
  "tasks/bulkUpdateTasks",
  async ({ newData, previousData }, { getState, rejectWithValue }) => {
    try {
      const batch = writeBatch(db);

      const userId = getState().app.uid;

      newData.forEach((updatedTask) => {
        const taskRef = doc(db, "users", userId, "tasks", updatedTask.id);
        batch.set(
          taskRef,
          {
            ...updatedTask,
          },
          { merge: true }
        );
      });

      await batch.commit();

      return { newData, previousData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkDeleteTasks = createAsyncThunk(
  "tasks/bulkDeleteTasks",
  async ({ tasksToDelete, previousData }, { getState, rejectWithValue }) => {
    try {
      const batch = writeBatch(db);

      const userId = getState().app.uid;

      tasksToDelete.forEach((taskToDelete) => {
        const taskRef = doc(db, "users", userId, "tasks", taskToDelete.id);
        batch.delete(taskRef);
      });

      await batch.commit();

      return { tasksToDelete, previousData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async (newTask, { dispatch, getState, rejectWithValue }) => {
    const { new_task_position = "top" } = getState().app.currentUser;

    window.mixpanel.track("Task created", {
      task_id: newTask.id,
      added_to: !newTask.date ? "braindump" : "date",
      fields_updated: Object.keys(newTask),
      recurring: newTask.recurring,
    });

    try {
      // Get date in format YYYY-MM-DD
      const dateString = newTask.date
        ? moment(newTask.date).format("YYYY-MM-DD")
        : "brain_dump";

      let newOrder = _.cloneDeep(
        getState().tasks.order[dateString]?.order || []
      );

      if (new_task_position === "top") {
        newOrder.unshift(newTask.id);

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: newOrder,
            previousOrder: getState().tasks.order[dateString]?.order || [],
          })
        );
      } else {
        dispatch(
          moveTaskToBottomOfIncomplete({
            taskId: newTask.id,
            date: dateString,
          })
        );
      }

      const userId = getState().app.uid;
      await setDoc(
        doc(db, "users", userId, "tasks", newTask.id),
        {
          ...newTask,
          created_at: new Date(),
        },
        {
          merge: true,
        }
      );

      return newTask;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async ({ taskId, currentTask }, { dispatch, getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    try {
      // We need to check if this is a ghost task, if so, add an exclusion
      // If this is a recurring task, lets check if it has been branched off yet
      if (currentTask.recurring) {
        var recurringTask =
          getState().tasks.recurringTasks[currentTask.recurring_id];

        if (
          recurringTask &&
          (!recurringTask.branched_tasks ||
            !recurringTask.branched_tasks?.includes(taskId))
        ) {
          // No need to branch it off since it is going to be deleted
          // but let's add it to the exclusions

          let newExclusions = _.cloneDeep(recurringTask.exclusions || []);

          // Get date in format YYYY-MM-DD
          const dateString = moment(currentTask.date).format("YYYY-MM-DD");

          newExclusions.push(dateString);

          dispatch(
            updateRecurringTask({
              recurringTaskId: recurringTask.id,
              currentRecurringTask: recurringTask,
              newData: {
                exclusions: newExclusions,
              },
            })
          );

          return;
        }
      }

      window.mixpanel.track("Task deleted", {
        task_id: taskId,
      });

      await deleteDoc(doc(db, "users", userId, "tasks", taskId));

      return currentTask;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Move a task to the bottom of the date
export const moveTaskToBottom = createAsyncThunk(
  "tasks/moveTaskToBottom",
  async ({ taskId, date }, { dispatch, getState, rejectWithValue }) => {
    // Get the current order
    let currentOrder = _.cloneDeep(getState().tasks.order[date]?.order || []);

    // Remove the task from the current order
    currentOrder = currentOrder.filter((task) => task !== taskId);

    // Add the task to the bottom of the order
    currentOrder = [...currentOrder, taskId];

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: date,
        order: currentOrder,
        previousOrder: getState().tasks.order[date]?.order || [],
      })
    );

    return;
  }
);

// Move a task to the bottom of the date
export const moveTaskToBottomOfIncomplete = createAsyncThunk(
  "tasks/moveTaskToBottomOfIncomplete",
  async ({ taskId, date }, { dispatch, getState, rejectWithValue }) => {
    // Get the current order
    let currentOrder = _.cloneDeep(getState().tasks.order[date]?.order || []);

    // Get all tasks
    const allTasks = getState().tasks.data;

    // Remove the task from the current order
    currentOrder = currentOrder.filter((task) => task !== taskId);

    // Iterate through the current order and find the first complete task index
    let firstCompleteTaskIndex = currentOrder.findIndex((task) => {
      return allTasks[task] && allTasks[task].complete;
    });

    // If there is no complete task, add the task to the bottom of the order
    if (firstCompleteTaskIndex === -1) {
      currentOrder = [...currentOrder, taskId];
    } else {
      // Otherwise, add the task before the first complete task
      currentOrder.splice(firstCompleteTaskIndex, 0, taskId);
    }

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: date,
        order: currentOrder,
        previousOrder: getState().tasks.order[date]?.order || [],
      })
    );

    return;
  }
);

// Move a task from the brain dump to the bottom of the date
export const moveTaskToBottomFromBrainDump = createAsyncThunk(
  "tasks/moveTaskToBottomFromBrainDump",
  async ({ taskId }, { dispatch, getState, rejectWithValue }) => {
    // Get the current braindump
    let currentBrainDumpOrder = _.cloneDeep(
      getState().tasks.order["brain_dump"]?.order || []
    );

    // Remove the task from the current braindump
    currentBrainDumpOrder = currentBrainDumpOrder.filter(
      (task) => task !== taskId
    );

    // Get the current order for today
    let currentOrder = _.cloneDeep(
      getState().tasks.order[moment().format("YYYY-MM-DD")]?.order || []
    );

    // Add the task to the bottom of the order
    currentOrder = [...currentOrder, taskId];

    // Update the task order
    dispatch(
      updateTaskOrder({
        date: moment().format("YYYY-MM-DD"),
        order: currentOrder,
        previousOrder:
          getState().tasks.order[moment().format("YYYY-MM-DD")].order || [],
      })
    );

    dispatch(
      updateTaskOrder({
        date: "brain_dump",
        order: currentBrainDumpOrder,
        previousOrder: getState().tasks.order["brain_dump"].order || [],
      })
    );
    return;
  }
);

// Move a task from the brain dump to the bottom of the date
export const processRecurringTasks = createAsyncThunk(
  "tasks/processRecurringTasks",
  async (
    { recurringTasks, dates, override },
    { dispatch, getState, rejectWithValue }
  ) => {
    var recurringTasksToRegenerateGhosts = [];

    // Go through each recurring task and see if task_template or rrule is different
    recurringTasks.forEach((incomingRecurringTask) => {
      // Get the existing recurring task
      const existingRecurringTask =
        getState().tasks.recurringTasks[incomingRecurringTask.id];

      // If the task_template or rrule is different, add it to the recurringTasksToRegenerateGhosts
      if (
        !existingRecurringTask ||
        !_.isEqual(
          incomingRecurringTask.task_template,
          existingRecurringTask.task_template
        ) ||
        !_.isEqual(incomingRecurringTask.rrule, existingRecurringTask.rrule) ||
        override
      ) {
        recurringTasksToRegenerateGhosts.push(incomingRecurringTask);
      }
    });

    if (recurringTasksToRegenerateGhosts.length > 0) {
      // Regenerate the ghosts for the recurring tasks

      recurringTasksToRegenerateGhosts.forEach((recurringTask) => {
        dispatch(
          reloadGhostTasksForDates({
            recurringTask,
            dates,
          })
        );
      });
    }

    dispatch(
      addRecurringTasks({
        recurringTasks,
        dates,
      })
    );

    return;
  }
);

// Move a task from the brain dump to the bottom of the date
export const processTaskOrders = createAsyncThunk(
  "tasks/processTaskOrders",
  async (
    { taskOrder, dates, override },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Get current task order to process (clone it)
    var processedTaskOrder = _.cloneDeep({
      ...getState().tasks.order,
      ..._.keyBy(taskOrder, "id"),
    });

    const calendarDate = getState().tasks.calendarDate;

    // 1. Remove any dates that are not in the taskOrder
    // Ignore the brain dump and calendare Date
    if (dates) {
      Object.keys(processedTaskOrder).forEach((date) => {
        if (
          !dates.includes(date) &&
          date !== "brain_dump" &&
          date !== calendarDate
        ) {
          delete processedTaskOrder[date];
        }
      });

      // Add dates that are in dates but not processedTaskOrder
      dates.forEach((date) => {
        if (!processedTaskOrder[date]) {
          processedTaskOrder[date] = {
            order: [],
            id: date,
            date: moment(date, "YYYY-MM-DD").toDate(),
          };
        }
      });
    }

    // 2. Let's filter out any duplicates
    var previouslySeenTasks = [];
    // We are going to filter out any duplicate tasks
    // First, get a list of orderToFilter keys and order them by date (descending)
    // We do this so that the duplicates are removed and we default to the most recent date

    var orderToFilterKeys = Object.keys(processedTaskOrder).sort(
      (a, b) =>
        moment(b, "YYYY-MM-DD").valueOf() - moment(a, "YYYY-MM-DD").valueOf()
    );

    orderToFilterKeys.forEach((date) => {
      if (processedTaskOrder[date] && processedTaskOrder[date].order) {
        processedTaskOrder[date].order = processedTaskOrder[date].order.filter(
          (task) => {
            if (previouslySeenTasks.includes(task)) {
              return false;
            }

            previouslySeenTasks.push(task);

            return true;
          }
        );
      }
    });

    // 3. Update the task order
    dispatch(
      addTaskOrder({
        taskOrders: processedTaskOrder,
        dates,
      })
    );

    return;
  }
);

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    removeTaskFromOrder: (state, action) => {
      const { date, taskId } = action.payload;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      const currentOrder = _.cloneDeep(state.order[date]?.order || []);

      const newOrder = currentOrder.filter((task) => task !== taskId);

      state.order[date].order = newOrder;
    },
    changeCalendarDate(state, action) {
      state.calendarDate = action.payload.date;
    },
    addTaskOrder: (state, action) => {
      const { taskOrders, dates } = action.payload;

      // 1. Get the current task order
      const taskOrdersEditable = _.cloneDeep(taskOrders);
      // These are the tasks we are going to add
      var tasksToAdd = [];

      // TODO: REMOVE THIS TO ACCOUNT FOR CALENDAR DATES
      // 2. Go through each task order and remove any ghost tasks
      if (dates) {
        Object.keys(taskOrdersEditable).forEach((date) => {
          taskOrdersEditable[date].order = taskOrdersEditable[
            date
          ].order.filter((taskId) => {
            // TODO: REMOVE THIS TO ACCOUNT FOR CALENDAR DATES
            // If the task is a ghost task, remove it
            if (
              !isGhostTask({
                task: state.data[taskId],
                recurringTasks: state.recurringTasks,
              })
            ) {
              return true;
            } else {
              return false;
            }
          });
        });
      }

      // TODO: REMOVE THIS TO ACCOUNT FOR CALENDAR DATES
      if (dates) {
        // 3. Generate all ghost tasks and add them to relevant task orders
        // Also add the relevant tasks to the array
        Object.values(state.recurringTasks).forEach((recurringTask) => {
          // From the recurringTask's rrule, get the RRule object
          const rruleObject = rrulestr(recurringTask.rrule);

          // Get all ocurrence dates between the date ranges
          // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
          const startDate = moment(dates[0], "YYYY-MM-DD")
            .startOf("day")
            .toDate();

          // End date is the last day in dates
          const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
            .endOf("day")
            .toDate();

          const tzOffset = new Date().getTimezoneOffset();

          const fromRRuleOutput = (date) => {
            return addMinutes(date, tzOffset);
          };

          const ocurrenceDates = rruleObject
            .between(startDate, endDate)
            .map(fromRRuleOutput);

          // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
          const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
            return moment(date).format("YYYY-MM-DD");
          });

          ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
            // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
            if (recurringTask.exclusions?.includes(ocurrenceDate)) {
              return;
            }

            // Otherwise, create a new task
            const newTask = {
              ...recurringTask.task_template,
              id: uuidv4(),
              complete: false,
              recurring: true,
              recurring_id: recurringTask.id,
              date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
            };

            // Add the new task to the tasksToAdd array
            tasksToAdd.push(newTask);

            if (taskOrdersEditable[ocurrenceDate]) {
              // Add the new task to the task order
              taskOrdersEditable[ocurrenceDate].order.unshift(newTask.id);
            } else {
              // If it doesn't exist, create it
              taskOrdersEditable[ocurrenceDate] = {
                date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
                order: [newTask.id],
                id: ocurrenceDate,
              };
            }
          });
        });
      }

      // 4. Add the tasks to the state
      state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };

      // 5. Update the task order
      state.order = taskOrdersEditable;
    },
    reloadGhostTasksForDates: (state, action) => {
      const { recurringTask, dates } = action.payload;

      var taskOrderEditable = _.cloneDeep(state.order);

      // These are the tasks we are going to add
      var tasksToAdd = [];

      // Go through the dates and let's remove the ghost tasks from the task order
      // That match the recurring task
      dates.forEach((date) => {
        if (taskOrderEditable[date]) {
          if (!taskOrderEditable[date].order) {
            taskOrderEditable[date].order = [];
          } else {
            var ordersToDelete = [];

            if (
              taskOrderEditable[date].order &&
              taskOrderEditable[date].order.length > 0
            ) {
              taskOrderEditable[date].order.forEach(function (taskId) {
                const task = state.data[taskId];

                if (task && task.recurring_id == recurringTask.id) {
                  if (
                    isGhostTask({
                      task: state.data[taskId],
                      recurringTasks: state.recurringTasks,
                    })
                  ) {
                    // Add taskId to the ordersToDelete array, without returning
                    ordersToDelete = [...ordersToDelete, taskId];
                  }
                }
              });
            }

            ordersToDelete.forEach((taskId) => {
              // Delete the taskId from the task order, using splice
              taskOrderEditable[date].order.splice(
                taskOrderEditable[date].order.indexOf(taskId),
                1
              );
            });
          }
        }
      });

      // From the recurringTask's rrule, get the RRule object
      const rruleObject = rrulestr(recurringTask.rrule);
      //  rruleObject.tzid = "UTC";

      // Lets print out the rruleObject to a readable string

      // Get the dstart of the recurring object
      const dstart = moment(recurringTask.dstart).toDate();

      // Change dstart to the utc timezone from the user's timezone
      // rruleObject.options.dtstart = dstartUTC
      const tzOffset = new Date().getTimezoneOffset();

      const fromRRuleOutput = (date) => {
        return addMinutes(date, tzOffset);
      };

      // Get all ocurrence dates between the date ranges
      // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
      const startDate = moment(dates[0], "YYYY-MM-DD").startOf("day").toDate();

      // End date is the last day in dates
      const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
        .endOf("day")
        .toDate();

      const ocurrenceDates = rruleObject
        .between(startDate, endDate)
        .map(fromRRuleOutput);

      // Assume that the ocurrenceDates are in UTC, convert them to the user's timezone
      const ocurrenceDatesFormatted2 = ocurrenceDates.map((date) => {
        var utcMomentDate = moment.utc(date);

        // Convert to the user's timezone
        var userTimezoneMomentDate = utcMomentDate.tz(moment.tz.guess());

        return userTimezoneMomentDate.format("YYYY-MM-DD");
      });

      // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
      const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
        return moment(date).format("YYYY-MM-DD");
      });

      ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
        // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
        if (recurringTask.exclusions?.includes(ocurrenceDate)) {
          return;
        }

        // Otherwise, create a new task
        const newTask = {
          ...recurringTask.task_template,
          id: uuidv4(),
          complete: false,
          recurring: true,
          recurring_id: recurringTask.id,
          date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
        };

        // Add the new task to the tasksToAdd array
        tasksToAdd.push(newTask);

        if (!taskOrderEditable[ocurrenceDate]) {
          // Add the new task to the task order
          taskOrderEditable[ocurrenceDate] = {
            date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
            order: [newTask.id],
            id: ocurrenceDate,
          };
        } else {
          // Add the new task to the top of task order
          taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
        }
      });

      state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
      state.order = taskOrderEditable;
    },
    loadRecurringTaskGhosts: (state, action) => {
      // Load recurring tasks from the state
      //  const { recurringTasks, taskOrder, taskData } = action.payload;
      const { dates } = action.payload;

      const taskOrderEditable = _.cloneDeep(state.order);

      // Go through the dates and let's remove the ghost tasks from the task order
      dates.forEach((date) => {
        if (taskOrderEditable[date]) {
          taskOrderEditable[date].order = taskOrderEditable[date].order.filter(
            (taskId) => {
              return !isGhostTask({
                task: state.data[taskId],
                recurringTasks: state.recurringTasks,
              });
            }
          );
        }
      });

      const ghostTaskDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.ghostTasks
      );

      // Get dates that are in dates but not in state
      const datesToLoad = dates.filter((date) => {
        return !ghostTaskDatesLoaded.includes(date);
      });

      // Iterate through the recurring tasks, and generate a new task for each
      Object.keys(state.recurringTasks).forEach((recurringTaskId) => {
        // Get the recurring task
        const recurringTask = state.recurringTasks[recurringTaskId];

        // From the recurringTask's rrule, get the RRule object
        const rruleObject = rrulestr(recurringTask.rrule);

        // Get all ocurrence dates between the date ranges
        // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
        const startDate = moment(dates[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate();

        // End date is the last day in dates
        const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate();
        const tzOffset = new Date().getTimezoneOffset();

        const fromRRuleOutput = (date) => {
          return addMinutes(date, tzOffset);
        };

        const ocurrenceDates = rruleObject
          .between(startDate, endDate)
          .map(fromRRuleOutput);

        // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
        const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
          // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
          if (recurringTask.exclusions?.includes(ocurrenceDate)) {
            return;
          }

          // Otherwise, create a new task
          const newTask = {
            ...recurringTask.task_template,
            id: uuidv4(),
            complete: false,
            recurring: true,
            recurring_id: recurringTaskId,
            date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
          };

          // Add the new task to the task data
          state.data[newTask.id] = newTask;

          if (!taskOrderEditable[ocurrenceDate]) {
            // Add the new task to the task order
            taskOrderEditable[ocurrenceDate] = {
              date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
              order: [newTask.id],
            };
          } else {
            // Add the new task to the top of task order
            taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
          }
        });

        state.order = taskOrderEditable;

        state.dateRangesLoaded.ghostTasks =
          ghostTaskDatesLoaded.concat(datesToLoad);
      });
    },
    addTaskToOrder: (state, action) => {
      const { date, taskId } = action.payload;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      const currentOrder = _.cloneDeep(state.order[date]?.order || []);

      const newOrder = [...currentOrder, taskId];

      state.order[date].order = newOrder;
    },
    addTasks: (state, action) => {
      const { tasks, dates } = action.payload;

      state.loading = false;
      state.data = { ...state.data, ..._.keyBy(tasks, "id") };

      // Remove any tasks that are not between the dates
      Object.keys(state.data).forEach((taskId) => {
        const task = state.data[taskId];

        if (
          dates &&
          task.date &&
          !dates?.includes(moment(task.date).format("YYYY-MM-DD")) &&
          moment(task.date).format("YYYY-MM-DD") !== state.calendarDate
        ) {
          delete state.data[taskId];
        }
      });
    },
    addRecurringTasks: (state, action) => {
      const { recurringTasks, dates } = action.payload;

      state.recurringTasks = {
        ...state.recurringTasks,
        ..._.keyBy(recurringTasks, "id"),
      };
    },
    removeTask: (state, action) => {
      const { taskId } = action.payload;

      // Delete the task from the data
      delete state.data[taskId];
    },
    removeRecurringTask: (state, action) => {
      const { recurringTask } = action.payload;

      // If it fulfilled, let's delete all the ghost tasks associated with it

      const recurringTaskId = recurringTask.id;

      // This is the taskOrder we are going to edit
      const taskOrderEditable = _.cloneDeep(state.order);

      // Go through each date and delete the ghost tasks
      Object.keys(taskOrderEditable).forEach((date) => {
        // Go through each task in the order
        taskOrderEditable[date].order.forEach((taskId, index) => {
          const task = state.data[taskId];

          // If the task is a ghost task, delete it from state
          if (task && task.recurring_id === recurringTaskId) {
            if (
              !recurringTask.branched_tasks ||
              !recurringTask.branched_tasks?.includes(task.id)
            ) {
              // Delete the task
              delete state.data[taskId];
              // Remove the task from the order
              taskOrderEditable[date].order.splice(index, 1);
            }
          }
        });
      });

      delete state.recurringTasks[recurringTask.id];
    },
    manuallySetOrderLoading: (state, action) => {
      const { loading, dates } = action.payload;

      const taskOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.taskOrders
      );

      const datesToLoad = dates.filter((date) => {
        return !taskOrdersDatesLoaded.includes(date);
      });

      state.dateRangesLoaded.taskOrders =
        taskOrdersDatesLoaded.concat(datesToLoad);

      state.orderLoading = loading;
    },
    removeDatesFromLoaded: (state, action) => {
      const { dates } = action.payload;

      const taskOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.taskOrders
      );

      const ghostOrdersDatesLoaded = _.cloneDeep(
        state.dateRangesLoaded.ghostTasks
      );

      if (taskOrdersDatesLoaded) {
        // Remove dates from the loaded dates
        state.dateRangesLoaded.taskOrders = taskOrdersDatesLoaded.filter(
          (date) => !dates.includes(date)
        );
      }

      if (ghostOrdersDatesLoaded) {
        state.dateRangesLoaded.ghostTasks = ghostOrdersDatesLoaded.filter(
          (date) => !dates.includes(date)
        );
      }
    },
    manuallySetTasksLoading: (state, action) => {
      const { loading } = action.payload;

      state.loading = loading;
    },
    addBraindumpOrder: (state, action) => {
      const { order } = action.payload;

      state.brainDumpOrder = false;
      state.order["brain_dump"] = {
        id: "brain_dump",
        order,
      };
    },
  },
  extraReducers: {
    [updateTask.pending]: (state, action) => {
      // Let's just update the store
      const { taskId, newData } = action.meta.arg;

      state.data[taskId] = { ...state.data[taskId], ...newData };
    },
    [updateTask.fulfilled]: (state, action) => {
      // Let's just update the store
      const { taskId, newTask } = action.payload;

      state.data[taskId] = newTask;
    },
    [updateTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { taskId, currentTask } = action.meta.arg;

      state.data[taskId] = currentTask;
    },
    [updateRecurringTask.pending]: (state, action) => {
      // Let's just update the store
      const { recurringTaskId, newData } = action.meta.arg;

      state.recurringTasks[recurringTaskId] = {
        ...state.recurringTasks[recurringTaskId],
        ...newData,
      };
    },
    [updateRecurringTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { recurringTaskId, currentRecurringTask } = action.meta.arg;

      state.recurringTasks[recurringTaskId] = currentRecurringTask;
    },
    [updateRecurringTask.fulfilled]: (state, action) => {
      const { recurringTaskId, currentRecurringTask, newData } =
        action.meta.arg;

      const { dates } = action.payload;

      var recurringTask = {
        ...state.recurringTasks[recurringTaskId],
        ...newData,
      };

      // If the new data includes a new rrule or task_template, then we need to update the ghosts
      if (newData.rrule || newData.task_template) {
        // Go through all the tasks and task orders and delete any ghosts
        // This is the taskOrder we are going to edit
        const taskOrderEditable = _.cloneDeep(state.order);

        Object.keys(taskOrderEditable).forEach((date) => {
          // Go through each task in the order
          taskOrderEditable[date].order.forEach((taskId, index) => {
            const task = state.data[taskId];

            // If the task is a ghost task, delete it from state
            if (task && task.recurring_id === recurringTaskId) {
              if (
                !recurringTask.branched_tasks ||
                !recurringTask.branched_tasks?.includes(task.id)
              ) {
                // Delete the task
                delete state.data[taskId];
                // Remove the task from the order
                taskOrderEditable[date].order.splice(index, 1);
              }
            }
          });
        });

        // Now we need to add the new ghosts

        var tasksToAdd = [];
        // From the recurringTask's rrule, get the RRule object
        const rruleObject = rrulestr(recurringTask.rrule);

        // Get all ocurrence dates between the date ranges
        // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
        const startDate = moment(dates[0], "YYYY-MM-DD")
          .startOf("day")
          .toDate();

        // End date is the last day in dates
        const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate();
        const tzOffset = new Date().getTimezoneOffset();

        const fromRRuleOutput = (date) => {
          return addMinutes(date, tzOffset);
        };

        const ocurrenceDates = rruleObject
          .between(startDate, endDate)
          .map(fromRRuleOutput);

        // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
        const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
          return moment(date).format("YYYY-MM-DD");
        });

        ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
          // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
          if (recurringTask.exclusions?.includes(ocurrenceDate)) {
            return;
          }

          // Otherwise, create a new task
          const newTask = {
            ...recurringTask.task_template,
            id: uuidv4(),
            complete: false,
            recurring: true,
            recurring_id: recurringTask.id,
            date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
          };

          // Add the new task to the tasksToAdd array
          tasksToAdd.push(newTask);

          if (!taskOrderEditable[ocurrenceDate]) {
            // Add the new task to the task order
            taskOrderEditable[ocurrenceDate] = {
              date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
              order: [newTask.id],
              id: ocurrenceDate,
            };
          } else {
            // Add the new task to the top of task order
            taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
          }
        });

        state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
        state.order = taskOrderEditable;
      }
    },
    [bulkUpdateTasks.pending]: (state, action) => {
      // Let's just update the store
      const { newData } = action.meta.arg;

      newData.forEach((taskData) => {
        state.data[taskData.id] = { ...state.data[taskData.id], ...taskData };
      });
    },
    [bulkUpdateTasks.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousData } = action.meta.arg;

      previousData.forEach((task) => {
        state.data[task.id] = task;
      });
    },
    [bulkDeleteTasks.pending]: (state, action) => {
      // Let's just update the store
      const { tasksToDelete } = action.meta.arg;

      tasksToDelete.forEach((task) => {
        delete state.data[task.id];
      });
    },
    [bulkDeleteTasks.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousData } = action.meta.arg;

      previousData.forEach((task) => {
        state.data[task.id] = task;
      });
    },
    [updateTaskOrder.pending]: (state, action) => {
      // Let's just update the store
      const { date, order } = action.meta.arg;

      if (!state.order) {
        state.order = {};
      }

      if (!state.order[date]) {
        state.order[date] = {
          date: date,
        };
      }

      state.order[date].order = order;
    },
    [updateTaskOrder.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { date, previousOrder } = action.meta.arg;

      if (!previousOrder) {
        // There was no previous order, just set it to blank
        state.order[date] = {};
      } else {
        state.order[date].order = previousOrder;
      }
    },
    [bulkUpdateTaskOrder.pending]: (state, action) => {
      // Let's just update the store
      const { newOrder } = action.meta.arg;

      if (!state.order) {
        state.order = {};
      }

      newOrder.forEach((order) => {
        // Get date in format YYYY-MM-DD
        const dateString =
          order.date === "brain_dump"
            ? "brain_dump"
            : moment(order.date).format("YYYY-MM-DD");

        if (!state.order[dateString]) {
          state.order[dateString] = {};
        }

        state.order[dateString].order = order.order;
      });
    },
    [bulkUpdateTaskOrder.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousOrder } = action.meta.arg;

      if (!state.order) {
        state.order = {};
      }

      previousOrder.forEach((order) => {
        // Get date in format YYYY-MM-DD
        const dateString =
          order.date === "brain_dump"
            ? "brain_dump"
            : moment(order.date).format("YYYY-MM-DD");

        if (!state.order[dateString]) {
          state.order[dateString] = {};
        }

        state.order[dateString].order = order.order;
      });
    },
    [convertGhostTaskToTask.fulfilled]: (state, action) => {
      // Let's just update the store

      const { ghostTask } = action.meta.arg;

      state.data[ghostTask.id] = ghostTask;
    },
    [createTask.pending]: (state, action) => {
      // Let's just update the store

      const newTask = action.meta.arg;

      state.data[newTask.id] = newTask;
    },
    [createTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const newTask = action.meta.arg;

      delete state.data[newTask.id];
    },
    [createRecurringTask.pending]: (state, action) => {
      // Let's just update the store

      const { recurringTask } = action.meta.arg;

      state.recurringTasks[recurringTask.id] = recurringTask;
    },
    [createRecurringTask.fulfilled]: (state, action) => {
      const { dates, recurringTask } = action.payload;

      // Now we need to add the new ghosts
      const taskOrderEditable = _.cloneDeep(state.order);

      // 2. Go through each task order and remove any ghost tasks
      Object.keys(taskOrderEditable).forEach((date) => {
        taskOrderEditable[date].order = taskOrderEditable[date].order.filter(
          (taskId) => {
            // If the task is a ghost task, remove it
            if (
              state.data[taskId] &&
              state.data[taskId].recurring_id === recurringTask.id &&
              isGhostTask({
                task: state.data[taskId],
                recurringTasks: state.recurringTasks,
              })
            ) {
              return false;
            } else {
              return true;
            }
          }
        );
      });

      var tasksToAdd = [];
      // From the recurringTask's rrule, get the RRule object
      const rruleObject = rrulestr(recurringTask.rrule);

      // Get all ocurrence dates between the date ranges
      // dates is an array of date strings with format YYYY-MM-DD, convert to date objects
      const startDate = moment(dates[0], "YYYY-MM-DD").startOf("day").toDate();

      // End date is the last day in dates
      const endDate = moment(dates[dates.length - 1], "YYYY-MM-DD")
        .endOf("day")
        .toDate();

      const tzOffset = new Date().getTimezoneOffset();

      const fromRRuleOutput = (date) => {
        return addMinutes(date, tzOffset);
      };

      const ocurrenceDates = rruleObject
        .between(startDate, endDate)
        .map(fromRRuleOutput);

      // Iterate through the ocurrence dates, convert to "YYYY-MM-DD" format
      const ocurrenceDatesFormatted = ocurrenceDates.map((date) => {
        return moment(date).format("YYYY-MM-DD");
      });

      ocurrenceDatesFormatted.forEach((ocurrenceDate) => {
        // Let's check the exlucions on recurringTask, if ocurrenceDate is excluded, skip it
        if (recurringTask.exclusions?.includes(ocurrenceDate)) {
          return;
        }

        // Otherwise, create a new task
        const newTask = {
          ...recurringTask.task_template,
          id: uuidv4(),
          complete: false,
          recurring: true,
          recurring_id: recurringTask.id,
          date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
        };

        // Add the new task to the tasksToAdd array
        tasksToAdd.push(newTask);

        if (!taskOrderEditable[ocurrenceDate]) {
          // Add the new task to the task order
          taskOrderEditable[ocurrenceDate] = {
            date: moment(ocurrenceDate, "YYYY-MM-DD").toDate(),
            order: [newTask.id],
            id: ocurrenceDate,
          };
        } else {
          // Add the new task to the top of task order
          taskOrderEditable[ocurrenceDate].order.unshift(newTask.id);
        }
      });

      state.data = { ...state.data, ..._.keyBy(tasksToAdd, "id") };
      state.order = taskOrderEditable;
    },
    [createRecurringTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const recurringTask = action.meta.arg;

      delete state.recurringTasks[recurringTask.id];
    },
    [deleteTask.pending]: (state, action) => {
      const { taskId } = action.meta.arg;

      delete state.data[taskId];
    },
    [deleteTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { taskId, currentTask } = action.meta.arg;

      state.data[taskId] = currentTask;
    },
    [deleteRecurringTask.pending]: (state, action) => {
      const { recurringTask } = action.meta.arg;

      delete state.recurringTasks[recurringTask.id];
    },
    [deleteRecurringTask.fulfilled]: (state, action) => {
      const { recurringTask } = action.meta.arg;

      // If it fulfilled, let's delete all the ghost tasks associated with it

      const recurringTaskId = recurringTask.id;

      // This is the taskOrder we are going to edit
      const taskOrderEditable = _.cloneDeep(state.order);

      // Go through each date and delete the ghost tasks
      Object.keys(taskOrderEditable).forEach((date) => {
        // Go through each task in the order
        taskOrderEditable[date].order.forEach((taskId, index) => {
          const task = state.data[taskId];

          // If the task is a ghost task, delete it from state
          if (task && task.recurring_id === recurringTaskId) {
            if (
              !recurringTask.branched_tasks ||
              !recurringTask.branched_tasks?.includes(task.id)
            ) {
              // Delete the task
              delete state.data[taskId];
              // Remove the task from the order
              taskOrderEditable[date].order.splice(index, 1);
            }
          }
        });
      });
    },
    [deleteRecurringTask.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { recurringTask } = action.meta.arg;

      state.recurringTasks[recurringTask.id] = recurringTask;
    },
  },
});

export const {
  removeTaskFromOrder,
  addTaskToOrder,
  addTasks,
  addRecurringTasks,
  removeTask,
  addTaskOrder,
  addBraindumpOrder,
  loadRecurringTaskGhosts,
  manuallySetOrderLoading,
  manuallySetTasksLoading,
  removeDatesFromLoaded,
  reloadGhostTasksForDates,
  removeRecurringTask,
  changeCalendarDate,
} = tasksSlice.actions;

export default tasksSlice.reducer;
