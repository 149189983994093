import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  SupportIcon,
  MailIcon,
  LightBulbIcon,
  LightningBoltIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

import { Popover, Modal } from "antd";
import { Default, Mobile } from "../../utils";

import "./HelpSection.css";

import KeyboardShortcuts from "./KeyboardShortcuts";
import { LifebuoyIcon } from "@heroicons/react/24/outline";

export default function HelpIcon() {
  const [shortcutsVisible, setShortcutsVisible] = useState(false);
  const [helpMenuVisible, setHelpMenuVisible] = useState(false);

  useEffect(() => {
    window.Canny("initChangelog", {
      appID: "637d2ce47a741d0ff12b8c61",
      position: "top",
      align: "right",
    });
  }, [helpMenuVisible]);

  return (
    <>
      <KeyboardShortcuts
        visible={shortcutsVisible}
        setVisible={setShortcutsVisible}
      />
      <Popover
        visible={helpMenuVisible}
        onVisibleChange={(visible) => {
          setHelpMenuVisible(visible);
        }}
        content={
          <div className="help-container">
            <div className="help-section">
              <a
                href="mailto:chris@aloa.co"
                target="_blank"
                className="help-option"
              >
                <LifebuoyIcon className="help-option-icon" />
                <div className="help-option-text">Contact Support (Chris)</div>
              </a>
              <a
                href="mailto:chris@aloa.co"
                target="_blank"
                className="help-option"
              >
                <LightBulbIcon className="help-option-icon" />
                <div className="help-option-text">Send feedback</div>
              </a>
            </div>
            <div className="help-section">
              <div
                onClick={() => {
                  setHelpMenuVisible(false);
                  setShortcutsVisible(true);
                }}
                className="help-option"
              >
                <div className="help-option-text">Keyboard shortcuts</div>
              </div>
              <a className="help-option" data-canny-changelog>
                <div className="help-option-text">What's new?</div>
              </a>
              <a
                href="https://feedback.ellieplanner.com/feature-requests"
                target="_blank"
                className="help-option"
              >
                <div className="help-option-text">Request a feature</div>
              </a>
            </div>
            <div className="help-section" style={{ borderBottom: "none" }}>
              <a
                href="https://twitter.com/raroque"
                target="_blank"
                className="help-option"
              >
                <div className="help-option-text light">Twitter - @raroque</div>
              </a>
              <div className="help-option no-hover">
                <div className="help-option-text light">Ellie 1.37</div>
              </div>
            </div>
          </div>
        }
        title={null}
        trigger="click"
        placement="topRight"
      >
        <div className="help-icon">?</div>
      </Popover>
    </>
  );
}
