import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";

export default function Auth() {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
    </Routes>
  );
}
