import { RRule, RRuleSet, rrulestr } from "rrule";
import _ from "lodash";

export const isDev = false;

const moment = require("moment");
export const getDaysDiff = (
  start_date,
  end_date,
  date_format = "YYYY-MM-DD"
) => {
  const getDateAsArray = (date) => {
    return moment(date.split(/\D+/), date_format);
  };
  const diff =
    getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1;
  const dates = [];
  for (let i = 0; i < diff; i++) {
    const nextDate = getDateAsArray(start_date).add(i, "day");
    dates.push(nextDate.format(date_format));
  }
  return dates;
};

// Function to get intials from a name string
// If there is no name, return a smily face
export const getInitials = (name) => {
  if (name) {
    return name
      .split(" ")
      .map((name) => name.charAt(0))
      .join("");
  } else {
    return "👋";
  }
};

// Deep clone an array of objects
export const cloneArrayOfObjects = (array) => {
  return array.map((item) => {
    return { ...item };
  });
};

export function showToast(type, message) {
  // store.dispatch(setToastVisible(type, message));
}

export const focusModeBackgrounds = {
  japan2: {
    id: "japan2",
    name: "Calm Tokyo",
    image: require("./images/japan2.png"),
    video:
      "https://player.vimeo.com/external/664028532.hd.mp4?s=1f90c6fd7f83771e09230ee113da190ed657160c&profile_id=175",
  },
  rain2: {
    id: "rain2",
    name: "Rainy Apartment",
    image: require("./images/rain2.png"),
    video:
      "https://player.vimeo.com/external/664059441.hd.mp4?s=4fd93288123d5cddcaea668e5586d50932eb3bca&profile_id=175",
  },
  fireplace1: {
    id: "fireplace1",
    name: "Cozy Fireplace",
    image: require("./images/fireplace1.png"),
    video:
      "https://player.vimeo.com/external/664059384.hd.mp4?s=808ec2cf9c204c48b852fb30b7aa1f5402c3ad37&profile_id=175",
  },
  gb2: {
    id: "gb2",
    name: "Studio Ghibli Montage",
    image: require("./images/gb1-icon.jpeg"),
    video:
      "https://player.vimeo.com/external/664058476.hd.mp4?s=f966a2c341bee045d63b38e45e3284fe6845add3&profile_id=174",
  },
  beach1: {
    id: "beach1",
    name: "Beach Cafe",
    image: require("./images/beach1.png"),
    video:
      "https://player.vimeo.com/external/664059403.hd.mp4?s=54ce55159df10b30471f6396649130294cb413c5&profile_id=175",
  },
  lofi1: {
    id: "lofi1",
    name: "Lofi Girl",
    image: require("./images/lofi1.jpeg"),
    video:
      "https://player.vimeo.com/external/664059612.hd.mp4?s=b074071973e6c5f730fc8c70dcada5becda0e462&profile_id=175",
  },
};

export const serverUrl = isDev
  ? "http://localhost:5001/ellie-18430/us-central1/webApi/api/v1"
  : "https://us-central1-ellie-18430.cloudfunctions.net/webApi/api/v1";

  export const googleServerUrl = isDev
  ? "http://localhost:5001/ellie-18430/us-central1"
  : "https://valhalla.ellie.so";

export const isGhostTask = ({ task, recurringTasks }) => {
  if (task && task.recurring) {
    const recurringTask = recurringTasks?.[task.recurring_id];

    if (
      recurringTask &&
      (!recurringTask.branched_tasks ||
        !recurringTask.branched_tasks?.includes(task.id))
    ) {
      return true;
    }
  }

  return false;
};

export const getRecurrenceText = ({ recurringTask }) => {
  if (!recurringTask) {
    return "Does not repeat";
  }
  const recurrenceRule = rrulestr(recurringTask.rrule);

  if (recurrenceRule) {
    // If it is daily, then this is a daily recurrence
    if (recurrenceRule.options.freq == RRule.DAILY) {
      return "Every day";
    }

    // If it is weekly, there are a few options
    if (recurrenceRule.options.freq == RRule.WEEKLY) {
      // If byweekday has 5 days, this is "every weekday"
      if (recurrenceRule.options.byweekday.length == 5) {
        return "Every weekday (Mon - Fri)";
      }

      // if by weekday has 2 days, this is "every weekend day"
      if (recurrenceRule.options.byweekday.length == 2) {
        return "Every weekend day (Sat & Sun)";
      }

      // If there is 1 instance, this could be either weekly or biweekly
      if (recurrenceRule.options.byweekday.length == 1) {
        // Let's get the day of the week
        let weekdayNum = recurrenceRule.options.byweekday[0];

        // If the interval is 1, it is weekly
        if (recurrenceRule.options.interval == 1) {
          return `Every week (on ${weekdaysMondayFirst[weekdayNum]})`;
        }

        // If the interval is 2, it is bi-weekly
        if (recurrenceRule.options.interval == 2) {
          return `Every 2 weeks (on ${weekdaysMondayFirst[weekdayNum]})`;
        }
      }
    }

    // If it is monthly, it is every month
    if (recurrenceRule.options.freq == RRule.MONTHLY) {
      let weekdayNum = recurrenceRule.options.byweekday[0];

      return `Every month (on the ${
        recurrenceRule.options.bysetpos[0] == -1
          ? "last"
          : ordinal_suffix_of(recurrenceRule.options.bysetpos[0])
      } ${weekdaysMondayFirst[weekdayNum]})`;
    }

    // If it is yearly, it is yearly
    if (recurrenceRule.options.freq == RRule.YEARLY) {
      return `Every year (on ${
        months[recurrenceRule.options.bymonth - 1]
      } ${ordinal_suffix_of(recurrenceRule.options.bymonthday[0])})`;
    }
  }

  return "Does not repeat";
};

export const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const weekdaysMondayFirst = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function generateTemplateFromTask(task) {
  var template = {};

  template.description = task.description || null;
  template.label = task.label || null;
  template.estimated_time = task.estimated_time || null;

  template.subtasks = [];

  if (task.subtasks) {
    task.subtasks.forEach((subtask) => {
      var subtaskClone = _.cloneDeep(subtask);
      subtaskClone.complete = false;
      template.subtasks.push(subtaskClone);
    });
  }

  return template;
}

export function updateTaskToMatchTemplate(task, template) {
  task.description = template.description;
  task.label = template.label;
  task.estimated_time = template.estimated_time;
  task.subtasks = template.subtasks;

  return task;
}
