import "./Settings.css";
import React from "react";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toggleSettings, toggleLabelManager } from "../../redux/appSlice";

import SettingsContent from "./SettingsContent";

export default function Settings() {
  const { settingsVisible } = useSelector((state) => state.app);
  const { labelManagerVisible } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={900}
      visible={settingsVisible || labelManagerVisible}
      onOk={() => {
        if (settingsVisible) {
          dispatch(toggleSettings());
        }

        if (labelManagerVisible) {
          dispatch(toggleLabelManager());
        }
      }}
      onCancel={() => {
        if (settingsVisible) {
          dispatch(toggleSettings());
        }

        if (labelManagerVisible) {
          dispatch(toggleLabelManager());
        }
      }}
    >
      <SettingsContent />
    </Modal>
  );
}
