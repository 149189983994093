import "./LabelSelector.css";
import { useState, useRef, useEffect } from "react";
import { Popover } from "antd";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

import { useSelector, useDispatch } from "react-redux";

import CreateLabel from "./CreateLabel";
import { toggleLabelManager } from "../../../../../redux/appSlice";
import { useHotkeys } from "react-hotkeys-hook";

import { updateTask } from "../../../../../redux/tasksSlice";

import Sheet from "react-modal-sheet";

import { Mobile, Default } from "../../../../../mediaUtils";

import { useMediaQuery } from "react-responsive";
import { TbCalendar, TbTag } from "react-icons/tb";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
export default function LabelSelector({
  item,
  labelPickerActive,
  setLabelPickerActive,
  updateLabelLocal,
  label,
  disabled,
  buttonMode = false,
}) {
  const { data: labels, loading } = useSelector((state) => state.labels);

  function updateLabel(newValue) {
    if (updateLabelLocal) {
      updateLabelLocal(newValue);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            label: newValue,
          },
        })
      );
    }
  }

  const dispatch = useDispatch();
  return (
    <>
      <Default>
        <Popover
          visible={labelPickerActive}
          onVisibleChange={(visible) => {
            setLabelPickerActive(visible);
          }}
          destroyTooltipOnHide={true}
          title={null}
          content={
            <LabelSelectorContent
              label={label}
              updateLabel={updateLabel}
              labelPickerActive={labelPickerActive}
              setLabelPickerActive={setLabelPickerActive}
              labels={labels}
              loading={loading}
            />
          }
          placement="bottom"
          trigger="click"
        >
          {buttonMode ? (
            <>
              {(!label || !labels[label]) && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    setLabelPickerActive(true);
                  }}
                  className="mcf-button empty"
                >
                  Select a label
                </div>
              )}
              {label && labels[label] && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    setLabelPickerActive(true);
                  }}
                  className="mcf-button"
                  style={{ color: labels[label].color }}
                >
                  <div
                    className="label-box"
                    style={{ backgroundColor: labels[label].color }}
                  />
                  {labels[label].name}
                </div>
              )}
            </>
          ) : (
            <div
              onClick={(event) => {
                event.stopPropagation();
                setLabelPickerActive(true);
              }}
              data-no-dnd="true"
              style={{ maxWidth: "107px" }}
            >
              {(!label || !labels[label]) && (
                <div
                  className={`card-no-label ${
                    labelPickerActive ? "visible" : ""
                  }`}
                >
                  Select Label
                </div>
              )}

              {label && labels[label] && (
                <div className="card-label">
                  <div
                    className="label-box"
                    style={{ backgroundColor: labels[label].color }}
                  />
                  <span>{labels[label].name}</span>
                </div>
              )}
            </div>
          )}
        </Popover>
      </Default>
      <Mobile>
        <div
          onClick={() => {
            if (!disabled) {
              setLabelPickerActive(true);
            }
          }}
          className="noselect"
        >
          {(!label || !labels[label]) && (
            <div
              className={`card-no-label ${labelPickerActive ? "visible" : ""}`}
            >
              Select Label
            </div>
          )}

          {label && labels[label] && (
            <div className="card-label">
              <div
                className="label-box"
                style={{ backgroundColor: labels[label].color }}
              />
              <span>{labels[label].name}</span>
            </div>
          )}
        </div>
        <Sheet
          isOpen={labelPickerActive}
          onClose={() => setLabelPickerActive(false)}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <LabelSelectorContent
                label={label}
                updateLabel={updateLabel}
                labelPickerActive={labelPickerActive}
                setLabelPickerActive={setLabelPickerActive}
                labels={labels}
                loading={loading}
              />
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop />
        </Sheet>
      </Mobile>
    </>
  );
}

export function LabelSelectorContent({
  label,
  updateLabel,
  labelPickerActive,
  setLabelPickerActive,
  labels,
  loading,
}) {
  const searchRef = useRef();

  const [createLabelActive, setCreateLabelActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const [indexSelected, setIndexSelected] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();
      if (indexSelected > 0) {
        setIndexSelected(indexSelected - 1);
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      var labelCount =
        Object.values(labels).filter(function (label) {
          if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
          return false;
        }).length || 0;

      if (labelCount === indexSelected) {
        setCreateLabelActive(true);
      } else if (labelCount + 1 === indexSelected) {
        dispatch(toggleLabelManager());
        setLabelPickerActive(false);
      } else {
        const labelOption = Object.values(labels).filter(function (label) {
          if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }

          return false;
        })[indexSelected];
        updateLabel(labelOption.id);
        setLabelPickerActive(false);
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive && !createLabelActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      setLabelPickerActive(false);
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();
      if (
        indexSelected <
        Object.values(labels).filter(function (label) {
          if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
          return false;
        }).length +
          1
      ) {
        setIndexSelected(indexSelected + 1);
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useEffect(() => {
    if (!isMobile && searchRef?.current && labelPickerActive) {
      searchRef.current.focus();
    }
  }, [isMobile, labelPickerActive]);

  var labelCount =
    Object.values(labels).filter(function (label) {
      if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      return false;
    }).length || 0;
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="label-selector-container"
      data-no-dnd="true"
    >
      <div className="label-selector-search">
        <MagnifyingGlassIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIndexSelected(0);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
          ref={searchRef}
          autoFocus={!isMobile}
        />
      </div>
      <div className="label-selector-options">
        {loading && <div>Loading Labels</div>}

        {!createLabelActive && !loading && labels && (
          <div>
            {Object.values(labels)
              .filter(function (label) {
                if (
                  label.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
              .map((labelOption, index) => {
                return (
                  <Label
                    key={labelOption.id + "_label-option"}
                    labelOption={labelOption}
                    index={index}
                    indexSelected={indexSelected}
                    updateLabel={updateLabel}
                    setLabelPickerActive={setLabelPickerActive}
                  />
                );
              })}
            <div
              className={
                "create-label-button-container" +
                (labelCount === indexSelected ? " selected" : "")
              }
              onClick={() => setCreateLabelActive(true)}
            >
              <PlusCircleIcon className="create-label-icon" />
              Create {searchTerm ? `"${searchTerm}"` : "Label"}
            </div>

            <div
              className={
                "create-label-button-container" +
                (labelCount + 1 === indexSelected ? " selected" : "")
              }
              onClick={() => {
                dispatch(toggleLabelManager());
                setLabelPickerActive(false);
              }}
            >
              Edit Labels
              <ArrowSmallRightIcon className="edit-labels-arrow-icon" />
            </div>
          </div>
        )}

        {createLabelActive && (
          <CreateLabel
            updateLabel={updateLabel}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setCreateLabelActive={setCreateLabelActive}
            createLabelActive={createLabelActive}
          />
        )}
      </div>
    </div>
  );
}

export const Label = ({
  labelOption,
  index,
  indexSelected,
  updateLabel,
  setLabelPickerActive,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && index === indexSelected) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [indexSelected, ref]);

  return (
    <div
      ref={ref}
      key={labelOption.id + "label_option"}
      className={
        "label-selector-option" + (index === indexSelected ? " selected" : "")
      }
      onClick={() => {
        updateLabel(labelOption.id);
        setLabelPickerActive(false);
      }}
    >
      <div
        className="label-box"
        style={{ backgroundColor: labelOption.color }}
      />

      <span> {labelOption.name}</span>
    </div>
  );
};
