import "./TimeSection.css";
import { useState, useRef } from "react";

import { PlayIcon, PauseIcon } from "@heroicons/react/24/outline";

import DurationPicker from "../DurationPicker";

import { useDispatch, useSelector } from "react-redux";

import { updateTask } from "../../../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../../../redux/appSlice";

import moment from "moment";

import FocusModeDurationPicker from "../../../../Mobile/Task/FocusModeDurationPicker";
import FocusModeSelector from "../../../../Mobile/Task/FocusModeSelector";
import { useHotkeys } from "react-hotkeys-hook";

export default function TimeSection({
  item,
  updateTimeLocal,
  activelySelected,
  timeSectionActive,
  setTimeSectionActive,
}) {
  const [timerActive, setTimerActive] = useState(false);
  const [time, setTime] = useState(item.actual_time || 0);

  const [timerStartDate, setTimerStartDate] = useState(null);

  const [focusModeDurationPickerOpen, setFocusModeDurationPickerOpen] =
    useState(false);
  const [focusModePickerOpen, setFocusModePickerOpen] = useState(false);

  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);

  const timerRef = useRef(null);

  const dispatch = useDispatch();

  const { focus_mode_type } = useSelector((state) => state.app.currentUser);

  const startCountDown = () => {
    setTimerActive(true);

    setTimerStartDate(new Date());

    const today = new Date();

    setTime(item.actual_time || 0);

    timerRef.current = setInterval(() => {
      // Check the difference between now and the start time

      const difference = moment().diff(
        moment(timerStartDate || today),
        "seconds"
      );

      setTime((item.actual_time || 0) + difference);
    }, 1000);
  };

  const startFocusMode = () => {
    // Stop the current timer then let's clear the active timer on the user

    // Update the actual_time on the task
    const active_timer = {
      task_id: item.id,
      active: true,
      focus_time_original: 1500,
      focus_time_left: 1500,
      last_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );
  };

  const pauseCountDown = () => {
    setTimerActive(false);

    setTimerStartDate(new Date());

    clearInterval(timerRef.current);

    dispatch(
      updateTask({
        taskId: item.id,
        currentTask: item,
        newData: {
          actual_time: time,
        },
      })
    );
  };

  useHotkeys(
    "e",
    (e) => {
      e.preventDefault();

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        if (!timeSectionActive) {
          setTimeSectionActive(true);
        }
        setEstimatedTimeActive(true);
      }

      if (actualTimeActive) {
        setActualTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected, estimatedTimeActive, timeSectionActive, actualTimeActive]
  );

  useHotkeys(
    "a",
    (e) => {
      e.preventDefault();

      if (actualTimeActive) {
        setActualTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        if (!timeSectionActive) {
          setTimeSectionActive(true);
        }
        setActualTimeActive(true);
      }

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected, actualTimeActive, timeSectionActive, estimatedTimeActive]
  );

  function enterStopwatchMode() {
    const active_timer = {
      task_id: item.id,
      active: true,
      focus_time_original: 0,
      focus_time_left: 0,
      last_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      type: "stopwatch",
    };
    
    window.mixpanel.track("Focus mode entered", {
      start_duration: active_timer.focus_time_original,
      task_id: item.id,
      type: "stopwatch",
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );
  }

  if (!timeSectionActive) {
    return null;
  }

  return (
    <div className="card-time-content" data-no-dnd="true">
      <div className="time-section">
        <div className="active-timer-container">
          <FocusModeDurationPicker
            item={item}
            isOpen={focusModeDurationPickerOpen}
            setIsOpen={setFocusModeDurationPickerOpen}
          />
          <FocusModeSelector
            item={item}
            isOpen={focusModePickerOpen}
            setIsOpen={setFocusModePickerOpen}
            setFocusModeDurationPickerOpen={setFocusModeDurationPickerOpen}
            enterStopwatchMode={enterStopwatchMode}
          />

          {timerActive && (
            <PauseIcon
              onClick={() => {
                pauseCountDown();
              }}
              className="timer-play-button"
            />
          )}

          {!timerActive && (
            <PlayIcon
              onClick={() => {
                //  startFocusMode();
                if (focus_mode_type === "stopwatch") {
                  // Default is stopwatch mode, so let's start the timer
                  // Update the actual_time on the task
                  enterStopwatchMode();
                } else if (focus_mode_type === "timer") {
                  // In timer mode, we should open the duration picker
                  setFocusModeDurationPickerOpen(!focusModeDurationPickerOpen);
                } else {
                  // There is no focus mode yet, let's ask the user to pick one
                  setFocusModePickerOpen(!focusModePickerOpen);
                }
              }}
              className="timer-play-button"
            />
          )}

          {timerActive && (
            <div className="active-timer">
              {!isNaN(time)
                ? moment.utc(time * 1000).format("H:mm:ss")
                : "0:00"}
            </div>
          )}
        </div>

        <div className="time-sections">
          {!timerActive && (
            <DurationPicker
              time={!isNaN(item.actual_time) ? item.actual_time : null}
              item={item}
              title="Actual"
              updateTimeLocal={
                updateTimeLocal
                  ? (newValue) => {
                      updateTimeLocal("actual_time", newValue);
                    }
                  : null
              }
              durationPickerActive={actualTimeActive}
              setDurationPickerActive={setActualTimeActive}
            />
          )}

          <DurationPicker
            time={!isNaN(item.estimated_time) ? item.estimated_time : null}
            item={item}
            title="Estimated"
            updateTimeLocal={
              updateTimeLocal
                ? (newValue) => {
                    updateTimeLocal("estimated_time", newValue);
                  }
                : null
            }
            durationPickerActive={estimatedTimeActive}
            setDurationPickerActive={setEstimatedTimeActive}
          />
        </div>
      </div>
    </div>
  );
}
