import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Kanban.css";

import Column from "./Column";

import { useDispatch, useSelector } from "react-redux";

import {
  loadNextDates,
  loadPreviousDates,
  changeStartDate,
  selectNextColumn,
  selectPreviousColumn,
  refreshKabanCursor,
  updateCurrentUser,
} from "../../redux/appSlice";

import { useHotkeys } from "react-hotkeys-hook";
import Toolbar from "../Toolbar";

import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import TopbarActions from "./TopbarActions";
import _ from "lodash";

function Kanban({ isDragging, taskOrder }) {
  const kanbanRef = useRef();
  const dispatch = useDispatch();

  const dates = useSelector((state) => state.app.dates);
  // Default columnSelected to the index of today's column in taskOrder

  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);

  const kanbanRefreshCursor = useSelector(
    (state) => state.app.kanbanRefreshCursor
  );

  useEffect(() => {
    if (kanbanRefreshCursor && kanbanRef.current) {
      var index = dates.indexOf(kanbanRefreshCursor);
      kanbanRef.current.scrollLeft = 280 * index;

      dispatch(refreshKabanCursor(null));
    }
  }, [kanbanRefreshCursor]);

  function scrollToColumn(date) {
    if (kanbanRef.current) {
      var index = dates.indexOf(date);

      kanbanRef.current.scrollLeft = 280 * index;
    }
  }

  useHotkeys(
    "right",
    (e) => {
      e.preventDefault();

      dispatch(selectNextColumn());

      setNavigatedViaKeyboard(true);
    },
    []
  );

  useHotkeys(
    "left",
    (e) => {
      e.preventDefault();

      dispatch(selectPreviousColumn());

      setNavigatedViaKeyboard(true);
    },
    []
  );

  const [isDraggingWithMouse, setIsDraggingWithMouse] = useState(false);

  // Create event handlers so a user can drag the kanban board with their mouse to scroll left and right
  // useCallback is used to prevent the function from being recreated on every render
  const handleMouseDown = useCallback((e) => {
    // Only run the function if the user is dragging on the kanban board or class is "draggable-container"
    if (
      e.target === kanbanRef.current ||
      e.target.className === "draggable-container "
    ) {
      // Set the isDraggingWithMouse state to true
      setIsDraggingWithMouse(true);

      // Prevent scrolling on the y-axix when the user is dragging
      e.preventDefault();

      // Set the initial position of the mouse
      var initialX = e.clientX;
      var initialScrollLeft = kanbanRef.current.scrollLeft;

      // Create a function to handle the mouse movement
      const handleMouseMove = (e) => {
        // Calculate the distance the mouse has moved since the initial position
        const distanceX = e.clientX - initialX;

        // Scroll the kanban board to the new position
        kanbanRef.current.scrollLeft = initialScrollLeft - distanceX;

        const scrollLeftRoundingUp = Math.ceil(
          kanbanRef.current.scrollLeft + kanbanRef.current.offsetWidth
        );

        if (scrollLeftRoundingUp >= kanbanRef.current.scrollWidth) {
          dispatch(loadNextDates());
          kanbanRef.current.scrollLeft -= 280 * 7 - 15;

          initialScrollLeft = kanbanRef.current.scrollLeft;
          initialX = e.clientX;
        }

        if (kanbanRef.current.scrollLeft === 0) {
          dispatch(loadPreviousDates());
          kanbanRef.current.scrollLeft += 280 * 7 - 15;

          initialScrollLeft = kanbanRef.current.scrollLeft;
          initialX = e.clientX;
        }
      };

      // Add the mousemove event listener
      kanbanRef.current.addEventListener("mousemove", handleMouseMove);

      // Remove the mousemove event listener when the user releases the mouse button to the document
      document.addEventListener(
        "mouseup",
        () => {
          setIsDraggingWithMouse(false);
          kanbanRef.current.removeEventListener("mousemove", handleMouseMove);
        },
        { once: true }
      );
    }
  }, []);

  // Add the mousedown event listener to the kanban board
  useEffect(() => {
    kanbanRef.current.addEventListener("mousedown", handleMouseDown);

    // Remove the mousedown event listener when the component unmounts
    return () => {
      kanbanRef.current.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleMouseDown]);

  return (
    <div className="kanban-board">
      <TopbarActions />
      <div
        className="kanban-body"
        ref={kanbanRef}
        onMouseMove={() => {
          if (navigatedViaKeyboard) {
            setNavigatedViaKeyboard(false);
          }
        }}
        onScroll={(event) => {
          const indexInView = Math.floor(
            (event.target.scrollWidth -
              event.target.offsetWidth -
              (event.target.scrollWidth -
                event.target.offsetWidth -
                event.target.scrollLeft)) /
              280
          );

          dispatch(changeStartDate(dates[indexInView]));

          // Prevent scrolling on the x-axix when the user is dragging
          const scrollLeftRoundingUp = Math.ceil(
            event.target.scrollLeft + event.target.offsetWidth
          );

          if (!isDragging && !isDraggingWithMouse) {
            if (scrollLeftRoundingUp >= event.target.scrollWidth) {
              dispatch(loadNextDates());
              kanbanRef.current.scrollLeft -= 280 * 7 - 15;
            }

            if (event.target.scrollLeft === 0) {
              dispatch(loadPreviousDates());
              kanbanRef.current.scrollLeft += 280 * 7 - 15;
            }
          }
        }}
      >
        {dates.map((date, index) => {
          if (taskOrder && taskOrder[date]) {
            return (
              <Column
                key={"column_" + date}
                order={taskOrder[date].order}
                date={date}
                scrollToColumn={scrollToColumn}
                navigatedViaKeyboard={navigatedViaKeyboard}
                setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              />
            );
          } else {
            return (
              <Column
                key={"column_" + date}
                order={[]}
                date={date}
                scrollToColumn={scrollToColumn}
                navigatedViaKeyboard={navigatedViaKeyboard}
                setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              />
            );
          }
        })}
      </div>

      <Toolbar />
    </div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check if the taskOrder is the same, using lodash _.isEqual
  return _.isEqual(prev.taskOrder, next.taskOrder);
}

export default React.memo(Kanban, areEqual);
