import "./FocusMode.css";

import { useDispatch, useSelector } from "react-redux";

import { useRef, useEffect } from "react";
import useState from "react-usestateref";
import { PauseIcon, StopIcon, PlayIcon } from "@heroicons/react/24/outline";
import {
  PauseIcon as PauseIconSolid,
  StopIcon as StopIconSolid,
  PlayIcon as PlayIconSolid,
} from "@heroicons/react/24/solid";

import moment from "moment";

import _ from "lodash";

import FocusBGPicker from "./FocusBGPicker";

import { focusModeBackgrounds } from "../../../utils";

import { Mobile, Default } from "../../../mediaUtils";

import { updateCurrentUser } from "../../../redux/appSlice";
import { updateTask } from "../../../redux/tasksSlice";

import CompleteInput from "../../Kanban/Card/Components/CompleteInput";
import Subtasks from "../../Kanban/Card/Components/Subtasks";
import TextareaAutosize from "react-textarea-autosize";
export default function FocusMode() {
  const { active_timer } = useSelector((state) => state.app.currentUser);

  const task = useSelector((state) => state.tasks?.data[active_timer?.task_id]);

  const [timerActive, setTimerActive] = useState(false);
  const [time, setTime, timeRef] = useState(1500);

  const [bgPickerActive, setBgPickerActive] = useState(false);

  const [bgSelected, setBgSelected] = useState("japan2");

  const timerRef = useRef(null);

  const dispatch = useDispatch();

  function updateTime() {
    let dateStarted = moment(
      active_timer.last_start_time,
      "YYYY-MM-DD HH:mm:ss"
    ).toDate();
    let secondsRemaining = active_timer.focus_time_left || 1500;

    const today = new Date();

    const difference = moment().diff(moment(dateStarted || today), "seconds");

    if (active_timer.active) {
      secondsRemaining = secondsRemaining - difference;
    }

    // if the timer is of type timer, let's check the seconds remaining
    if (active_timer.type === "timer") {
      // If the active timer is past the time, let's go ahead and stop/save/close
      if (secondsRemaining <= 0) {
        secondsRemaining = 0;
      } else {
        // Let's get the active timer right now and start off where we left off
        setTime(secondsRemaining);
      }
    } else {
      // If this is a stopwatch mode, let's just set the time to the difference
      setTime(difference);
    }
  }

  const resumeCountDown = () => {
    setTimerActive(true);

    timerRef.current = setInterval(() => {
      // Check the difference between now and the start time

      let dateStarted = moment(
        active_timer.last_start_time,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
      let secondsRemaining = active_timer.focus_time_left || 1500;

      const today = new Date();

      const difference = moment().diff(moment(dateStarted || today), "seconds");

      secondsRemaining = secondsRemaining - difference;

      // if the timer is of type timer, let's check the seconds remaining
      if (active_timer.type === "timer") {
        // If the active timer is past the time, let's go ahead and stop/save/close
        if (secondsRemaining <= 0) {
          setTime(0);
          secondsRemaining = 0;
          stopCountdown();
        } else {
          // Let's get the active timer right now and start off where we left off
          setTime(secondsRemaining);
        }
      } else {
        // If this is a stopwatch mode, let's just set the time to the difference
        setTime(difference + active_timer.focus_time_left);
      }
    }, 1000);
  };

  const reactivateTimer = () => {
    const timerCopy = _.cloneDeep(active_timer);

    timerCopy.active = true;
    timerCopy.last_start_time = moment().format("YYYY-MM-DD HH:mm:ss");

    window.mixpanel.track("Focus mode unpaused", {
      time_left: timerCopy.focus_time_left,
      task_id: timerCopy.task_id,
      type: timerCopy.type,
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: timerCopy },
        previousValues: { active_timer: active_timer },
      })
    );
  };

  const stopCountdown = () => {
    // Stop the current timer then let's clear the active timer on the user
    setTimerActive(false);
    clearInterval(timerRef.current);

    // Update the actual_time on the task

    const timeRemaining = timeRef.current;

    const timeInFocusMode = active_timer.focus_time_original - timeRemaining;

    window.mixpanel.track("Focus mode exited", {
      end_duration:
        active_timer.type === "stopwatch" ? timeRemaining : timeInFocusMode,
      task_id: active_timer.task_id,
      type: active_timer.type,
      background: active_timer.video_bg_id,
    });

    if (task) {
      // if the timer is of type timer, let's check the seconds remaining
      if (active_timer.type === "timer") {
        dispatch(
          updateTask({
            taskId: task.id,
            currentTask: task,
            newData: {
              actual_time: (task.actual_time || 0) + timeInFocusMode,
            },
          })
        );
      } else {
        // In stopwatch mode, time remaining is the total time
        dispatch(
          updateTask({
            taskId: task.id,
            currentTask: task,
            newData: {
              actual_time: (task.actual_time || 0) + timeRemaining,
            },
          })
        );
      }

      dispatch(
        updateCurrentUser({
          newValues: { active_timer: null },
          previousValues: { active_timer: active_timer },
        })
      );
    }
  };

  const pauseCountdown = () => {
    // Update the active_timer on the user
    const timerCopy = _.cloneDeep(active_timer);

    timerCopy.active = false;
    timerCopy.focus_time_left = timeRef.current;
    timerCopy.last_start_time = moment().format("YYYY-MM-DD HH:mm:ss");

    window.mixpanel.track("Focus mode paused", {
      time_left: timerCopy.focus_time_left,
      task_id: timerCopy.task_id,
      type: timerCopy.type,
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: timerCopy },
        previousValues: { active_timer: active_timer },
      })
    );

    setTimerActive(false);
    clearInterval(timerRef.current);
  };

  useEffect(() => {
    if (active_timer) {
      if (focusModeBackgrounds[active_timer.video_bg_id]) {
        setBgSelected(active_timer.video_bg_id);
      }

      updateTime();

      if (active_timer.active) {
        setTimerActive(false);
        clearInterval(timerRef.current);
        resumeCountDown();
      } else {
        // we have a paused timer in "stopwatch" mode
        setTime(active_timer.focus_time_left || 0);
      }
    }
  }, [active_timer]);

  const [descriptionEditable, setDescriptionEditable] = useState(
    task?.description || ""
  );

  useEffect(() => {
    if (descriptionEditable !== task?.description) {
      setDescriptionEditable(task?.description || "");
    }
  }, [task]);

  if (active_timer == null) {
    return null;
  }

  if (task == null) {
    return null;
  }

  return (
    <div className="focusmode-mobile">
      <FocusBGPicker
        isOpen={bgPickerActive}
        setIsOpen={setBgPickerActive}
        setBg={(data) => {

          window.mixpanel.track("Focus mode background changed", {
            background: data.id,
          });

          dispatch(
            updateCurrentUser({
              newValues: {
                active_timer: {
                  video_bg_id: data.id,
                },
              },
              previousValues: { active_timer: active_timer },
            })
          );

          setBgSelected(data.id);
        }}
      />

      <div className="video-container">
        <video
          preload="auto"
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          key={focusModeBackgrounds[bgSelected || "japan2"].video}
        >
          <source
            src={focusModeBackgrounds[bgSelected || "japan2"].video}
            type="video/mp4"
          ></source>
        </video>
      </div>

      <Default>
        {" "}
        <div className="fm-content">
          <div className="fm-task-info">
            <div className="fm-task-card">
              {" "}
              <div className="complete-container">
                <CompleteInput item={task} complete={task?.complete} />
              </div>
              <TextareaAutosize
                spellCheck={false}
                onBlur={() => {
                  dispatch(
                    updateTask({
                      taskId: task?.id,
                      currentTask: task,
                      newData: {
                        description: descriptionEditable,
                      },
                    })
                  );
                }}
                className="card-description fm"
                value={descriptionEditable}
                onChange={(e) => {
                  setDescriptionEditable(e.target.value);
                }}
              />
            </div>

            <Subtasks item={task} focusMode={true} subtasksActive={true} />

            <div className="fm-footer">
              <img
                onClick={() => {
                  setBgPickerActive(true);
                }}
                src={focusModeBackgrounds[bgSelected || "japan2"].image}
                className="fm-background-picker"
              />
              <div className="fm-buttons">
                {timerActive && (
                  <div
                    onClick={() => {
                      pauseCountdown();
                    }}
                    className="fm-button pause"
                  >
                    <PauseIconSolid className="icon" />
                  </div>
                )}

                {!timerActive && (
                  <div
                    onClick={() => {
                      reactivateTimer();
                    }}
                    className="fm-button pause"
                  >
                    <PlayIconSolid className="icon" />
                  </div>
                )}

                <div
                  onClick={() => {
                    stopCountdown();
                  }}
                  className="fm-button"
                >
                  <StopIconSolid className="icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="fm-timer">
            {!isNaN(time) ? moment.utc(time * 1000).format("H:mm:ss") : "0:00"}
          </div>
        </div>
      </Default>

      <Mobile>
        <div className="fm-content">
          <div className="fm-timer">
            {!isNaN(time) ? moment.utc(time * 1000).format("H:mm:ss") : "0:00"}
          </div>
          <div className="fm-footer">
            <img
              onClick={() => {
                setBgPickerActive(true);
              }}
              src={focusModeBackgrounds[bgSelected || "japan2"].image}
              className="fm-background-picker"
            />
            <div className="fm-buttons">
              {timerActive && (
                <div
                  onClick={() => {
                    pauseCountdown();
                  }}
                  className="fm-button pause"
                >
                  <PauseIcon className="icon" />
                  Pause
                </div>
              )}

              {!timerActive && (
                <div
                  onClick={() => {
                    reactivateTimer();
                  }}
                  className="fm-button pause"
                >
                  <PlayIcon className="icon" />
                  Play
                </div>
              )}

              <div
                onClick={() => {
                  stopCountdown();
                }}
                className="fm-button"
              >
                <StopIcon className="icon" />
                Stop
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  );
}
