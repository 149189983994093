import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BrowserRouter } from "react-router-dom";
import Auth from "./Components/Auth";
import Toast from "./Components/Generics/Toast";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { ConfigProvider } from "antd";

Sentry.init({
  dsn: "https://536adbe2e8be46bfa6d9e4d43828438f@o1131095.ingest.sentry.io/6175295",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    // ...

    Sentry.setUser({ id: user.uid });

    window.mixpanel.identify(user.uid);
    window.mixpanel.people.set({
      $email: user.email, // only reserved properties need the $
      USER_ID: user.uid, // use human-readable names
    });

    ReactDOM.render(
      <Provider store={store}>
        <ConfigProvider theme={{ hashed: false }}>
          <App userId={uid} />
          <Toast />
        </ConfigProvider>
      </Provider>,
      document.getElementById("root")
    );
  } else {
    // User is signed out
    // ...
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider theme={{ hashed: false }}>
            <Auth />
          </ConfigProvider>
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
