/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import "./Toolbar.css";

import DatePicker from "../Sidebar/DatePicker";
import Labels from "../Labels";

import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";

import {
  changeStartDate,
  loadDatesFromStartDate,
  refreshKabanCursor,
  updateCurrentUser,
  toggleSettings,
} from "../../redux/appSlice";

import moment from "moment";

import { getInitials } from "../../utils";

import { useSpring, animated as a } from "react-spring";
import HelpIcon from "../Generics/HelpIcon";
import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { CalendarIcon } from "@heroicons/react/24/outline";

export default function Toolbar() {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showLabels, setShowLabels] = useState(false);

  const {
    label_filters: activeLabels,
    hide_complete,
    avatar_url,
    name,
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  const labelsSpringProps = useSpring({
    opacity: showLabels ? 1 : 0,
  });

  const datePickerSpringProps = useSpring({
    opacity: showDatePicker ? 1 : 0,
  });

  return (
    <div className="floating-toolbar noselect">
      {showLabels || showDatePicker ? (
        <div className="floating-toolbar-content">
          {showLabels && (
            <div>
              <a.div className="box" style={labelsSpringProps}>
                <Labels />
              </a.div>
              <a.div className="box" style={labelsSpringProps}>
                <div className="hc-container">
                  Show Complete{" "}
                  <Switch
                    size="small"
                    checked={!hide_complete}
                    defaultChecked
                    onClick={() => {
                      // Update the current user's hide_complete value

                      window.mixpanel.track("Hide complete toggled", {
                        active: !hide_complete,
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            hide_complete: !hide_complete,
                          },
                          previousValues: {
                            hide_complete: hide_complete,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </a.div>
            </div>
          )}
          {showDatePicker && (
            <a.div className="box" style={datePickerSpringProps}>
              <DatePicker />
            </a.div>
          )}
        </div>
      ) : null}

      <div className="floating-bar">
        <div className="floating-buttons">
          <div
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
            className={"floating-button" + (showDatePicker ? " active" : "")}
          >
            <CalendarIcon style={{ height: "15px" }} />
          </div>
        </div>

        <HelpIcon />
      </div>
    </div>
  );
}
