import { useRef, useEffect, useState } from "react";
import moment from "moment";

import OutsideClickHandler from "react-outside-click-handler";

export default function DurationPickerInput({
  hours,
  minutes,
  setHours,
  setMinutes,
  visible,
  setVisible,
  time,
  inline = false,
  inlineActive = false,
  setInlineActive,
}) {
  const [value, setValue] = useState(null);

  const inputRef = useRef(null);

  function updateMinutesAndHours() {
    // Get hours
    const newHours =
      (getDigit(value, 4)?.toString() || "0") +
      (getDigit(value, 3)?.toString() || "0");
    const newMinutes =
      (getDigit(value, 2)?.toString() || "0") +
      (getDigit(value, 1)?.toString() || "0");

    setHours(parseInt(!isNaN(newHours) ? newHours : "0"));
    setMinutes(parseInt(!isNaN(newMinutes) ? newMinutes : "0"));
  }

  useEffect(() => {
    if (!inline && visible) {
      inputRef.current.focus();
    }

    if (inline && inlineActive) {
      inputRef.current.focus();
    }
  }, [visible, inline, inlineActive]);

  useEffect(() => {
    // convert hours and minutes to strings and combine them
    const hoursString = hours?.toString().padStart(2, "0") ?? "00";
    const minutesString = minutes?.toString().padStart(2, "0") ?? "00";

    // Set value to the combined hours and minutes strings converted to a number
    const newValue = parseInt(hoursString + minutesString);

    if (value === null) {
      if (newValue === 0) {
        setValue(null);
      } else {
        setValue(newValue);
      }
    }
  }, []);

  useEffect(() => {
    if (value === null) {
      setHours(0);
      setMinutes(0);
    } else {
      updateMinutesAndHours();
    }
  }, [value]);

  return (
    <div>
      {inline && !inlineActive && (
        <div
          className="inline-dpi-content"
          onClick={() => {
            setInlineActive(true);
          }}
        >
          {!isNaN(time) ? moment.utc(time * 1000).format("H:mm") : "0:00"}
        </div>
      )}

      {(!inline || inlineActive) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            if (inline && inlineActive) {
              setInlineActive(false);
            }
          }}
          disabled={!inline}
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className="time-input-container"
              ref={inputRef}
              tabIndex={0}
              onKeyDown={(e) => {
                const isNumber = /^[0-9]$/i.test(e.key);

                if (
                  e.key === "Enter" ||
                  e.key === "Tab" ||
                  e.key === "Escape"
                ) {
                  e.stopPropagation();
                  setVisible(false);

                  return;
                }

                // If key is backspace, delete the last digit
                if (e.key === "Backspace") {
                  if (value === null || value === 0) {
                    setValue(null);
                  } else {
                    const newValue = Math.floor(value / 10);

                    if (newValue === 0) {
                      setValue(null);
                    } else {
                      setValue(newValue);
                    }
                  }

                  return;
                }

                if (isNumber) {
                  setValue(value ? value + e.key : e.key);
                } else {
                  e.preventDefault();
                  return;
                }
              }}
            >
              <span
                className={`${inline ? "inline" : ""} ti-digit ${
                  getDigit(value, 4) !== null ? "" : "unsure"
                }`}
              >
                {getDigit(value, 4) || 0}
              </span>
              <span
                className={`${inline ? "inline" : ""} ti-digit ${
                  getDigit(value, 3) !== null ? "" : "unsure"
                }`}
              >
                {getDigit(value, 3) || 0}
              </span>
              <span
                className={`${inline ? "inline" : ""} ti-sep ${
                  getDigit(value, 3) !== null ? "" : "unsure"
                }`}
              >
                h
              </span>
              <span
                className={`${inline ? "inline" : ""} ti-digit ${
                  getDigit(value, 2) !== null ? "" : "unsure"
                }`}
              >
                {getDigit(value, 2) || 0}
              </span>
              <span
                className={`${inline ? "inline" : ""} ti-digit ${
                  getDigit(value, 1) !== null ? "" : "unsure"
                } cursor`}
              >
                {getDigit(value, 1) || 0}
              </span>
              <span
                className={`${inline ? "inline" : ""} ti-sep ${
                  getDigit(value, 1) !== null ? "" : "unsure"
                }`}
              >
                m
              </span>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

function getDigitCount(number) {
  return Math.max(Math.floor(Math.log10(Math.abs(number))), 0) + 1;
}
function getDigit(number, n, fromLeft) {
  const location = fromLeft ? getDigitCount(number) + 1 - n : n;

  // If n is 1 and number is null, return null
  if (n === 1 && (number === null || number === "")) {
    return null;
  }

  // If n is greater than the number of digits in the number, return null
  if (location > getDigitCount(number)) return null;

  return Math.floor((number / Math.pow(10, location - 1)) % 10);
}
