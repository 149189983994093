import { configureStore } from "@reduxjs/toolkit";
import tasksReducer from "../src/redux/tasksSlice";
import labelsReducer from "../src/redux/labelsSlice";
import appReducer from "../src/redux/appSlice";

export default configureStore({
  reducer: {
    tasks: tasksReducer,
    labels: labelsReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(
    {
      serializableCheck: false,
    }
  ),
});
