import "./TabBar.css";
import React from "react";

import calendarIcon from "../../../images/calendar_icon.svg";
import brainIcon from "../../../images/brain_icon.svg";
import moreIcon from "../../../images/more_icon.svg";

// Import calendar icon from hero icons

import { setMobilePageActive } from "../../../redux/appSlice";

import { useSelector, useDispatch } from "react-redux";
export default function TabBar() {
  const { mobilePageActive } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  return (
    <div className="tabbar">
      <div
        onClick={() => {
          dispatch(setMobilePageActive("tasks"));
        }}
        className={`tab ${mobilePageActive === "tasks" ? "active" : ""}`}
      >
        <img src={calendarIcon} alt="calendar" className="tab-icon" />
      </div>
      <div
        onClick={() => dispatch(setMobilePageActive("braindump"))}
        className={`tab ${mobilePageActive === "braindump" ? "active" : ""}`}
      >
        <img src={brainIcon} alt="brain" className="tab-icon" />
      </div>
      <div
        onClick={() => dispatch(setMobilePageActive("settings"))}
        className={`tab ${mobilePageActive === "settings" ? "active" : ""}`}
      >
        <img src={moreIcon} alt="more" className="tab-icon" />
      </div>
    </div>
  );
}
