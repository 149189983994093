import "./RecurringInput.css";
import React, { useState } from "react";

import { CheckIcon } from "@heroicons/react/24/solid";
import { TiArrowRepeat } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Tooltip } from "antd";

import {
  updateTask,
  moveTaskToBottom,
  moveTaskToBottomFromBrainDump,
  moveTaskToBottomOfIncomplete,
} from "../../../../../redux/tasksSlice";

import { getRecurrenceText } from "../../../../../utils";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

import RecurringTaskMenu from "./RecurringTaskMenu";

export default function RecurringInput({
  item,
  recurringTaskLocal,
  updateRecurringLocal,
  creation = false,
  recurringTaskMenuOpen,
  setRecurringTaskMenuOpen,
  modalMode = false,
}) {
  const dispatch = useDispatch();

  const [toolTipVisible, setToolTipVisible] = useState(false);

  const recurringTask = useSelector(
    (state) => state.tasks.recurringTasks[item.recurring_id]
  );

  const recurrenceText = getRecurrenceText({
    recurringTask: creation ? recurringTaskLocal : recurringTask,
  });

  return (
    <Popover
      visible={recurringTaskMenuOpen}
      onVisibleChange={(visible) => {
        if (item.date) {
          setRecurringTaskMenuOpen(visible);
        }
      }}
      destroyTooltipOnHide={true}
      title={null}
      content={
        <RecurringTaskMenu
          task={item}
          setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
          recurringTaskLocal={recurringTaskLocal}
          updateRecurringLocal={updateRecurringLocal}
          creation={creation}
        />
      }
      placement="rightTop"
      trigger="click"
    >
      <Tooltip
        visible={toolTipVisible}
        onVisibleChange={(visible) => {
          if (!item.date) {
            setToolTipVisible(visible);
          }
        }}
        placement="bottomRight"
        title={"Tasks in the brain dump cannot be recurring"}
      >
        {modalMode ? (
          <div className={`mcf-button${recurrenceText == "Does not repeat" ? ' empty' : ''}`}>{recurrenceText}</div>
        ) : (
          <div
            className={`subtask-button-container ${
              item.recurring ? "visible" : ""
            }`}
            data-no-dnd="true"
          >
            <TiArrowRepeat className="subtask-button" />
          </div>
        )}
      </Tooltip>
    </Popover>
  );
}
