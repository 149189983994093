import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Column.css";

import Card from "../Card";
import CreateCard from "../Card/CreateCard";

import { useHotkeys } from "react-hotkeys-hook";
import { useInViewport } from "react-in-viewport";

import { setColumnSelected } from "../../../redux/appSlice";
import ColumnHeader from "./ColumnHeader";
import ColumnBody from "./ColumnBody";
import moment from "moment";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function Column({
  order,
  date,
  scrollToColumn,
  navigatedViaKeyboard = false,
  setNavigatedViaKeyboard,
  onDragEnd,
  saveOrder,
}) {
  const [createActive, setCreateActive] = useState(null);
  const columnSelected = useSelector(
    (state) => state.app.columnSelected === date
  );

  const { data: taskData } = useSelector((state) => state.tasks);
  const columnRef = useRef(null);

  const [indexSelected, setIndexSelected] = useState(null);

  const { inViewport, enterCount, leaveCount } = useInViewport(columnRef);

  const dispatch = useDispatch();

  useEffect(() => {
    if (navigatedViaKeyboard && columnSelected && !inViewport) {

      scrollToColumn(date);
    }
  }, [columnSelected, inViewport, navigatedViaKeyboard]);

  useEffect(() => {
    if (createActive) {
      setIndexSelected(null);
    } else {
      if (navigatedViaKeyboard) {
        if (columnSelected && indexSelected === null) {
          setIndexSelected(0);
        }

        if (!columnSelected) {
          setIndexSelected(null);
        }
      } else {
        if (!columnSelected && indexSelected !== null) {
          setIndexSelected(null);
        }

        if (columnSelected && indexSelected === null) {
          setIndexSelected(0);
        }
      }
    }
  }, [columnSelected, indexSelected, createActive, navigatedViaKeyboard]);

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();

      var filteredOrder = order.filter((taskId) => {
        if (taskData[taskId]) {
          return true;
        }
        return false;
      });

      if (indexSelected === null) {
        setIndexSelected(0);
      } else if (indexSelected < filteredOrder.length - 1) {
        setIndexSelected(indexSelected + 1);
      }

      if (setNavigatedViaKeyboard) {
        setNavigatedViaKeyboard(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: columnSelected && !createActive,
    },
    [indexSelected, columnSelected, taskData, order]
  );

  useHotkeys(
    "t",
    (e) => {
      e.preventDefault();

      // Set create active to today's date
      setCreateActive(date);
      dispatch(setColumnSelected(null));

      // ... set up our own saving dialog.
    },
    {
      enabled: date === moment().format("YYYY-MM-DD"),
    }
  );

  useHotkeys(
    "shift+t",
    (e) => {
      e.preventDefault();

      // Set create active to today's date
      setIndexSelected(0);
      dispatch(setColumnSelected(moment().format("YYYY-MM-DD")));

      // ... set up our own saving dialog.
    },
    {
      enabled: date === moment().format("YYYY-MM-DD"),
    }
  );

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();

      if (indexSelected === null) {
        return;
      }

      if (indexSelected > 0) {
        setIndexSelected(indexSelected - 1);

        if (setNavigatedViaKeyboard) {
          setNavigatedViaKeyboard(true);
        }
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: columnSelected && !createActive,
    },
    [indexSelected, columnSelected, order]
  );

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  return (
    <div ref={columnRef} className="column-container" key={`${date}_container`}>
      <ColumnHeader
        date={date}
        key={"header_" + date}
        setCreateActive={setCreateActive}
      />
      {createActive && (
        <CreateCard
          setCreateActive={setCreateActive}
          date={date}
          setNavigatedViaKeyboard={setNavigatedViaKeyboard}
        />
      )}

      <ColumnBody
        order={order}
        date={date}
        indexSelected={indexSelected}
        manuallySelectColumn={manuallySelectColumn}
        navigatedViaKeyboard={navigatedViaKeyboard}
        setIndexSelected={setIndexSelected}
        mini={false}
      />
    </div>
  );
}
