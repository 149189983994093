import "./DurationPicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import Sheet from "react-modal-sheet";

import "react-datepicker/dist/react-datepicker.css";

import {
  CheckIcon,
  SearchIcon,
  ArrowSmRightIcon,
} from "@heroicons/react/24/outline";

import {
  SunIcon,
  ArrowCircleRightIcon,
  ArchiveIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/solid";

import { toggleLabelManager } from "../../../redux/appSlice";

import { updateTask } from "../../../redux/tasksSlice";

import DurationPickerInput from "../../Kanban/Card/Components/DurationPicker/DurationPickerInput";

export default function DurationPickerComponent({
  item,
  time,
  title,
  isOpen,
  setIsOpen,
  updateItemLocal,
}) {
  const [hours, setHours] = useState(secondsToHms(time)?.hours);
  const [minutes, setMinutes] = useState(secondsToHms(time)?.minutes);

  const [timeEditable, setTimeEditable] = useState(time);

  const [inlineActive, setInlineActive] = useState(false);

  const dispatch = useDispatch();

  function updateTime(newValue) {
    if (updateItemLocal) {
      updateItemLocal(newValue);
    } else {
      if (title === "Actual") {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              actual_time: newValue,
            },
          })
        );
      } else {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              estimated_time: newValue,
            },
          })
        );
      }
    }
  }

  useEffect(() => {
    // If the estimated time is updated, let's update the hours and minutes
    if (!inlineActive) {
      const totalSeconds =
        parseInt(hours, 10) * 60 * 60 + parseInt(minutes, 10) * 60;

      setTimeEditable(totalSeconds);
    }
  }, [inlineActive]);

  useEffect(() => {
    // If the estimated time is updated, let's update the hours and minutes
    const hmsObject = secondsToHms(time);

    setHours(hmsObject?.hours);
    setMinutes(hmsObject?.minutes);
  }, [time]);

  useEffect(() => {
    // When we close the duration picker input
    // If there are differences, save the change'

    if (time !== timeEditable) {
      updateTime(timeEditable);
    }
  }, [timeEditable]);

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="duration-picker-container-mobile">
            <div className="dp-title">{title} Time</div>
            <DurationPickerInput
              hours={hours}
              minutes={minutes}
              setHours={setHours}
              setMinutes={setMinutes}
              visible={isOpen}
              time={time}
              setVisible={setIsOpen}
              inline={true}
              inlineActive={inlineActive}
              setInlineActive={setInlineActive}
            />
            <div
              onClick={() => {
                updateTime(0);
                setIsOpen(false);
              }}
              className="dp-button"
            >
              <ArchiveBoxIcon className="dp-button-icon" />
              <span>Clear Time</span>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}

function secondsToHms(d) {
  if (!d) {
    return null;
  }

  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}
