import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { Popover } from "antd";
import { AiOutlineCalendar, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { Checkbox } from "antd";

import { updateCurrentUser } from "../../redux/appSlice";
import _ from "lodash";
import { googleServerUrl } from "../../utils";
import AddCalendar from "./AddCalendar";

export default function CalendarSettings() {
  const { active_calendars = {} } = useSelector(
    (state) => state.app.currentUser
  );
  const userId = useSelector((state) => state.app.uid);

  const [calendars, setCalendars] = useState({});
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  function toggleCalendar(accountId, calendarId) {
    // Make a copy of the active_calendars object
    const newCalendars = _.cloneDeep(active_calendars);

    // If the calendar is already active (.calendars array contains calendarId), deactivate it
    if (newCalendars[accountId]?.calendars?.includes(calendarId)) {
      newCalendars[accountId].calendars = newCalendars[
        accountId
      ].calendars.filter((id) => id !== calendarId);
    }
    // Otherwise, activate it
    else {
      // Check if the account exists
      if (!newCalendars[accountId]) {
        newCalendars[accountId] = {
          calendars: [calendarId],
        };
      } else {
        // Check if the account has a .calendars array
        if (!newCalendars[accountId]?.calendars) {
          newCalendars[accountId].calendars = [];
        }

        newCalendars[accountId].calendars.push(calendarId);
      }
    }

    dispatch(
      updateCurrentUser({
        newValues: { active_calendars: newCalendars },
        previousValues: { active_calendars },
      })
    );
  }

  useEffect(() => {
    // Let's use axios to get the google calendar events from the api
    // Calling http://localhost:5001/ellie-18430/us-central1/getCalendarEvents?userId=${userId}&date=${date}
    if (userId) {
      axios
        .get(`${googleServerUrl}/calendar`, {
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data) {
            setCalendars(response.data.calendars);
            setLoaded(true);
          } else {
            setLoaded(true);
          }
        });
    }
  }, [userId]);

  if (!loaded) return null;

  return (
    <>
      {Object.keys(calendars).length > 0 && (
        <Popover
          placement="topRight"
          trigger="click"
          content={
            <div className="calendar-settings-inner">
              <div className="calendar-settings-title">Active calendars</div>
              <div className="calendar-settings-content">
                {Object.keys(calendars).map((account_id) => {
                  const account = calendars[account_id];
                  return (
                    <div className="calendar-settings-item">
                      <div className="calendar-account">{account.email}</div>
                      <div className="claendar-list">
                        {account.calendars.map((calendar) => {
                          return (
                            <div
                              onClick={() => {
                                toggleCalendar(account_id, calendar.id);
                              }}
                              className="calendar-list-item"
                            >
                              <div
                                className="calendar-list-item-checkbox"
                                style={{
                                  borderColor: calendar.backgroundColor,
                                  backgroundColor: active_calendars[
                                    account_id
                                  ]?.calendars?.includes(calendar.id)
                                    ? calendar.backgroundColor
                                    : "transparent",
                                }}
                              />
                              <span> {calendar.summary}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        >
          <div className="last-sync-icon-container">
            <AiOutlineCalendar className="calendar-settings-icon" />
          </div>
        </Popover>
      )}

      {Object.keys(calendars).length === 0 && (
        <AddCalendar>
          <div className="last-sync-icon-container">
            <AiOutlineCalendar className="calendar-settings-icon" />
          </div>
        </AddCalendar>
      )}
    </>
  );
}
