import "./Auth.css";

import { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import logo from "../../images/logo.svg";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { updateCurrentUser } from "../../redux/appSlice";
import moment from "moment";

import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import Onboarding from "./Onboarding";

export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSignUp = async (email) => {
    setLoading(true);

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        // ...
        window.mixpanel.track("User signed up", { source: "Web" });

        await setDoc(
          doc(db, "users", userCredential.user.uid),
          {
            pro_meta: {
              pro_source: "web",
              pro_status: "trial",
              pro_expiration_date: moment().add(14, "days").toDate(),
            },
          },
          {
            merge: true,
          }
        );

        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error.message;
        // ..

        setLoading(false);
        alert(errorMessage);
      });
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      handleSignUp(email);
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-left">
        <div className="auth-left-container">
          <img src={logo} className="auth-logo" alt="Ellie logo" />

          <div className="auth-modal-container">
            <h1 className="header">Let's get started 🥳</h1>
            <p className="description">
              Sign up via your email and password to continue
            </p>
            <div>
              <input
                className="auth-input"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={keyPress}
              />
            </div>
            <div>
              <input
                className="auth-input"
                type="password"
                placeholder="Your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={keyPress}
              />
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSignUp(email);
                }}
                className="auth-button"
                disabled={loading}
              >
                {loading ? <span>Loading</span> : <span>Create Account</span>}
              </button>
            </div>
            <button onClick={() => navigate("/")} className="auth-button-thin">
              or Log in
            </button>
          </div>
        </div>
      </div>
      <div className="auth-right"></div>
    </div>
  );
}
