import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {
  ThirdPartyDraggable,
} from "@fullcalendar/interaction";
import "./Calendar.css";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  updateTask,
  changeCalendarDate,
  addTasks,
  processTaskOrders,
  moveTaskToBottom,
  moveTaskToBottomFromBrainDump,
  moveTaskToBottomOfIncomplete,
} from "../../redux/tasksSlice";

import {
  setCardModalActive,
  setCreateTaskModalActive,
  setColumnSelected,
  setUpgradeVisible,
} from "../../redux/appSlice";
import Moment from "react-moment";

import CalendarSettings from "./CalendarSettings";
import AddCalendar from "./AddCalendar";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { BsFillCheckCircleFill, BsPlus } from "react-icons/bs";
import { useSpring, animated as a } from "react-spring";

import { TrashIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { Popover } from "antd";
import { useDroppable } from "@dnd-kit/core";
import { query, where, collection, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import axios from "axios";

import { SiGoogle, SiGooglecalendar } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { IoSync } from "react-icons/io5";
import { googleServerUrl } from "../../utils";

import { StyleCalendarWrapper } from "./WeekView";

function MiniDayView({}) {
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const {
    hide_calendar_sidebar,
    move_task_on_complete_disabled = false,
    active_calendars = {},
    calendar_accounts,
  } = useSelector((state) => state.app.currentUser);

  const [lastSync, setLastSync] = useState(null);

  const userId = useSelector((state) => state.app.uid);
  const dispatch = useDispatch();

  const calendarRef = useRef(null);

  const date = useSelector((state) => state.tasks.calendarDate);

  const [eventContextMenuActive, setEventContextMenuActive] = useState("");

  const tasks = useSelector((state) => state.tasks.data);

  const labels = useSelector((state) => state.labels.data);

  const [events, setEvents] = useState([]);
  const [googleEvents, setGoogleEvents] = useState({});

  const columnRef = useRef(null);

  const { isOver, setNodeRef } = useDroppable({
    id: "CALENDAR",
  });

  const { active } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi && !active) {
      calendarApi.unselect();
    }
  }, [active]);

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(date);
    }
  }, [date]);

  function loadTasksForDate(date) {
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTasks = onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks }));
      }
    });

    return unsubTasks;
  }

  function loadTaskOrderForDate(date) {
    const taskOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTaskOrder = onSnapshot(taskOrderQuery, (querySnapshot) => {
      const taskOrdersAdded = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            // Let's remove duplicates from the order array
            if (data.order) {
              data.order = data.order.filter((item, index) => {
                return data.order.indexOf(item) === index;
              });
            } else {
              data.order = [];
            }
            taskOrdersAdded.push({ ...data, id: doc.id });
          }
          if (change.type === "modified") {
            // Let's remove duplicates from the order array
            data.order = data.order.filter((item, index) => {
              return data.order.indexOf(item) === index;
            });

            taskOrdersAdded.push({ ...data, id: doc.id });
          }
        }
      });

      if (taskOrdersAdded && taskOrdersAdded.length > 0) {
        dispatch(
          processTaskOrders({
            taskOrder: taskOrdersAdded,
            override: true,
          })
        );
      } else {
        // Nothing to process, let's just do ghost tasks
        dispatch(
          processTaskOrders({
            taskOrder: [],
            override: true,
          })
        );
      }
    });

    return unsubTaskOrder;
  }

  useEffect(() => {
    if (userId) {
      const unsubTasks = loadTasksForDate(date);

      const unsubTaskOrder = loadTaskOrderForDate(date);

      return () => {
        unsubTasks();
        unsubTaskOrder();
      };
    }
  }, [dispatch, userId, date]);

  function loadCalendarEvents(activeCalendars) {
    // Cnovert activeCalendars to a json string
    const activeCalendarsJson = JSON.stringify(activeCalendars);
    setLastSync(null);
    axios
      .get(`${googleServerUrl}/getCalendarEvents`, {
        params: {
          userId: userId,
          timeMin: moment(date, "YYYY-MM-DD").startOf("day").toISOString(),
          timeMax: moment(date, "YYYY-MM-DD").endOf("day").toISOString(),
          active_calendars: activeCalendarsJson,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data && response.data.events?.length > 0) {
          const newEvents = convertGoogleEventsToEvents(response.data.events);

          // Take the array and group by id
          const newGoogleEvents = newEvents.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});
          setGoogleEvents(newGoogleEvents);
          setLastSync(new Date());
        } else {
          setLastSync(new Date());
        }
      })
      .catch((error) => {
        console.log(error);
        setLastSync(new Date());
      });
  }

  const active_calendars_ids_count = useMemo(() => {
    var ids = [];

    Object.keys(active_calendars || []).forEach((key) => {
      (active_calendars[key]?.calendars || []).forEach((key2) => {
        ids.push(key2);
      });
    });

    return ids.length;
  }, [active_calendars]);

  useEffect(() => {
    // Let's use axios to get the google calendar events from the api
    // Calling http://localhost:5001/ellie-18430/us-central1/getCalendarEvents?userId=${userId}&date=${date}
    if (userId) {
      loadCalendarEvents(active_calendars);
    }
  }, [userId, date, active_calendars_ids_count]);

  useEffect(() => {
    if (!hide_calendar_sidebar) {
      delay(500).then(() => {
        let calendarApi = calendarRef.current.getApi();
        if (calendarApi) {
          calendarApi.updateSize();
        }
      });
    }
  }, [hide_calendar_sidebar]);

  useEffect(() => {
    // Get all tasks from taskOrder
    const tasksFromOrder = Object.values(tasks).filter((task) => {
      // Only if task.date is in the same day as date
      var taskDate = task.date;

      if (!taskDate) {
        return false;
      }

      if (taskDate.toDate) {
        taskDate = taskDate.toDate();
      }

      return moment(taskDate).isSame(moment(date, "YYYY-MM-DD"), "day");
    });

    // Convert tasks to events
    const newEvents = convertTasksToEvents(tasksFromOrder, labels);

    // Check if events is different from newEvents, using lodash
    if (!_.isEqual(events, newEvents)) {
      setEvents(newEvents);
    }
  }, [tasks, labels, date]);

  useEffect(() => {
    if (document) {
      let draggableEl = document.getElementById("app-container");
      let draggable = new ThirdPartyDraggable(draggableEl, {
        itemSelector: ".card-container",
        // mirrorSelector: ".card-drag-preview",
        eventData: function (eventEl) {
          let id = eventEl.getAttribute("taskId");
          const task = tasks[id];

          if (task) {
            // convert from seconds to 00:00 format
            const duration = moment.duration(
              task.estimated_time || 0,
              "seconds"
            );
            // Convert duration (in seconds) to 00:00:00 format
            // preserving the 00:00:00 format
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            var m = moment().utcOffset(0);
            m.set({
              hour: hours,
              minute: minutes,
              second: seconds,
            });

            var durationString = m.format("HH:mm:ss");

            if (durationString === "00:00:00") {
              // Set it to 30 mins
              durationString = "00:30:00";
            }

            return {
              id: id,
              title: `${task.description}`,
              duration: durationString,
            };
          }
        },
      });

      // a cleanup function
      return () => draggable.destroy();
    }
  }, [tasks]);

  function updateTaskFromEvent(event) {
    // Let's get the event's start and end time
    const start = event.start;
    const end = event.end;
    const taskId = event.id;

    // Get the duration in seconds from the start and end time
    let duration = moment(end).diff(moment(start), "seconds");

    // if duration is not a number, set it to 1 hour in seconds
    if (isNaN(duration)) {
      duration = 3600;
    }

    // Update the task
    const task = tasks[taskId];
    const googleEvent = googleEvents[taskId];

    if (!googleEvent) {
      dispatch(
        updateTask({
          taskId: taskId,
          newData: {
            date: moment(date, "YYYY-MM-DD").toDate(),
            start: start,
            estimated_time: duration,
          },
          currentTask: task,
          saveGhostOrder: false,
          updateOrder: true,
        })
      );
    }
  }

  function removeEventForTask(event) {
    const taskId = event.id;
    // Update the task
    const task = tasks[taskId];

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          start: null,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );
  }

  function completeTaskForEvent(event) {
    const taskId = event.id;
    // Update the task
    const task = tasks[taskId];

    var newCompleteValue = task.complete ? false : true;
    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          complete: newCompleteValue,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );

    if (!move_task_on_complete_disabled) {
      // If we are marking as complete, move it to the bottom of the order

      // Get date in format YYYY-MM-DD
      const dateString = moment(task.date).format("YYYY-MM-DD");

      if (newCompleteValue) {
        dispatch(
          moveTaskToBottom({
            taskId: task.id,
            date: dateString,
          })
        );
      } else {
        // We are marking as incomplete, move it to the bottom of the incomplete tasks
        dispatch(
          moveTaskToBottomOfIncomplete({
            taskId: task.id,
            date: dateString,
          })
        );
      }
    }
  }

  const sidebarSpringProps = useSpring({
    width: hide_calendar_sidebar ? 0 : 280,
    overflow: hide_calendar_sidebar ? "hidden" : "visible",
    maxWidth: 280,
    minWidth: hide_calendar_sidebar ? 0 : 280,
  });

  const sidebarToggleSpringProps = useSpring({
    opacity: hide_calendar_sidebar ? 1 : 0,
    display: hide_calendar_sidebar ? "flex" : "none",
  });

  /*
  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: "task",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      };
    },
    hover(item, monitor) {
      const isOverInner = monitor.isOver();
      const isOverCurrentInner = monitor.isOver({ shallow: true });

      const sourceColumn = item.columnId;
      const destinationColumn = date;

      const taskDragged = item.id;

      console.log("isoverminidayview ", isOverCurrentInner);

      if (!isOverCurrentInner) {
        return;
      }
    },
    drop(item, monitor) {
      resetOrder();
    },
  }); 

  drop(columnRef); */

  // Set scroll time to 1 hour before the current time, unless time is before 1am
  // with format "HH:mm:ss"

  const scrollTime = moment().subtract(1, "hour").format("HH:mm:ss");

  return (
    <a.div style={sidebarSpringProps} className="sidebar-right">
      <div className="sidebar-right-container">
        <div className="mini-calendar-header">
          <div className="mini-cal-title">
            <div className="mini-cal-subdate">🕓 Timebox</div>
          </div>
          <div className="mini-calendar-right">
            <div className="mini-calendar-nav">
              <FiChevronLeft
                className="topbar-button nav-button"
                onClick={() => {
                  dispatch(
                    changeCalendarDate({
                      date: moment(date, "YYYY-MM-DD")
                        .subtract(1, "days")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              />
              <div
                onClick={() => {
                  dispatch(
                    changeCalendarDate({
                      date: moment().format("YYYY-MM-DD"),
                    })
                  );
                }}
                className="topbar-button today-button"
              >
                Today
              </div>
              <FiChevronRight
                className="topbar-button nav-button"
                onClick={() => {
                  dispatch(
                    changeCalendarDate({
                      date: moment(date, "YYYY-MM-DD")
                        .add(1, "days")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div ref={setNodeRef} className="mini-calendar-container">
          <StyleCalendarWrapper>
            <FullCalendar
              longPressDelay={300}
              eventColor="#FFE3C9"
              eventTextColor="#3c4043"
              selectable={true}
              selectMirror={true}
              ref={calendarRef}
              headerToolbar={false}
              // header={false}
              initialView="timeGridDay"
              plugins={[timeGridPlugin, interactionPlugin]}
              height="100%"
              nowIndicator={true}
              dayHeaders={true}
              dayHeaderContent={({ date, isToday }) => {
                return (
                  <div className="calendar-header-custom">
                    <div>{moment(date).format("ddd, MMM D")}</div>
                    {isToday && <div className="today">Today</div>}
                  </div>
                );
              }}
              unselectCancel={".create-task-modal"}
              editable={true}
              droppable={true}
              allDaySlot={false}
              events={[...Object.values(googleEvents), ...events]}
              initialDate={moment().format("YYYY-MM-DD")}
              //   columnHeader={false}
              scrollTimeReset={false}
              scrollTime={scrollTime}
              eventContent={(eventObject) => {
                const event = eventObject.event;

                const task = tasks[event.id];

                const durationLessThan15Minutes =
                  moment(event.end).diff(moment(event.start), "minutes") <= 15;

                const googleEvent = googleEvents[event.id];

                if (googleEvent) {
                  return (
                    <Popover
                      placement="left"
                      title={null}
                      visible={event.id === eventContextMenuActive}
                      content={
                        <div className="card-context-menu">
                          {googleEvent.conferenceData && (
                            <div
                              onClick={() => {
                                window.open(googleEvent.conferenceData.link);
                              }}
                              className="context-menu-row"
                            >
                              <img
                                src={googleEvent.conferenceData.icon}
                                className="context-mmenu-row-icon logo"
                                alt="Zoom Logo"
                              />
                              {googleEvent.conferenceData.summary}
                            </div>
                          )}

                          {!googleEvent.conferenceData &&
                            googleEvent.zoomLink && (
                              <div
                                onClick={() => {
                                  window.open(googleEvent.zoomLink);
                                }}
                                className="context-menu-row"
                              >
                                <img
                                  src={require("../../images/zoom-icon.png")}
                                  className="context-mmenu-row-icon logo"
                                  alt="Zoom Logo"
                                />
                                Join zoom meeting
                              </div>
                            )}

                          <div
                            onClick={() => {
                              // Open up a link to the google event in a new tab
                              window.open(googleEvent.googleEventLink);
                            }}
                            className="context-menu-row"
                          >
                            <img
                              src={require("../../images/google-calendar-icon.png")}
                              className="context-mmenu-row-icon logo"
                              alt="Google Calendar Logo"
                            />
                            Open in Google Calendar
                          </div>
                        </div>
                      }
                      onVisibleChange={(visible) => {
                        if (!visible) {
                          setEventContextMenuActive(null);
                        }
                      }}
                      trigger={"click"}
                      id="card-context-menu"
                    >
                      <div
                        className={`event-content ${
                          durationLessThan15Minutes ? "small-text" : ""
                        }`}
                      >
                        <div className="event-meta">
                          <div className={`event-title`}>
                            {googleEvent.title}
                          </div>
                          <div className="event-time">
                            {eventObject.timeText}
                          </div>
                        </div>
                        <FcGoogle
                          className={`event-complete-check ${
                            durationLessThan15Minutes ? "small" : ""
                          }`}
                          style={{
                            color: eventObject.textColor,
                          }}
                        />
                      </div>
                    </Popover>
                  );
                }

                if (task) {
                  return (
                    <Popover
                      placement="left"
                      title={null}
                      content={
                        <div className="card-context-menu">
                          <div
                            onClick={() => {
                              removeEventForTask(event);
                            }}
                            className="context-menu-row"
                          >
                            <TrashIcon className="context-mmenu-row-icon" />
                            Remove Event
                          </div>
                          <div
                            onClick={() => {
                              completeTaskForEvent(event);
                            }}
                            className="context-menu-row"
                          >
                            <CheckCircleIcon className="context-mmenu-row-icon" />
                            {task.complete && <span>Mark as incomplete</span>}
                            {!task.complete && <span>Mark as complete</span>}
                          </div>
                        </div>
                      }
                      visible={event.id === eventContextMenuActive}
                      onVisibleChange={(visible) => {
                        if (!visible) {
                          setEventContextMenuActive(null);
                        }
                      }}
                      trigger={["contextMenu"]}
                      id="card-context-menu"
                    >
                      <div
                        className={`event-content ${
                          durationLessThan15Minutes ? "small-text" : ""
                        }`}
                      >
                        <div className="event-meta">
                          <div
                            className={`event-title ${
                              task?.complete ? "completed" : ""
                            }`}
                          >
                            {event.title}
                          </div>
                          <div className="event-time">
                            {eventObject.timeText}
                          </div>
                        </div>
                        {task.complete && (
                          <BsFillCheckCircleFill
                            className={`event-complete-check ${
                              durationLessThan15Minutes ? "small" : ""
                            }`}
                            style={{
                              color: eventObject.textColor,
                            }}
                          />
                        )}
                      </div>
                    </Popover>
                  );
                }
              }}
              eventDidMount={(arg) => {
                const eventId = arg.event.id;

                const googleEvent = googleEvents[eventId];

                if (googleEvent) {
                  arg.el.style.border = "1px dashed #d1d1d1";
                  arg.el.style.backgroundColor = "#fefefe";
                  arg.el.style.borderLeft = `3px solid ${googleEvent.backgroundColor}`;
                } else {
                  arg.el.addEventListener("contextmenu", (jsEvent) => {
                    jsEvent.preventDefault();
                    setEventContextMenuActive(eventId);
                  });
                }
              }}
              eventClick={(arg) => {
                const event = arg.event;
                const task = tasks[event.id];

                const googleEvent = googleEvents[event.id];

                if (googleEvent) {
                  setEventContextMenuActive(event.id);
                }

                if (task) {
                  dispatch(setColumnSelected(null));
                  dispatch(setCardModalActive(task.id));
                }
              }}
              select={(arg) => {
                const { start, end } = arg;

                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  dispatch(
                    setCreateTaskModalActive({
                      active: true,
                      date: moment(start).format("YYYY-MM-DD"),
                      start_date: moment(start).toDate(),
                      duration: moment(end).diff(moment(start), "seconds"),
                    })
                  );
                }
              }}
              slotDuration="00:15"
              slotLabelInterval="01:00"
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: true,
                meridiem: "short",
              }}
              eventChange={(changeInfo) => {
                const event = changeInfo.event;

                updateTaskFromEvent(event);
              }}
              eventReceive={(info) => {
                const event = info.event;

                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                  info.revert();
                } else {
                  window.mixpanel.track("Task timeboxed", {
                    method: "dragged",
                  });

                  updateTaskFromEvent(event);
                  info.revert();
                }
              }}
            />
          </StyleCalendarWrapper>
        </div>

        <div className="calendar-settings-bar">
          {!calendar_accounts && (
            <div className="last-sync-text">
              {lastSync && <AddCalendar />}
              {!lastSync && (
                <>
                  {" "}
                  <IoSync className="last-sync-icon" />
                  <span>Syncing...</span>
                </>
              )}
            </div>
          )}
          {calendar_accounts && (
            <>
              <div
                onClick={() => {
                  loadCalendarEvents(active_calendars);
                }}
                className="last-sync-text"
              >
                {lastSync && (
                  <>
                    {" "}
                    <IoSync className="last-sync-icon" />
                    <span>
                      Last synced{" "}
                      <Moment interval={1000} fromNow>
                        {lastSync}
                      </Moment>{" "}
                    </span>
                  </>
                )}
                {!lastSync && (
                  <>
                    {" "}
                    <IoSync className="last-sync-icon" />
                    <span>Syncing...</span>
                  </>
                )}
              </div>
              <CalendarSettings />
            </>
          )}
        </div>
      </div>
    </a.div>
  );
}

function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#3c4043" : "white";
}

function addAlpha(color, opacity) {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

function convertGoogleEventsToEvents(googleEvents) {
  return googleEvents.map((googleEvent) => {
    const event = {
      id: googleEvent.id,
      title: googleEvent.summary,
      start: googleEvent.start.dateTime,
      end: googleEvent.end.dateTime,
      color: googleEvent.colorId,
      backgroundColor: googleEvent.backgroundColor,
      textColor: "#3c4043",
      borderColor: googleEvent.backgroundColor,
      googleEventLink: googleEvent.htmlLink,
      googleEvent: true,
      editable: false,
      zoomLink:
        googleEvent.location && googleEvent.location.includes("zoom")
          ? googleEvent.location
          : null,
      hangoutLink: googleEvent.hangoutLink,
      conferenceData: processConferenceData(googleEvent.conferenceData),
    };

    return event;
  });
}

function processConferenceData(conferenceData) {
  if (!conferenceData) {
    return null;
  }

  var processedConferenceData = {};

  const {
    conferenceId,
    conferenceSolution,
    createRequest,
    entryPoints,
    signature,
    conferenceDataVersion,
  } = conferenceData;

  if (conferenceSolution.name?.toLowerCase() === "zoom meeting") {
    processedConferenceData.link = entryPoints[0].uri;
    processedConferenceData.summary = "Join Zoom meeting";
    processedConferenceData.icon = require("../../images/zoom-icon.png");

    return processedConferenceData;
  }

  if (conferenceSolution.name === "Google Meet") {
    processedConferenceData.link = entryPoints[0].uri;
    processedConferenceData.summary = "Join Google Meet";
    processedConferenceData.icon = conferenceSolution.iconUri;

    return processedConferenceData;
  }

  return null;
}

function convertTasksToEvents(tasks, labels) {
  return tasks
    .map((task) => {
      // If there is no estimated time, make it 1 hour (in seconds)
      const estimated_time = task.estimated_time || 3600;

      var start_date = task.start;

      if (!start_date) {
        return null;
      }

      // Check if start_date is instanceof firebase timestamp, if so, convert to date
      if (start_date.toDate) {
        start_date = start_date.toDate();
      }

      // The end time is the start time plus the estimated time (using moment), then convert to date
      const end_date = moment(start_date)
        .add(estimated_time, "seconds")
        .toDate();

      return {
        title: task.description,
        start: start_date,
        end: end_date,
        allDay: false, // will make the time show
        editable: true,
        backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
        textColor: getContrastYIQ(labels?.[task.label]?.color || "#FFE3C9"),
        borderColor: labels?.[task.label]?.color || "#FFE3C9",
        id: task.id,
      };
    })
    .filter((event) => event !== null);
}

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check if the taskOrder is the same, using lodash _.isEqual
  return true;
}

export default React.memo(MiniDayView, areEqual);
