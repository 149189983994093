import "./Task.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Sheet, { SheetRef } from "react-modal-sheet";
import {
  TagIcon,
  ClockIcon,
  MoonIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, CalendarIcon } from "@heroicons/react/24/outline";

import CompleteInput from "../../Kanban/Card/Components/CompleteInput";

import { updateTask, deleteTask } from "../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../redux/appSlice";

import DatePicker from "./DatePicker";

import LabelPicker from "./LabelPicker";
import DurationPickerComponent from "./DurationPicker";
import FocusModeDurationPicker from "./FocusModeDurationPicker";
import FocusModeSelector from "./FocusModeSelector";

import moment from "moment";

export default function TaskView({ item, isOpen, setIsOpen }) {
  const { active_timer, focus_mode_type } = useSelector(
    (state) => state.app.currentUser
  );

  const [descriptionEditable, setDescriptionEditable] = useState(
    item?.description || ""
  );

  const dispatch = useDispatch();

  const { data: labels } = useSelector((state) => state.labels);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [labelPickerOpen, setLabelPickerOpen] = useState(false);
  const [actualTimePickerOpen, setActualTimePickerOpen] = useState(false);
  const [estimatedTimePickerOpen, setEstimatedTimePickerOpen] = useState(false);
  const [focusModeDurationPickerOpen, setFocusModeDurationPickerOpen] =
    useState(false);
  const [focusModePickerOpen, setFocusModePickerOpen] = useState(false);

  function enterStopwatchMode() {
    const active_timer = {
      task_id: item.id,
      active: true,
      focus_time_original: 0,
      focus_time_left: 0,
      last_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      type: "stopwatch",
    };

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );
  }

  useEffect(() => {
    if (active_timer && active_timer?.active == true && isOpen) {
      setIsOpen(false);
    }
  }, [active_timer, isOpen]);

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="tv-container">
            <div className="tv-top">
              <div className="complete-button">
                {" "}
                <CompleteInput item={item} complete={item?.complete} />
              </div>
              <TextareaAutosize
                onBlur={() => {
                  dispatch(
                    updateTask({
                      taskId: item.id,
                      currentTask: item,
                      newData: {
                        description: descriptionEditable,
                      },
                    })
                  );
                }}
                className="task-description"
                value={descriptionEditable}
                onChange={(e) => {
                  setDescriptionEditable(e.target.value);
                }}
              />
            </div>
            <div className="tv-bottom">
              <div
                onClick={() => {
                  setDatePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <CalendarIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    {getDayValue(item)}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setLabelPickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  {item.label && labels[item.label] && (
                    <>
                      <TagIcon
                        style={{ color: labels[item.label].color }}
                        className="task-meta-row-icon"
                      />
                      <span
                        style={{ color: labels[item.label].color }}
                        className="task-meta-row-text"
                      >
                        {labels[item.label].name}
                      </span>
                    </>
                  )}

                  {(!item.label || !labels[item.label]) && (
                    <>
                      <TagIcon className="task-meta-row-icon" />
                      <span className="task-meta-row-text">No Label</span>
                    </>
                  )}
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setEstimatedTimePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <ClockIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    Estimated Time:{" "}
                    {!isNaN(item.estimated_time)
                      ? moment.utc(item.estimated_time * 1000).format("H:mm")
                      : "0:00"}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setActualTimePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <ClockIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    Actual Time:{" "}
                    {!isNaN(item.actual_time)
                      ? moment.utc(item.actual_time * 1000).format("H:mm")
                      : "0:00"}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  if (focus_mode_type === "stopwatch") {
                    // Default is stopwatch mode, so let's start the timer
                    // Update the actual_time on the task
                    enterStopwatchMode();
                  } else if (focus_mode_type === "timer") {
                    // In timer mode, we should open the duration picker
                    setFocusModeDurationPickerOpen(
                      !focusModeDurationPickerOpen
                    );
                  } else {
                    // There is no focus mode yet, let's ask the user to pick one
                    setFocusModePickerOpen(!focusModePickerOpen);
                  }
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <MoonIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">Enter Focus Mode</span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>
              <div
                onClick={() => {
                  dispatch(deleteTask({ taskId: item.id, currentTask: item }));
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <TrashIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">Delete Task</span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>
            </div>
          </div>

          <DatePicker
            item={item}
            isOpen={datePickerOpen}
            setIsOpen={setDatePickerOpen}
          />
          <LabelPicker
            item={item}
            isOpen={labelPickerOpen}
            setIsOpen={setLabelPickerOpen}
          />

          <DurationPickerComponent
            item={item}
            time={item.estimated_time}
            title="Estimated"
            isOpen={estimatedTimePickerOpen}
            setIsOpen={setEstimatedTimePickerOpen}
          />

          <DurationPickerComponent
            item={item}
            time={item.actual_time}
            title="Actual"
            isOpen={actualTimePickerOpen}
            setIsOpen={setActualTimePickerOpen}
          />

          <FocusModeDurationPicker
            item={item}
            isOpen={focusModeDurationPickerOpen}
            setIsOpen={setFocusModeDurationPickerOpen}
            setTaskViewOpen={setIsOpen}
          />
          <FocusModeSelector
            item={item}
            isOpen={focusModePickerOpen}
            setIsOpen={setFocusModePickerOpen}
            setFocusModeDurationPickerOpen={setFocusModeDurationPickerOpen}
            enterStopwatchMode={enterStopwatchMode}
          />
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}

function getDayValue(item) {
  if (item.date == null) {
    return "Braindump";
  }

  var date = moment(item.date);
  if (date) {
    // Check if date is today
    if (date.isSame(moment(), "day")) {
      return "Today";
    }

    // Check if date is tomorrow
    if (date.isSame(moment().add(1, "day"), "day")) {
      return "Tomorrow";
    }

    // Return the date in a readable format
    return date.format("ddd, MMM Do");
  } else {
    return "Invalid date";
  }
}
