import React from "react";
import { useDispatch } from "react-redux";
import "./Column.css";

import moment from "moment";

import AddTaskCard from "./AddTaskCard";

import { createTask } from "../../../redux/tasksSlice";

export default function ColumnHeader({ date, setCreateActive }) {
  const dispatch = useDispatch();
  return (
    <div className="column-header">
      <div className="column-date-container">
        <div className="column-day-text">
          {moment(date, "YYYY-MM-DD").format("ddd")}
        </div>
        <div className="column-date-text">
          {moment(date, "YYYY-MM-DD").format("MMM D")}
        </div>
        {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
          <div className="today-text">Today</div>
        )}
      </div>
      <AddTaskCard setCreateActive={setCreateActive} date={date} />
    </div>
  );
}
