import "../Sidebar/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { ChevronRightIcon, ArrowSmLeftIcon } from "@heroicons/react/24/outline";

import AddTaskCard from "../Kanban/Column/AddTaskCard";

import Card from "../Kanban/Card";

import {
  updateCurrentUser,
  setColumnSelected,
  changeStartDate,
  loadDatesFromStartDate,
} from "../../redux/appSlice";
import {
  updateTask,
  changeCalendarDate,
  addTasks,
  processTaskOrders,
  moveTaskToBottom,
  moveTaskToBottomFromBrainDump,
  moveTaskToBottomOfIncomplete,
} from "../../redux/tasksSlice";
import CreateCard from "../Kanban/Card/CreateCard";
import { Resizable } from "re-resizable";
import { useSpring, animated as a } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";
import { query, where, collection, onSnapshot, doc } from "firebase/firestore";
import logo from "../../images/ellie-solid.svg";
import ColumnBody from "./Column/ColumnBody";
import moment from "moment";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { db } from "../../firebase";

export default function MiniKanbanView({
  orderEditable = [],
  onDragEnd,
  saveOrder,
}) {
  const userId = useSelector((state) => state.app.uid);
  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);
  const columnRef = useRef(null);

  const columnSelected = useSelector(
    (state) => state.app.columnSelected === "braindump"
  );
  const { data: tasks } = useSelector((state) => state.tasks);

  const { hide_calendar_sidebar, sidebar_width = 280 } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();

  const [createActive, setCreateActive] = useState(null);

  const sidebarSpringProps = useSpring({
    width: hide_calendar_sidebar ? 0 : 280,
    overflow: hide_calendar_sidebar ? "hidden" : "visible",
    maxWidth: 280,
    minWidth: hide_calendar_sidebar ? 0 : 280,
  });

  const [indexSelected, setIndexSelected] = useState(null);

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  // We'll use calendar date as Kanban date now
  const date = useSelector((state) => state.tasks.calendarDate);

  function loadTasksForDate(date) {
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTasks = onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks }));
      }
    });

    return unsubTasks;
  }

  function loadTaskOrderForDate(date) {
    const taskOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where("date", ">=", moment(date, "YYYY-MM-DD").startOf("day").toDate()),
      where("date", "<=", moment(date, "YYYY-MM-DD").endOf("day").toDate())
    );

    const unsubTaskOrder = onSnapshot(taskOrderQuery, (querySnapshot) => {
      const taskOrdersAdded = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            // Let's remove duplicates from the order array
            if (data.order) {
              data.order = data.order.filter((item, index) => {
                return data.order.indexOf(item) === index;
              });
            } else {
              data.order = [];
            }
            taskOrdersAdded.push({ ...data, id: doc.id });
          }
          if (change.type === "modified") {
            // Let's remove duplicates from the order array
            data.order = data.order.filter((item, index) => {
              return data.order.indexOf(item) === index;
            });

            taskOrdersAdded.push({ ...data, id: doc.id });
          }
        }
      });

      if (taskOrdersAdded && taskOrdersAdded.length > 0) {
        dispatch(
          processTaskOrders({
            taskOrder: taskOrdersAdded,
            override: true,
          })
        );
      } else {
        // Nothing to process, let's just do ghost tasks
        dispatch(
          processTaskOrders({
            taskOrder: [],
            override: true,
          })
        );
      }
    });

    return unsubTaskOrder;
  }

  useEffect(() => {
    if (userId) {
      const unsubTasks = loadTasksForDate(date);

      const unsubTaskOrder = loadTaskOrderForDate(date);

      return () => {
        unsubTasks();
        unsubTaskOrder();
      };
    }
  }, [dispatch, userId, date]);

  function changeDate(date) {
    dispatch(changeStartDate(date));

    dispatch(loadDatesFromStartDate(date));

    dispatch(
      changeCalendarDate({
        date: date,
      })
    );
  }

  return (
    <a.div
      className="sidebar-right"
      style={sidebarSpringProps}
      onMouseMove={() => {
        if (navigatedViaKeyboard) {
          setNavigatedViaKeyboard(false);
        }
      }}
    >
      <div className="sidebar-container">
        <div className="mini-kanban-header">
          <div className="mini-cal-title">
            <div className="mini-cal-subdate">🌤️ Tasks</div>
          </div>
          <div className="mini-calendar-right">
            <div className="mini-calendar-nav">
              <FiChevronLeft
                className="topbar-button nav-button"
                onClick={() => {
                  var newDate = moment(date, "YYYY-MM-DD")
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");

                  changeDate(newDate);
                }}
              />
              <div
                onClick={() => {
                  changeDate(moment().format("YYYY-MM-DD"));
                }}
                className="topbar-button today-button"
              >
                Today
              </div>
              <FiChevronRight
                className="topbar-button nav-button"
                onClick={() => {
                  var newDate = moment(date, "YYYY-MM-DD")
                    .add(1, "days")
                    .format("YYYY-MM-DD");

                  changeDate(newDate);
                }}
              />
            </div>
          </div>
        </div>
        <div className="braindump-header">
          <div className="braindump-header-title-container">
            <div className="column-date-container mini">
              <div className="column-day-text">
                {moment(date, "YYYY-MM-DD").format("ddd")}
              </div>
              <div className="column-date-text">
                {moment(date, "YYYY-MM-DD").format("MMM D")}
              </div>
              {moment(date, "YYYY-MM-DD").isSame(moment(), "d") && (
                <div className="today-text">Today</div>
              )}
            </div>
          </div>

          <AddTaskCard setCreateActive={setCreateActive} date={date} />
          {createActive && (
            <CreateCard
              setCreateActive={setCreateActive}
              setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              date={date}
            />
          )}
        </div>

        <ColumnBody
          order={orderEditable}
          date={date}
          indexSelected={indexSelected}
          manuallySelectColumn={manuallySelectColumn}
          navigatedViaKeyboard={navigatedViaKeyboard}
          setIndexSelected={setIndexSelected}
          mini={true}
        />
      </div>
    </a.div>
  );
}
