import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Modal, Switch } from "antd";

import {
  BellIcon,
  UserGroupIcon,
  ViewGridAddIcon,
  ShieldCheckIcon,
  ChatIcon,
  EmojiHappyIcon,
  TagIcon,
  ClipboardDocumentListIcon,
  ChartPieIcon,
  CalendarIcon,
  MoonIcon,
  RefreshIcon,
  ClockIcon,
  ArrowPathIcon,
  FaceSmileIcon,
  RectangleStackIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  ArrowPathRoundedSquareIcon,
  HeartIcon,
  InboxIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";

import { XMarkIcon } from "@heroicons/react/24/outline";

import logo from "../../images/logo.svg";

import { SparklesIcon } from "@heroicons/react/24/solid";

import "./Upgrade.css";

import { serverUrl, isDev } from "../../utils";

import { useMediaQuery } from "react-responsive";

import { setUpgradeVisible } from "../../redux/appSlice";
import { getAuth } from "firebase/auth";

import { FaTasks } from "react-icons/fa";
import {
  BsCheck,
  BsCheckCircle,
  BsClock,
  BsDash,
  BsGoogle,
  BsCheck2Square,
  BsCheck2Circle,
  BsApple,
  BsClockFill,
  BsCheckSquareFill,
  BsCheckCircleFill,
  BsHeartFill,
} from "react-icons/bs";

import { BiBox, BiCheckbox } from "react-icons/bi";

import { SiGoogle } from "react-icons/si";

import { FiRepeat } from "react-icons/fi";

import { HiTag } from "react-icons/hi";

const UpgradeModal = ({
  value,
  setValue,
  removeEnabled = false,
  yearEnabled = true,
  inlineButton = false,
  onDatePickerClose,
}) => {
  const isVisible = useSelector((state) => state.app.upgradeVisible);

  const currentUser = useSelector((state) => state.app.currentUser);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const auth = getAuth();
  const user = auth.currentUser;
  const prices = {
    yearly: {
      price_id: "price_1KLxz0J8MnUoTOQQexCyp12s",
    },
    monthly: {
      price_id: "price_1KLxz0J8MnUoTOQQGw45Hhh9",
    },
    lifetime: {
      price_id: "price_1KLxz0J8MnUoTOQQakq8gBtB",
    },
  };

  const prodPrices = {
    yearly: {
      price_id: "price_1L7Q5WJ8MnUoTOQQ6h9AreuF",
    },
    monthly: {
      price_id: "price_1L7Q4eJ8MnUoTOQQ1xsFRhDd",
    },
    lifetime: {
      price_id: "price_1LDa9SJ8MnUoTOQQZJMKNaaJ",
    },
  };

  const [yearly, setYearly] = useState(false);

  var discount = null;

  const dispatch = useDispatch();

  return (
    <Modal
      closable={false}
      footer={null}
      style={{ top: isMobile ? "20px" : "30px" }}
      bodyStyle={{ padding: "0px" }}
      className={"light-theme"}
      visible={isVisible}
      width={850}
      zIndex={10000}
      onCancel={() => {
        window.mixpanel.track("Web upgrade flow dismissed");
        dispatch(setUpgradeVisible(false));
      }}
    >
      <div className="upgrade-container">
        <div className="top-half">
          <div className="upgrade-modal-header">
            <div
              onClick={() => {
                window.mixpanel.track("Web upgrade flow dismissed");
                dispatch(setUpgradeVisible(false));
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="upgrade-h1">✨ Ready for an upgrade? ✨</div>
            <div className="upgrade-h2">
              Increase your productivity with powerful features
            </div>
          </div>

          <div className="pricing-table">
            <div className="table-row upgrade-header">
              <div className="table-cell">
                <div className="pro-feature-cell"></div>
              </div>
              <div className="table-cell upgrade-header">
                <div className="name">Basic</div>
                <div className="price">Free</div>
                <div className="plan-button">
                  <BsCheck className="check" />
                  Your Plan
                </div>
                <div className="billing-switch"></div>
              </div>
              <div className="table-cell upgrade-header">
                <div className="name">Pro</div>
                {yearly ? (
                  <div className="price">49.99/yr</div>
                ) : (
                  <div className="price">4.99/mo</div>
                )}
                <div
                  onClick={() => {
                    fetch(`${serverUrl}/create-checkout-session`, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        priceId: isDev
                          ? prices[selectedPlan].price_id
                          : prodPrices[selectedPlan].price_id,
                        client_reference_id: user.uid,
                        customer: currentUser.stripe_meta?.stripe_customer,
                        customer_email: user.email,
                        discount: discount,
                        mode:
                          selectedPlan === "lifetime"
                            ? "payment"
                            : "subscription",
                      }),
                    }).then((response) => {
                      // Examine the text in the response
                      response.json().then(function (data) {
                        window.location.assign(data.session.url);
                      });
                    });
                  }}
                  className="upgrade-plan-button"
                >
                  Upgrade
                </div>
                <div className="billing-switch">
                  <Switch
                    size="small"
                    checked={yearly}
                    onChange={(checked) => {
                      if (checked) {
                        setSelectedPlan("yearly");
                      } else {
                        setSelectedPlan("monthly");
                      }
                      setYearly(checked);
                    }}
                  />
                  Billed Yearly <span className="save-chip">Save 20%</span>
                </div>
              </div>
            </div>
            <FeatureRow
              icon={
                <RectangleStackIcon
                  className="feature-icon"
                  style={{
                    // Blue
                    color: "#3B82F6",
                  }}
                />
              }
              description="Unlimited tasks"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <InboxIcon
                  className="feature-icon"
                  style={{
                    // Purple
                    color: "#8B5CF6",
                  }}
                />
              }
              description="Braindump"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <DevicePhoneMobileIcon
                  className="feature-icon"
                  style={{
                    // Green
                    color: "#10B981",
                  }}
                />
              }
              description="iPhone & iPad App"
              basic={true}
              pro={true}
            />

            <FeatureRow
              icon={
                <ClockIcon
                  className="feature-icon"
                  style={{
                    // Yellow
                    color: "#F59E0B",
                  }}
                />
              }
              description="Timebox mode"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <CalendarIcon
                  className="feature-icon"
                  style={{
                    // Green
                    color: "#10B981",
                  }}
                />
              }
              description="Google Calendar Integration"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <CalendarDaysIcon
                  className="feature-icon"
                  style={{
                    // Black
                    color: "#000000",
                  }}
                />
              }
              description="Apple Calendar Integration"
              basic={false}
              pro={true}
            />

            <FeatureRow
              icon={
                <TagIcon
                  className="feature-icon"
                  style={{
                    // Red
                    color: "#EF4444",
                  }}
                />
              }
              description="Labels & Filters"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <ListBulletIcon
                  className="feature-icon"
                  style={{
                    // Orange
                    color: "#F97316",
                  }}
                />
              }
              description="Subtasks"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <ArrowPathRoundedSquareIcon
                  className="feature-icon"
                  style={{
                    // Blue
                    color: "#3B82F6",
                  }}
                />
              }
              description="Recurring Tasks"
              basic={false}
              pro={true}
            />
            <FeatureRow
              icon={
                <HeartIcon
                  className="feature-icon"
                  style={{
                    // Red
                    color: "#EF4444",
                  }}
                />
              }
              description="Support an independent developer"
              basic={false}
              pro={true}
            />
          </div>

          <div className="student-card">
            <span>😴 Tired of subscriptions? <span
              style={{
                // Purple
                color: "#8B5CF6",
                cursor: "pointer",
              }}
              onClick={() => {
                fetch(`${serverUrl}/create-checkout-session`, {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    priceId: isDev
                      ? prices["lifetime"].price_id
                      : prodPrices["lifetime"].price_id,
                    client_reference_id: user.uid,
                    customer: currentUser.stripe_meta?.stripe_customer,
                    customer_email: user.email,
                    discount: discount,
                    mode: "payment",
                  }),
                }).then((response) => {
                  // Examine the text in the response
                  response.json().then(function (data) {
                    window.location.assign(data.session.url);
                  });
                });

              }}
            >Click here</span> to purchase a lifetime license ($149.99)</span> 
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;

function FeatureRow({ icon, description, basic, pro }) {
  return (
    <div className="table-row">
      <div className="table-cell">
        <div className="pro-feature-cell">
          {icon}
          {description == "Support an independent developer" ? (
            <a
              href="https://ellieplanner.com/about"
              target="_blank"
              className="pro-feature-text"
              style={{
                // Purple
                color: "#8B5CF6",
              }}
            >
              Support an Independent Developer
            </a>
          ) : (
            <div className="pro-feature-text">{description}</div>
          )}
        </div>
      </div>
      <div className="table-cell">
        {basic ? <BsCheck className="check" /> : <BsDash className="check" />}
      </div>
      <div className="table-cell">
        {pro ? <BsCheck className="check" /> : <BsDash className="check" />}
      </div>
    </div>
  );
}
