import React, { useState, useRef, useEffect } from "react";
import "./Card.css";

import _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import Subtasks from "./Components/Subtasks";
import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import { createTask } from "../../../redux/tasksSlice";
import { createRecurringTask } from "../../../redux/recurringTasksSlice";

import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";

import { setToastVisible, setColumnSelected } from "../../../redux/appSlice";

import TimeSection from "./Components/TimeSection";
import { useHotkeys } from "react-hotkeys-hook";
import RecurringInput from "./Components/RecurringInput";

import { generateTemplateFromTask } from "../../../utils";

export default function CreateCard({
  setCreateActive,
  date,
  setNavigatedViaKeyboard,
}) {

  const { label_filters: activeLabels } = useSelector(
    (state) => state.app?.currentUser
  );

  const dispatch = useDispatch();
  const [itemEditable, setItemEditable] = useState({
    date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
    id: uuidv4(),
    complete: false,
  });

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);
  const [recurringTask, setRecurringTask] = useState(null);

  const [labelPickerActive, setLabelPickerActive] = useState(false);
  const [timeSectionActive, setTimeSectionActive] = useState(false);
  const taskInputRef = useRef(null);
  async function updateItemEditableValue(key, value, save = false) {
    const tempItemEditable = _.cloneDeep(itemEditable);

    tempItemEditable[key] = value;

    setItemEditable(tempItemEditable);
  }

  const [subtasksActive, setSubtasksActive] = useState(false);

  useHotkeys(
    "cmd+l",
    (e) => {
      e.preventDefault();

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, labelPickerActive]
  );

  useHotkeys(
    "cmd+i",
    (e) => {
      e.preventDefault();

      if (timeSectionActive) {
        setTimeSectionActive(false);
        // Let's focus on input
      } else {
        setTimeSectionActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, timeSectionActive]
  );

  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();

      if (subtasksActive) {
        setSubtasksActive(false);
      } else {
        setSubtasksActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
    },
    [setCreateActive, subtasksActive]
  );

  useEffect(() => {
    // If the label has changed, let's create the new task
    if (!isBlank(itemEditable.description)) {
      createTaskFunction(true);
    }
  }, [itemEditable?.label]);

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      if (!isBlank(itemEditable.description)) {
        createTaskFunction(true);
      } else {
        setCreateActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        setCreateActive &&
        !labelPickerActive &&
        !timeSectionActive &&
        !subtasksActive
          ? true
          : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      setCreateActive,
      itemEditable,
      taskInputRef,
      labelPickerActive,
      timeSectionActive,
      subtasksActive,
    ]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      setCreateActive(false);

      // ... set up our own saving dialog.
    },
    {
      enabled: setCreateActive ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [setCreateActive, taskInputRef]
  );

  useEffect(() => {
    // If there is no label selected, but there is 1 label in activeLabels, select it
    if (!itemEditable.label && activeLabels && activeLabels.length === 1) {
      updateItemEditableValue("label", activeLabels[0]);
    }
  }, [activeLabels?.length, itemEditable.label]);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  useEffect(() => {
    if (itemEditable.recurring && recurringTask) {
      updateItemEditableValue("recurring_id", recurringTask.id, true);
    } else {
      updateItemEditableValue("recurring_id", null, true);
    }
  }, [itemEditable.recurring, recurringTask]);

  function createTaskFunction(keepOpen) {
    // If no labels are selected for this item, and there is more than 1 label filter active,
    // then notify the user that the item was hidden
    if (itemEditable.label == null && activeLabels?.length > 1) {
      dispatch(
        setToastVisible({
          toastType: "success",
          message:
            "Task was created but hidden because you have active filters",
        })
      );
    }

    // Also create the recurring task, if there is one
    if (recurringTask) {
      const template = generateTemplateFromTask(itemEditable);
      const recurringTaskTemp = _.cloneDeep(recurringTask);
      recurringTaskTemp.task_template = template;
      dispatch(createRecurringTask({ recurringTask: recurringTaskTemp }));
    }

    // Let's go ahead and create
    dispatch(createTask(itemEditable));

    setRecurringTask(null);

    setItemEditable({
      date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
      description: "",
      estimated_time: null,
      label: null,
      id: uuidv4(),
      complete: false,
    });
    if (keepOpen) {
      setCreateActive(null);
      setNavigatedViaKeyboard(true);
      if (date) {
        dispatch(setColumnSelected(date));
      } else {
        dispatch(setColumnSelected("braindump"));
      }
    } else {
      setCreateActive(null);
    }
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isBlank(itemEditable.description)) {
          setCreateActive(null);
        } else {
          createTaskFunction(false);
        }
      }}
      disabled={labelPickerActive || recurringTaskMenuOpen}
    >
      <div className="draggable-card-container">
        <div
          className={`card-container ${
            itemEditable.complete ? " complete" : ""
          }`}
        >
          <div className="card-header">
            <TextareaAutosize
              className="card-description"
              ref={taskInputRef}
              value={itemEditable.description}
              onChange={(e) => {
                updateItemEditableValue("description", e.target.value);
              }}
              autoFocus
            />

            <div
              onClick={(event) => {
                setTimeSectionActive(!timeSectionActive);
              }}
              className="card-time-estimate"
            >
              {!isNaN(itemEditable.estimated_time)
                ? moment.utc(itemEditable.estimated_time * 1000).format("H:mm")
                : "0:00"}
            </div>
          </div>
          <div className="card-footer">
            <div className="card-left-buttons">
              <CompleteInput
                complete={itemEditable.complete}
                updateCompleteLocal={(newValue) => {
                  updateItemEditableValue("complete", newValue, true);
                }}
              />
              <RecurringInput
                item={itemEditable}
                recurringTaskLocal={recurringTask}
                updateRecurringLocal={(newValue) => {
                  if (newValue) {
                    // There is a recurring task, let's set the itemEditable
                    updateItemEditableValue("recurring", true, true);
                  } else {
                    // There is no recurring task, let's set the itemEditable
                    updateItemEditableValue("recurring", false, true);
                  }
                  setRecurringTask(newValue);
                }}
                creation={true}
                recurringTaskMenuOpen={recurringTaskMenuOpen}
                setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
              />
              <SubtasksIcon
                action={() => {
                  setSubtasksActive(!subtasksActive);
                }}
                item={itemEditable}
              />
            </div>

            <LabelSelector
              label={itemEditable.label}
              updateLabelLocal={(newValue) => {
                updateItemEditableValue("label", newValue, true);
              }}
              labelPickerActive={labelPickerActive}
              setLabelPickerActive={setLabelPickerActive}
            />
          </div>
          <TimeSection
            item={itemEditable}
            updateTimeLocal={(key, newValue) => {
              updateItemEditableValue(key, newValue, true);
            }}
            timeSectionActive={timeSectionActive}
            setTimeSectionActive={setTimeSectionActive}
            activelySelected={true}
          />

          <Subtasks
            item={itemEditable}
            updateSubtasksLocal={(newValue) => {
              updateItemEditableValue("subtasks", newValue, true);
            }}
            subtasksActive={subtasksActive}
            setSubtasksActive={setSubtasksActive}
            activelySelected={true}
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
}
