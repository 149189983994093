import "./FocusMode.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import Sheet from "react-modal-sheet";
import { Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";

import {
  CheckIcon,
  SearchIcon,
  ArrowSmRightIcon,
  PlayIcon,
  ClockIcon,
  MoonIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import {
  SunIcon,
  ArrowCircleRightIcon,
  ArchiveIcon,
} from "@heroicons/react/24/solid";

import moment from "moment";

import { Mobile, Default } from "../../../mediaUtils";

import { toggleLabelManager } from "../../../redux/appSlice";

import { updateTask } from "../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../redux/appSlice";

import DurationPickerInput from "../../Kanban/Card/Components/DurationPicker/DurationPickerInput";

export default function FocusModeSelector({
  isOpen,
  setIsOpen,
  setFocusModeDurationPickerOpen,
  enterStopwatchMode,
}) {
  const dispatch = useDispatch();

  const saveFocusModePreferences = (type = "timer") => {
    dispatch(
      updateCurrentUser({
        newValues: { focus_mode_type: type },
        previousValues: { focus_mode_type: null },
      })
    );

    window.mixpanel.track("Focus mode type selected", {
      type: type,
    });

    if (type === "timer") {
      setIsOpen(false);
      setFocusModeDurationPickerOpen(true);
    } else {
      setIsOpen(false);
      enterStopwatchMode();
    }
  };

  return (
    <>
      <Default>
        <Modal
          title={null}
          footer={null}
          closeIcon={null}
          closable={false}
          width={400}
          visible={isOpen}
          onOk={() => {
            setIsOpen(false);
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
        >
          <div className="duration-picker-container-mobile">
            <div className="dp-title">🤔 What is focus mode?</div>

            <div className="dp-description">
              Focus mode lets you focus in a distraction free view + track time
              spent on a specific task.
            </div>

            <div className="dp-title">🧘 So, how do you like to focus?</div>

            <div className="dp-description">
              We'll default to this mode in the future, but you can always
              change this later in settings.
            </div>

            <div
              onClick={() => {
                saveFocusModePreferences("timer");
              }}
              className="fmp-button"
            >
              <div className="fmp-content">
                <div className="fmp-title">⏳ Countdown timer mode</div>
                <div className="fmp-description">
                  Best for pomodoro style sessions (example: Focus for 25 mins).
                </div>
              </div>
              <ArrowRightIcon className="fmp-icon" />
            </div>

            <div
              onClick={() => {
                saveFocusModePreferences("stopwatch");
              }}
              className="fmp-button"
            >
              <div className="fmp-content">
                <div className="fmp-title">⏱ Stopwatch mode</div>
                <div className="fmp-description">
                  Best to track time spent on a task (no time limit)
                </div>
              </div>
              <ArrowRightIcon className="fmp-icon" />
            </div>
          </div>
        </Modal>
      </Default>
      <Mobile>
        <Sheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          initialSnap={1}
          snapPoints={[800, 500, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="duration-picker-container-mobile">
                <div className="dp-title">🤔 What is focus mode?</div>

                <div className="dp-description">
                  Focus mode lets you focus in a distraction free view + track
                  time spent on a specific task.
                </div>

                <div className="dp-title">🧘 So, how do you like to focus?</div>

                <div className="dp-description">
                  We'll default to this mode in the future, but you can always
                  change this later in settings.
                </div>

                <div
                  onClick={() => {
                    saveFocusModePreferences("timer");
                  }}
                  className="fmp-button"
                >
                  <div className="fmp-content">
                    <div className="fmp-title">⏳ Countdown timer mode</div>
                    <div className="fmp-description">
                      Best for pomodoro style sessions (example: Focus for 25
                      mins).
                    </div>
                  </div>
                  <ArrowRightIcon className="fmp-icon" />
                </div>

                <div
                  onClick={() => {
                    saveFocusModePreferences("stopwatch");
                  }}
                  className="fmp-button"
                >
                  <div className="fmp-content">
                    <div className="fmp-title">⏱ Stopwatch mode</div>
                    <div className="fmp-description">
                      Best to track time spent on a task (no time limit)
                    </div>
                  </div>
                  <ArrowRightIcon className="fmp-icon" />
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(false);
            }}
          />
        </Sheet>
      </Mobile>
    </>
  );
}

function secondsToHms(d) {
  if (!d) {
    return null;
  }

  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}
