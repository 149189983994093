import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

import { useMediaQuery } from "react-responsive";

import { XMarkIcon } from "@heroicons/react/24/outline";

import { BsCheckCircleFill } from "react-icons/bs";

import success from "../../images/success.gif";

import "./Upgrade.css";

import confetti from "canvas-confetti";

import { useSelector } from "react-redux";
import { updateCurrentUser } from "../../redux/appSlice";

export default function TrialModal({ setIsExpanded }) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const userId = useSelector((state) => state.app.uid);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const { trial_modal_shown = false, pro_meta } = useSelector(
    (state) => state.app.currentUser
  );

  var count = 200;
  var defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio, opts) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      })
    );
  }

  useEffect(() => {
    if (userId) {
      if (
        subscriptionActive &&
        pro_meta.pro_status == "trial" &&
        !trial_modal_shown
      ) {
        setShow(true);
      }
    }
  }, [subscriptionActive, userId, pro_meta, trial_modal_shown]);

  useEffect(() => {
    if (show) {
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }
  }, [show]);

  return (
    <div>
      <Modal
        closable={false}
        footer={null}
        style={{ top: isMobile ? "20px" : "30px" }}
        bodyStyle={{ padding: "0px" }}
        className={"light-theme"}
        visible={show}
        width={450}
        zIndex={10000000000}
        onCancel={() => {
          dispatch(
            updateCurrentUser({
              newValues: {
                trial_modal_shown: true,
              },
              previousValues: {
                trial_modal_shown: false,
              },
            })
          );
          setShow(false);
        }}
      >
        <div className="upgrade-container">
          {" "}
          <div className="top-half" style={{ padding: "20px" }}>
            <div
              onClick={() => {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      trial_modal_shown: true,
                    },
                    previousValues: {
                      trial_modal_shown: false,
                    },
                  })
                );
                setShow(false);
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="success-h1 small">
              🥳 <span className="success-text">Surprise!</span>
            </div>
            <div className="success-h2">You've unlocked pro features</div>
            <img className="success-image small" src={success} />
            <div className="success-h3">
              All pro features are unlocked for the next 14 days so you can
              fully experience Ellie ⚡️
            </div>
            <div className="features-card">
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Access to our native iOS app
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Powerful timeboxing mode
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Google calendar integration
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Apple calendar integration (iOS)
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Labels & filters
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Recurring tasks
              </div>
              <div className="feature-line">
                <BsCheckCircleFill className="checkmark" />
                Subtasks
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
