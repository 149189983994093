import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import TopbarActions from "../Kanban/TopbarActions";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import styled from "@emotion/styled";
import interactionPlugin, {
  ThirdPartyDraggable,
} from "@fullcalendar/interaction";
import axios from "axios";
import moment from "moment";
import { FcGoogle } from "react-icons/fc";
import { Popover } from "antd";

import {
  updateTask,
  changeCalendarDate,
  addTasks,
  processTaskOrders,
  moveTaskToBottom,
  moveTaskToBottomFromBrainDump,
  moveTaskToBottomOfIncomplete,
} from "../../redux/tasksSlice";
import { googleServerUrl } from "../../utils";
import {
  setCardModalActive,
  setCreateTaskModalActive,
  setColumnSelected,
  setUpgradeVisible,
} from "../../redux/appSlice";

import _ from "lodash";

import { TrashIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { BsFillCheckCircleFill, BsPlus } from "react-icons/bs";
import Toolbar from "../Toolbar";
import FloatingToolbar from "./FloatingToolbar";
import "./Calendar.css";

export default function WeekView() {
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const userId = useSelector((state) => state.app.uid);
  const {
    hide_calendar_sidebar,
    hide_sidebar,
    move_task_on_complete_disabled = false,
    active_calendars = {},
    calendar_accounts,
  } = useSelector((state) => state.app.currentUser);

  const dates = useSelector((state) => state.app.dates);
  const calendarRef = useRef(null);

  const [events, setEvents] = useState([]);
  const [googleEvents, setGoogleEvents] = useState({});

  const scrollTime = moment().subtract(1, "hour").format("HH:mm:ss");

  const [eventContextMenuActive, setEventContextMenuActive] = useState("");

  const tasks = useSelector((state) => state.tasks.data);

  const labels = useSelector((state) => state.labels.data);

  const dispatch = useDispatch();

  const [lastSync, setLastSync] = useState(null);
  const date = useSelector((state) => state.tasks.calendarDate);

  const [eventChanges, setEventChanges] = useState({});
  // When we make a change, let's create a timer to save it. If we make another change before the timer is up, we'll cancel the timer and start a new one.
  const [eventChangeTimer, setEventChangeTimer] = useState(null);

  const { active } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  const active_calendars_ids_count = useMemo(() => {
    var ids = [];

    Object.keys(active_calendars || []).forEach((key) => {
      (active_calendars[key]?.calendars || []).forEach((key2) => {
        ids.push(key2);
      });
    });

    return ids.length;
  }, [active_calendars]);

  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  }

  function convertGoogleEventsToEvents(googleEvents) {
    return googleEvents.map((googleEvent) => {
      var isAllDay = false;

      if (googleEvent.start.date) {
        isAllDay = true;
      }

      const event = {
        id: googleEvent.id,
        title: googleEvent.summary,
        start: isAllDay ? googleEvent.start.date : googleEvent.start.dateTime,
        end: isAllDay ? googleEvent.end.date : googleEvent.end.dateTime,
        color: googleEvent.colorId,
        backgroundColor: googleEvent.backgroundColor,
        textColor: "#3c4043",
        borderColor: googleEvent.backgroundColor,
        googleEventLink: googleEvent.htmlLink,
        allDay: isAllDay,
        googleEvent: true,
        editable: false,
        zoomLink:
          googleEvent.location && googleEvent.location.includes("zoom")
            ? googleEvent.location
            : null,
        hangoutLink: googleEvent.hangoutLink,
        conferenceData: processConferenceData(googleEvent.conferenceData),
      };

      return event;
    });
  }

  function processConferenceData(conferenceData) {
    if (!conferenceData) {
      return null;
    }

    var processedConferenceData = {};

    const {
      conferenceId,
      conferenceSolution,
      createRequest,
      entryPoints,
      signature,
      conferenceDataVersion,
    } = conferenceData;

    if (conferenceSolution.name?.toLowerCase() === "zoom meeting") {
      processedConferenceData.link = entryPoints[0].uri;
      processedConferenceData.summary = "Join Zoom meeting";
      processedConferenceData.icon = require("../../images/zoom-icon.png");

      return processedConferenceData;
    }

    if (conferenceSolution.name === "Google Meet") {
      processedConferenceData.link = entryPoints[0].uri;
      processedConferenceData.summary = "Join Google Meet";
      processedConferenceData.icon = conferenceSolution.iconUri;

      return processedConferenceData;
    }

    return null;
  }

  function loadCalendarEvents(activeCalendars) {
    var oneWeekBefore = moment(date, "YYYY-MM-DD").subtract(1, "week");
    var oneWeekAfter = moment(date, "YYYY-MM-DD").add(1, "week");

    // Cnovert activeCalendars to a json string
    const activeCalendarsJson = JSON.stringify(activeCalendars);
    setLastSync(null);
    axios
      .get(`${googleServerUrl}/getCalendarEvents`, {
        params: {
          userId: userId,
          // 1 week before date
          timeMin: oneWeekBefore.startOf("day").toISOString(),
          timeMax: oneWeekAfter.endOf("day").toISOString(),
          active_calendars: activeCalendarsJson,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data && response.data.events?.length > 0) {
          const newEvents = convertGoogleEventsToEvents(response.data.events);

          // Take the array and group by id
          const newGoogleEvents = newEvents.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});
          setGoogleEvents(newGoogleEvents);
          setLastSync(new Date());
        } else {
          setLastSync(new Date());
        }
      })
      .catch((error) => {
        console.log(error);
        setLastSync(new Date());
      });
  }

  // Function to convert hex code to rgb
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result;
  }

  function convertTasksToEvents(tasks, labels) {
    return tasks
      .map((task) => {
        // If there is no estimated time, make it 1 hour (in seconds)
        const estimated_time = task.estimated_time || 3600;

        var start_date = task.start;

        if (!start_date) {
          return null;
        }

        // Check if start_date is instanceof firebase timestamp, if so, convert to date
        if (start_date.toDate) {
          start_date = start_date.toDate();
        }

        // The end time is the start time plus the estimated time (using moment), then convert to date
        const end_date = moment(start_date)
          .add(estimated_time, "seconds")
          .toDate();

        return {
          title: task.description,
          start: start_date,
          end: end_date,
          allDay: false, // will make the time show
          editable: true,
          backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
          textColor: colourIsLight(labels?.[task.label]?.color || "#FFE3C9")
            ? "black"
            : "white",
          borderColor: labels?.[task.label]?.color || "#FFE3C9",
          id: task.id,
        };
      })
      .filter((event) => event !== null);
  }

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "#3c4043" : "white";
  }

  var colourIsLight = function (hex) {
    // Convert hex to RGB first (removing #)
    var rgb = hexToRgb(hex);
    var r = parseInt(rgb[1], 16);
    var g = parseInt(rgb[2], 16);
    var b = parseInt(rgb[3], 16);

    // Counting the perceptive luminance
    // human eye favors green color...
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  };

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(date);
    }
  }, [date]);

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    if (calendarApi && !active) {
      calendarApi.unselect();
    }
  }, [active]);

  useEffect(() => {
    if (document) {
      let draggableEl = document.getElementById("app-container");
      let draggable = new ThirdPartyDraggable(draggableEl, {
        itemSelector: ".card-container",
        // mirrorSelector: ".card-drag-preview",
        eventData: function (eventEl) {
          let id = eventEl.getAttribute("taskId");
          const task = tasks[id];

          if (task) {
            // convert from seconds to 00:00 format
            const duration = moment.duration(
              task.estimated_time || 0,
              "seconds"
            );
            // Convert duration (in seconds) to 00:00:00 format
            // preserving the 00:00:00 format
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            var m = moment().utcOffset(0);
            m.set({
              hour: hours,
              minute: minutes,
              second: seconds,
            });

            var durationString = m.format("HH:mm:ss");

            if (durationString === "00:00:00") {
              // Set it to 30 mins
              durationString = "00:30:00";
            }

            return {
              id: id,
              title: `${task.description}`,
              duration: durationString,
            };
          }
        },
      });

      // a cleanup function
      return () => draggable.destroy();
    }
  }, [tasks]);

  useEffect(() => {
    // Let's use axios to get the google calendar events from the api
    // Calling http://localhost:5001/ellie-18430/us-central1/getCalendarEvents?userId=${userId}&date=${date}
    if (userId) {
      loadCalendarEvents(active_calendars);
    }
  }, [userId, date, active_calendars_ids_count]);

  useEffect(() => {
    // Get all tasks from taskOrder
    const tasksFromOrder = Object.values(tasks).filter((task) => {
      // Only if task.date is in the same day as date
      var taskDate = task.date;

      if (!taskDate) {
        return false;
      }

      if (taskDate.toDate) {
        taskDate = taskDate.toDate();
      }

      // Check if task date is within 1 week of the date (plus or minus 1 week)
      const oneWeekBefore = moment(date, "YYYY-MM-DD").subtract(1, "week");
      const oneWeekAfter = moment(date, "YYYY-MM-DD").add(1, "week");

      return moment(taskDate).isBetween(
        oneWeekBefore,
        oneWeekAfter,
        "day",
        "[]"
      );
    });

    // Convert tasks to events
    const newEvents = convertTasksToEvents(tasksFromOrder, labels);

    // Check if events is different from newEvents, using lodash
    if (!_.isEqual(events, newEvents)) {
      // Get the difference between the two

      setEvents(newEvents);
    }
  }, [tasks, labels, date]);

  function removeEventForTask(event) {
    const taskId = event.id;
    // Update the task
    const task = tasks[taskId];

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          start: null,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );
  }

  function completeTaskForEvent(event) {
    const taskId = event.id;
    // Update the task
    const task = tasks[taskId];

    var newCompleteValue = task.complete ? false : true;
    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          complete: newCompleteValue,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );

    if (!move_task_on_complete_disabled) {
      // If we are marking as complete, move it to the bottom of the order

      // Get date in format YYYY-MM-DD
      const dateString = moment(task.date).format("YYYY-MM-DD");

      if (newCompleteValue) {
        dispatch(
          moveTaskToBottom({
            taskId: task.id,
            date: dateString,
          })
        );
      } else {
        // We are marking as incomplete, move it to the bottom of the incomplete tasks
        dispatch(
          moveTaskToBottomOfIncomplete({
            taskId: task.id,
            date: dateString,
          })
        );
      }
    }
  }

  function updateTaskFromEvent(event) {
    // Let's get the event's start and end time
    const start = event.start;
    const end = event.end;
    const taskId = event.id;

    // Get the duration in seconds from the start and end time
    let duration = moment(end).diff(moment(start), "seconds");

    // if duration is not a number, set it to 1 hour in seconds
    if (isNaN(duration)) {
      duration = 3600;
    }

    // Update the task
    const task = tasks[taskId];
    const googleEvent = googleEvents[taskId];

    var eventDate = moment(start).format("YYYY-MM-DD");

    if (!googleEvent) {
      dispatch(
        updateTask({
          taskId: taskId,
          newData: {
            date: start,
            start: start,
            estimated_time: duration,
          },
          currentTask: task,
          saveGhostOrder: false,
          updateOrder: true,
        })
      );
    }
  }

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    delay(500).then(() => {
      let calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.updateSize();
      }
    });
  }, [hide_calendar_sidebar, hide_sidebar]);

  // useEffect to go through each eventChange when the timer is done
  useEffect(() => {
    if (eventChangeTimer) {
      clearTimeout(eventChangeTimer);
    }

    if (Object.values(eventChanges).length > 0) {
      const timer = setTimeout(() => {
        // Go through each eventChange
        Object.values(eventChanges).forEach((eventChange) => {
          updateTaskFromEvent(eventChange);
        });

        // Clear the eventChanges
        setEventChanges([]);
      }, 2000);
      setEventChangeTimer(timer);
    }
  }, [eventChanges]);

  return (
    <div className="kanban-board">
      <TopbarActions
        loadCalendarEvents={loadCalendarEvents}
        lastSync={lastSync}
        setLastSync={setLastSync}
      />

      <div className="weekview-container">
        <StyleCalendarWrapper>
          <StyleCalendarWeekWrapper>
            <FullCalendar
              allDayText="All Day"
              longPressDelay={300}
              eventColor="#FFE3C9"
              eventTextColor="#3c4043"
              selectable={true}
              selectMirror={true}
              ref={calendarRef}
              headerToolbar={false}
              // header={false}
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              height="100%"
              nowIndicator={true}
              dayHeaders={true}
              snapDuration={"00:05:00"}
              dayHeaderContent={({ date, isToday }) => {
                return (
                  <div className="calendar-header-custom">
                    <div>
                      {moment(date).format("ddd")}{" "}
                      <span className={isToday ? "calendar-header-today" : ""}>
                        {moment(date).format("D")}{" "}
                      </span>
                    </div>
                  </div>
                );
              }}
              unselectCancel={".create-task-modal"}
              editable={true}
              droppable={true}
              allDaySlot={true}
              events={[...Object.values(googleEvents), ...events]}
              initialDate={moment().format("YYYY-MM-DD")}
              //   columnHeader={false}
              scrollTimeReset={false}
              scrollTime={scrollTime}
              eventContent={(eventObject) => {
                const event = eventObject.event;

                const task = tasks[event.id];

                const durationLessThan15Minutes =
                  moment(event.end).diff(moment(event.start), "minutes") <= 15;

                const googleEvent = googleEvents[event.id];

                if (googleEvent) {
                  return (
                    <Popover
                      placement="left"
                      title={null}
                      visible={event.id === eventContextMenuActive}
                      content={
                        <div className="card-context-menu">
                          {googleEvent.conferenceData && (
                            <div
                              onClick={() => {
                                window.open(googleEvent.conferenceData.link);
                              }}
                              className="context-menu-row"
                            >
                              <img
                                src={googleEvent.conferenceData.icon}
                                className="context-mmenu-row-icon logo"
                                alt="Zoom Logo"
                              />
                              {googleEvent.conferenceData.summary}
                            </div>
                          )}

                          {!googleEvent.conferenceData &&
                            googleEvent.zoomLink && (
                              <div
                                onClick={() => {
                                  window.open(googleEvent.zoomLink);
                                }}
                                className="context-menu-row"
                              >
                                <img
                                  src={require("../../images/zoom-icon.png")}
                                  className="context-mmenu-row-icon logo"
                                  alt="Zoom Logo"
                                />
                                Join zoom meeting
                              </div>
                            )}

                          <div
                            onClick={() => {
                              // Open up a link to the google event in a new tab
                              window.open(googleEvent.googleEventLink);
                            }}
                            className="context-menu-row"
                          >
                            <img
                              src={require("../../images/google-calendar-icon.png")}
                              className="context-mmenu-row-icon logo"
                              alt="Google Calendar Logo"
                            />
                            Open in Google Calendar
                          </div>
                        </div>
                      }
                      onVisibleChange={(visible) => {
                        if (!visible) {
                          setEventContextMenuActive(null);
                        }
                      }}
                      trigger={"click"}
                      id="card-context-menu"
                    >
                      <div
                        className={`event-content ${
                          durationLessThan15Minutes ? "small-text" : ""
                        }`}
                      >
                        <div className="event-meta">
                          <div className={`event-title`}>
                            {googleEvent.title}
                          </div>
                          <div className="event-time">
                            {eventObject.timeText}
                          </div>
                        </div>
                        <FcGoogle
                          className={`event-complete-check ${
                            durationLessThan15Minutes ? "small" : ""
                          }`}
                          style={{
                            color: eventObject.textColor,
                          }}
                        />
                      </div>
                    </Popover>
                  );
                }

                if (task) {
                  return (
                    <Popover
                      placement="left"
                      title={null}
                      content={
                        <div className="card-context-menu">
                          <div
                            onClick={() => {
                              removeEventForTask(event);
                            }}
                            className="context-menu-row"
                          >
                            <TrashIcon className="context-mmenu-row-icon" />
                            Remove Event
                          </div>
                          <div
                            onClick={() => {
                              completeTaskForEvent(event);
                            }}
                            className="context-menu-row"
                          >
                            <CheckCircleIcon className="context-mmenu-row-icon" />
                            {task.complete && <span>Mark as incomplete</span>}
                            {!task.complete && <span>Mark as complete</span>}
                          </div>
                        </div>
                      }
                      visible={event.id === eventContextMenuActive}
                      onVisibleChange={(visible) => {
                        if (!visible) {
                          setEventContextMenuActive(null);
                        }
                      }}
                      trigger={["contextMenu"]}
                      id="card-context-menu"
                    >
                      <div
                        className={`event-content ${
                          durationLessThan15Minutes ? "small-text" : ""
                        }`}
                      >
                        <div className="event-meta">
                          <div
                            className={`event-title ${
                              task?.complete ? "completed" : ""
                            }`}
                          >
                            {event.title}
                          </div>
                          <div className="event-time">
                            {eventObject.timeText}
                          </div>
                        </div>
                        {task.complete && (
                          <BsFillCheckCircleFill
                            className={`event-complete-check ${
                              durationLessThan15Minutes ? "small" : ""
                            }`}
                            style={{
                              color: eventObject.textColor,
                            }}
                          />
                        )}
                      </div>
                    </Popover>
                  );
                }
              }}
              eventDidMount={(arg) => {
                const eventId = arg.event.id;

                const googleEvent = googleEvents[eventId];
                arg.el.style.borderRadius = "5px";
                if (googleEvent) {
                  arg.el.style.border = "1px dashed #d1d1d1";
                  arg.el.style.backgroundColor = "#fefefe";
                  arg.el.style.borderLeft = `3px solid ${googleEvent.backgroundColor}`;
                } else {
                  arg.el.addEventListener("contextmenu", (jsEvent) => {
                    jsEvent.preventDefault();
                    setEventContextMenuActive(eventId);
                  });
                }
              }}
              eventClick={(arg) => {
                const event = arg.event;
                const task = tasks[event.id];

                const googleEvent = googleEvents[event.id];

                if (googleEvent) {
                  setEventContextMenuActive(event.id);
                }

                if (task) {
                  dispatch(setColumnSelected(null));
                  dispatch(setCardModalActive(task.id));
                }
              }}
              select={(arg) => {
                const { start, end } = arg;

                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  dispatch(
                    setCreateTaskModalActive({
                      active: true,
                      date: moment(start).format("YYYY-MM-DD"),
                      start_date: moment(start).toDate(),
                      duration: moment(end).diff(moment(start), "seconds"),
                    })
                  );
                }
              }}
              slotDuration="00:15"
              slotLabelInterval="01:00"
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: true,
                meridiem: "short",
              }}
              eventChange={(changeInfo) => {
                const event = changeInfo.event;

                // Update events state
                setEvents(
                  events.map((e) => {
                    if (e.id === event.id) {
                      return {
                        ...e,
                        start: event.start,
                        end: event.end,
                      };
                    }
                    return e;
                  })
                );

                // Log event change
                setEventChanges((eventChanges) => {
                  return {
                    ...eventChanges,
                    [event.id]: event,
                  };
                });

                //  updateTaskFromEvent(event);
              }}
              eventReceive={(info) => {
                const event = info.event;

                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                  info.revert();
                } else {
                  window.mixpanel.track("Task timeboxed", {
                    method: "dragged",
                  });

                  updateTaskFromEvent(event);
                  info.revert();
                }
              }}
            />
          </StyleCalendarWeekWrapper>
        </StyleCalendarWrapper>
      </div>
    </div>
  );
}

export const StyleCalendarWeekWrapper = styled.div`
  .fc-theme-standard td {
    border: none;
    border-bottom: none;
    border-right: 0.5px solid var(--fc-border-color, #ddd);
    border-top: 0.5px solid var(--fc-border-color, #ddd);
  }

  height: 100%;
  width: 100%;
  background-color: white;
`;

export const StyleCalendarWrapper = styled.div`
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    --ribbon-width: 4px;
    --ribbon-spacing: 1px;
    --z-index-background: 1;
    --z-index-ribbon: 2;
    --z-index-highlight-overlay: 3;
    --z-index-content: 3;
    --z-index-resize-handle: 5;
    --resize-handle-height: 4px;
    --resize-handle-width: 4px;
    --highlight-overlay-start-opacity: 0.35;
    --highlight-overlay-end-opacity: 0.25;
    --highlight-overlay-start-opacity-dark: 0.04;
    --highlight-overlay-end-opacity-dark: 0.03;
    position: absolute;
    border-color: var(--border-color);
    border-radius: var(--radius-m);
    border-width: 1px;
    padding: var(--chip-padding);
    opacity: 1;
    --background-color: rgb(255, 227, 200);
    --foreground-color-primary: rgb(163, 48, 0);
    --foreground-color-secondary: rgb(174, 69, 23);
    --ribbon-color-primary: rgb(254, 112, 50);
    --ribbon-color-secondary: rgb(254, 112, 50);
    --border-color: transparent;
    box-shadow: 0px 0px 0px 1px var(--material-default);
    text-decoration-color: rgb(174, 69, 23);
  }

  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  .fc-day-today {
    background: transparent !important;
  }

  .fc .fc-timegrid-slot-minor {
    border-top-style: hidden;
  }

  .fc .fc-timegrid-slot-label {
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.04em;
    text-align: right;
    font-feature-settings: "tnum", "lnum", "ss01", "ss02", "ss03", "ss04";
    color: #717171;
    text-transform: uppercase;
    border-right: 0.5px solid var(--fc-border-color, #ddd);
  }

  .fc-theme-standard td {
    border: none;
    border-bottom: none;
    border-right: none;
    border-top: 0.5px solid var(--fc-border-color, #ddd);
  }

  .fc-theme-standard th {
    border: none;
  }

  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0px;
  }

  .fc .fc-timegrid-axis-cushion {
    max-width: 60px;
    flex-shrink: 0;
    font-size: 11px;
  }

  .fc-timegrid-divider fc-cell-shaded {
    border-bottom: none;
    border-top: none;
  }

  .fc .fc-cell-shaded,
  .fc .fc-day-disabled {
    border: none;
    padding: 0px !important;
    height: 0px;
    background: transparent;
  }

  height: 100%;
  width: 100%;
  background-color: white;
`;
