import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadInDates } from "../../redux/appSlice";
import _ from "lodash";
import "./Analytics.css";

import { getDaysDiff } from "../../utils";

import moment from "moment";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function Analytics({ reloadDataForDates }) {
  const { data: taskData } = useSelector((state) => state.tasks);
  const { data: labels, loading } = useSelector((state) => state.labels);

  const dispatch = useDispatch();

  const rangeOptions = [
    {
      id: "7days",
      name: "Last 7 days",
      value: getDaysDiff(
        moment().add(-7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
    },
    {
      id: "14days",
      name: "Last 14 days",
      value: getDaysDiff(
        moment().add(-14, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
    },
    {
      id: "30days",
      name: "Last month",
      value: getDaysDiff(
        moment().add(-30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
    },
    {
      id: "3months",
      name: "Last 3 months",
      value: getDaysDiff(
        moment().add(-3, "months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
    },
  ];

  const [dateRange, setDateRange] = useState(rangeOptions[0]);

  const { finalData, labelsUsed } = transformData(taskData, dateRange.value);

  useEffect(() => {
    if (dateRange.value) {
      dispatch(
        loadInDates({
          dates: dateRange.value,
        })
      );
    }
  }, [dateRange, dispatch]);

  useEffect(() => {
    reloadDataForDates(dateRange.value);
  }, [dateRange, reloadDataForDates]);

  return (
    <div className="analytics">
      <div className="analytics-header">Analytics</div>

      <div className="range-selector">
        {rangeOptions.map((option) => (
          <div
            key={option.id}
            className={`range-option ${
              option.id === dateRange.id ? "selected" : ""
            }`}
            onClick={() => setDateRange(option)}
          >
            {option.name}
          </div>
        ))}
      </div>

      <div className="analytics-body">
        <div className="chart">
          <div className="chart-title">How you spend your time each day</div>
          <BarChart
            width={800}
            height={300}
            data={finalData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />

            {Object.values(labels)
              .filter((label) => {
                return labelsUsed.includes(label.id);
              })
              .map(function (label) {
                return (
                  <Bar
                    dataKey={label.id}
                    stackId="a"
                    fill={label.color}
                    name={label.name}
                  />
                );
              })}
            <Legend style={{ marginTop: "20px" }} />
          </BarChart>
        </div>
      </div>
    </div>
  );
}

function transformData(taskData, dates) {
  // In the end we want to return an array of objects looking like
  // { name: "2022-2-3", tasksCount: 10, label1: 200, label2: 500, label3: 250 }

  // Iterate through the task data and group by date, excluding null
  const groupedByDate = _.groupBy(taskData, (task) => {
    // Get date in format YYYY-MM-DD
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dateString;
  });

  // Delete the "null" key from the groupedByDate object
  delete groupedByDate["null"];

  var labelsUsed = [];

  // Iterate through the groupedByDate and create an array of objects
  const transformedData = Object.keys(groupedByDate).map((date) => {
    const tasksCount = groupedByDate[date].length;
    const labels = {};

    // Iterate through the labels and add the estimated_time for each label
    // Convert estimated_time from seconds to hours
    Object.values(groupedByDate[date]).forEach((task) => {
      const label = task.label;

      labels[label] = (labels[label] || 0) + (task.actual_time || 0) / 60 / 60;

      // Cap labels to 2 decimal places
      labels[label] = Math.round(labels[label] * 100) / 100;

      labelsUsed.push(label);
    });

    return {
      name: date,
      tasksCount,
      ...labels,
    };
  });

  var finalData = [];

  // Iterate through the dates and check if the date is in the transformedData
  // If not, add it with tasksCount = 0
  dates.map(function (date) {
    if (!transformedData.find((data) => data.name === date)) {
      finalData.push({
        name: date,
        tasksCount: 0,
      });
    } else {
      finalData.push(transformedData.find((data) => data.name === date));
    }
  });

  return { finalData, labelsUsed };
}
