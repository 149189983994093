import {
  updateRecurringTask,
  bulkDeleteTasks,
  bulkUpdateTasks,
  convertGhostTaskToTask,
} from "./tasksSlice";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { db } from "../firebase";
import {
  doc,
  setDoc,
  query,
  where,
  collection,
  getDocs,
  writeBatch,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { generateTemplateFromTask, updateTaskToMatchTemplate } from "../utils";

export const stopRecurringTask = createAsyncThunk(
  "tasks/stopRecurringTask",
  async (
    { recurringTask, taskToStop },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Let's materialize this task if it is a ghost task
    if (!recurringTask.branched_tasks.includes(taskToStop.id)) {
      var taskTopStopClone = _.cloneDeep(taskToStop);
      taskTopStopClone.recurring = false;
      taskTopStopClone.recurring_id = null;

      dispatch(
        convertGhostTaskToTask({ ghostTask: taskTopStopClone, saveOrder: true })
      );
    }

    // Let's delete all future branched tasks
    // First, let's get the the branched task ids from the recurring task
    const branchedTaskIds = recurringTask.branched_tasks;

    // Go through each branch task and see if it occurs after the task to stop
    const branchedTasksToDelete = branchedTaskIds
      .map((branchedTaskId) => {
        const branchedTask = getState().tasks.data[branchedTaskId];

        if (branchedTask) {
          // If the branched task occurs after the task to stop, we can delete it
          // date is in format "YYYY-MM-DD"
          const lastTaskDate = moment(taskToStop.date);
          const branchedTaskDate = moment(branchedTask.date);

          if (branchedTaskDate.isAfter(lastTaskDate)) {
            return branchedTask;
          }
        }
      })
      .filter((branchedTask) => branchedTask);

    // Now we can delete the branched tasks
    dispatch(
      bulkDeleteTasks({
        tasksToDelete: branchedTasksToDelete,
        previousData: branchedTasksToDelete,
      })
    );

    // Then delete the recurring task
    dispatch(
      deleteRecurringTask({
        recurringTask: recurringTask,
      })
    );
  }
);

export const createRecurringTask = createAsyncThunk(
  "tasks/createRecurringTask",
  async (
    { recurringTask },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    window.mixpanel.track("Recurring task created", {
      recurring_task_id: recurringTask.id,
    });

    try {
      const userId = getState().app.uid;
      await setDoc(
        doc(db, "users", userId, "recurring_tasks", recurringTask.id),
        {
          ...recurringTask,
        },
        {
          merge: true,
        }
      );

      return fulfillWithValue({
        recurringTask,
        dates: getState().app.dates,
      });
    } catch (error) {
      console.log("error", error);
      rejectWithValue(error);
    }
  }
);

export const deleteRecurringTask = createAsyncThunk(
  "tasks/deleteRecurringTask",
  async ({ recurringTask }, { dispatch, getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    // We need to go through every branched task and make it non recurring

    // First, let's get the the branched task ids from the recurring task
    const branchedTaskIds = recurringTask.branched_tasks;

    // Go through each branch task and make it non recurring
    const tasksToUpdate = branchedTaskIds
      .map((branchedTaskId) => {
        const branchedTask = getState().tasks.data[branchedTaskId];

        return branchedTask;
      })
      .filter((branchedTask) => branchedTask);

    const tasksToUpdateCopy = _.cloneDeep(tasksToUpdate);

    tasksToUpdateCopy.forEach((task) => {
      // Make it non recurring
      task.recurring = false;
      task.recurring_id = null;
    });

    // Now we can update the branched tasks
    dispatch(
      bulkUpdateTasks({
        newData: tasksToUpdateCopy,
        previousData: tasksToUpdate,
      })
    );

    // Then delete the recurring task
    try {
      await deleteDoc(
        doc(db, "users", userId, "recurring_tasks", recurringTask.id)
      );

      return recurringTask;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAllRecurringInstances = createAsyncThunk(
  "tasks/deleteAllRecurringInstances",
  async ({ recurringTask }, { dispatch, getState, rejectWithValue }) => {
    // Let's delete all branched tasks
    const branchedTaskIds = recurringTask.branched_tasks;

    // Go through each branch task and delete it
    const tasksToDelete = branchedTaskIds.map((branchedTaskId) => {
      const branchedTask = getState().tasks.data[branchedTaskId];
      return branchedTask;
    });

    // Now we can delete the branched tasks
    dispatch(
      bulkDeleteTasks({
        tasksToDelete,
        previousData: branchedTaskIds,
      })
    );

    // Then delete the recurring task
    dispatch(
      deleteRecurringTask({
        recurringTask,
      })
    );
  }
);

export const updateIncompleteInstances = createAsyncThunk(
  "tasks/updateIncompleteInstances",
  async (
    { recurringTask, taskToMatch },
    { dispatch, getState, rejectWithValue }
  ) => {
    // Let's all incomplete instances of the recurring task

    // Get all branched tasks
    const branchedTaskIds = recurringTask.branched_tasks;

    // Go through each branched task and see if it is incomplete
    const tasksToUpdate = branchedTaskIds
      .map((branchedTaskId) => {
        const branchedTask = getState().tasks.data[branchedTaskId];

        // If the branched task is incomplete, we can update it
        if (!branchedTask.complete && branchedTask.id !== taskToMatch.id) {
          return branchedTask;
        }
      })
      .filter((branchedTask) => branchedTask);

    // Now we can update the branched tasks with the new template
    var tasksToUpdateCopy = _.cloneDeep(tasksToUpdate);

    tasksToUpdateCopy.forEach((task) => {
      // Update the task to match the template
      const template = generateTemplateFromTask(taskToMatch);
      task = updateTaskToMatchTemplate(task, template);
    });

    if (tasksToUpdateCopy.length > 0) {
      // Now we can update the branched tasks
      dispatch(
        bulkUpdateTasks({
          newData: tasksToUpdateCopy,
          previousData: tasksToUpdate,
        })
      );
    }

    // Then update the recurring task template
    const template = generateTemplateFromTask(taskToMatch);

    const dates = getState().app.dates;

    dispatch(
      updateRecurringTask({
        recurringTaskId: recurringTask.id,
        currentRecurringTask: recurringTask,
        newData: {
          task_template: template,
        },
        dates: dates,
      })
    );
  }
);
