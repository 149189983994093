import "./AddTaskCard.css";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

import moment from "moment";
import { useEffect, useState } from "react";

export default function AddTaskCard({ setCreateActive, date }) {
  const { data: taskData } = useSelector((state) => state.tasks);
  const taskOrder = useSelector((state) =>
    date ? state.tasks.order[date]?.order : state.tasks.order["brain_dump"]?.order
  );

  const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);
  const [totalActualTime, setTotalActualTime] = useState(0);

  const { label_filters: activeLabels = [], hide_complete = false } =
    useSelector((state) => state.app?.currentUser);

  useEffect(() => {
    // Whenever task order updates, let's re-calculate the total time
    // spent on tasks for this column.
    var totalEstimatedTimeTemp = 0;
    var totalActualTimeTemp = 0;

    if (taskOrder) {
      // If there is a date, get all tasks for a given date and sum their time
      const tasks = taskOrder
        .map((taskId) => {
          let task = taskData[taskId];

          if (
            task &&
            (!hide_complete || !task.complete) &&
            (activeLabels?.length === 0 || activeLabels?.includes(task.label))
          ) {
            return task;
          }
        })
        .filter((task) => task);

      totalEstimatedTimeTemp = tasks.reduce(
        (acc, task) =>
          acc + (!isNaN(task.estimated_time) ? task.estimated_time : 0),
        0
      );

      totalActualTimeTemp = tasks.reduce(
        (acc, task) => acc + (!isNaN(task.actual_time) ? task.actual_time : 0),
        0
      );
    }

    setTotalEstimatedTime(totalEstimatedTimeTemp);
    setTotalActualTime(totalActualTimeTemp);
  }, [taskOrder, taskData, activeLabels, hide_complete]);

  return (
    <div>
      <div
        onClick={() => {
          if (date) {
            setCreateActive(date);
          } else {
            setCreateActive(true);
          }
        }}
        className="add-card-container"
      >
        <div className="add-card-left">
          <PlusCircleIcon className="add-card-icon" />
          <div className="add-card-text">Add a task</div>
        </div>

        {!isNaN(totalEstimatedTime) && totalEstimatedTime > 0 && (
          <div className="card-time-estimate">
            {`
            ${
              !isNaN(totalActualTime) && totalActualTime > 0
                ? `${moment.utc(totalActualTime * 1000).format("H:mm")} / `
                : ""
            }
            ${moment.utc(totalEstimatedTime * 1000).format("H:mm")}
            `}
          </div>
        )}
      </div>
    </div>
  );
}
