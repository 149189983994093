import "../DayView/DayView.css";

import { useState } from "react";

import { useSelector } from "react-redux";

import Card from "../Card";

import CreateCard from "../../Kanban/Card/CreateCard";
import AddTaskCard from "../../Kanban/Column/AddTaskCard";

export default function Braindump({
  brainDumpOrder,
  isDragging,
  onDragEnd,
  saveOrder,
}) {
  const { data: tasks } = useSelector((state) => state.tasks);

  const [createActive, setCreateActive] = useState(null);

  return (
    <div className="day-view">
      <div className="day-view-header">
        <div className="braindump-header-title">🧠 Brain Dump</div>
      </div>

      {createActive && <CreateCard setCreateActive={setCreateActive} />}

      <div className="draggable-container">
        {brainDumpOrder
          .filter((taskId) => {
            if (tasks[taskId]) {
              return true;
            }
            return false;
          })
          .map((taskId, index) => {
            return (
              <Card
                index={index}
                item={tasks[taskId]}
                key={taskId + "_card"}
                isDragging={isDragging}
                onDragEnd={onDragEnd}
                saveOrder={saveOrder}
                columnId="braindump"
              />
            );
          })}
      </div>
    </div>
  );
}
