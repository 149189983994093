import "./App.css";

import { useEffect, useCallback, useState } from "react";

import Toolbar from "./Components/Toolbar";
import Settings from "./Components/Settings";
import { useDispatch, useSelector } from "react-redux";
import {
  loadInitialDates,
  setCurrentUser,
  setUid,
  changeStartDate,
  loadDatesFromStartDate,
  refreshKabanCursor,
} from "./redux/appSlice";
import { addLabels, removeLabel } from "./redux/labelsSlice";
import {
  addTasks,
  removeTask,
  processTaskOrders,
  addBraindumpOrder,
  processRecurringTasks,
  manuallySetOrderLoading,
  manuallySetTasksLoading,
  loadRecurringTaskGhosts,
  removeDatesFromLoaded,
  removeRecurringTask,
  changeCalendarDate,
} from "./redux/tasksSlice";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Analytics from "./Components/Analytics";
import UpgradeModal from "./Components/Upgrade";
import TrialModal from "./Components/Upgrade/TrialModal";
import SubscriptionSuccess from "./Components/Upgrade/Success";
import CalendarSuccess from "./Components/Calendar/CalendarSuccess";
import { useInterval } from "usehooks-ts";

import { getAuth } from "firebase/auth";

import _ from "lodash";

import debounce from "lodash.debounce";

import moment from "moment-timezone";

import DnDContainer from "./Components/DnDContainer";

import { Default, Mobile } from "./mediaUtils";

import TabBar from "./Components/Mobile/TabBar";

import { db } from "./firebase";

import { query, where, collection, onSnapshot, doc } from "firebase/firestore";

import CardModal from "./Components/CardModal";
import CreateTaskModal from "./Components/CardModal/CreateTaskModal";
import SettingsContent from "./Components/Settings/SettingsContent";

import FocusMode from "./Components/Mobile/Task/FocusMode";
import KeyboardShortcuts from "./Components/Generics/KeyboardShortcuts";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MouseBackEnd from "./Components/DnDContainer/MouseBackend";
import CannyAuth from "./Components/Auth/CannyAuth";
import Onboarding from "./Components/Auth/Onboarding";

function App({ userId }) {
  const dates = useSelector((state) => state.app.dates);
  const mobilePageActive = useSelector((state) => state.app.mobilePageActive);

  const {
    name,
    last_rollover_date,
    rollover_position = "bottom",
    rollover_disabled = false,
  } = useSelector((state) => state.app.currentUser);

  const currentUserLoaded = useSelector((state) => state.app.currentUserLoaded);

  const dispatch = useDispatch();

  const [activeTaskQueries, setActiveTaskQueries] = useState([]);

  const [lastRefreshed, setLastRefreshed] = useState(moment());

  const dateRangesLoaded = useSelector((state) => state.tasks.dateRangesLoaded);

  useEffect(() => {
    // Use the Canny SDK to identify the current user of your website
    const auth = getAuth();
    const user = auth.currentUser;

    if (name) {
      window.Canny("identify", {
        appID: "637d2ce47a741d0ff12b8c61",
        user: {
          // Replace these values with the current user's data
          email: user.email,
          name: name,
          id: userId,
        },
      });
    }
  }, [userId, name]);

  function loadTasksForDate(dates) {
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      where(
        "date",
        ">=",
        moment(dates[0], "YYYY-MM-DD").startOf("day").toDate()
      ),
      where(
        "date",
        "<=",
        moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate()
      )
    );

    const unsubTasks = onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
              date: doc.data().date ? doc.data().date.toDate() : null,
            });
          }
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks, dates: dates }));
      }
    });

    return unsubTasks;
  }

  function loadTaskOrderForDates(dates) {
    const taskOrderQuery = query(
      collection(db, "users", userId, "task_order"),
      where(
        "date",
        ">=",
        moment(dates[0], "YYYY-MM-DD").startOf("day").toDate()
      ),
      where(
        "date",
        "<=",
        moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate()
      )
    );

    const unsubTaskOrder = onSnapshot(taskOrderQuery, (querySnapshot) => {
      const taskOrdersAdded = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            // Let's remove duplicates from the order array
            if (data.order) {
              data.order = data.order.filter((item, index) => {
                return data.order.indexOf(item) === index;
              });
            } else {
              data.order = [];
            }
            taskOrdersAdded.push({ ...data, id: doc.id });
          }
          if (change.type === "modified") {
            // Let's remove duplicates from the order array
            data.order = data.order.filter((item, index) => {
              return data.order.indexOf(item) === index;
            });

            taskOrdersAdded.push({ ...data, id: doc.id });
          }
        }
      });

      dispatch(removeDatesFromLoaded({ dates }));
      if (taskOrdersAdded && taskOrdersAdded.length > 0) {
        dispatch(
          processTaskOrders({
            taskOrder: taskOrdersAdded,
            dates: dates,
            override: true,
          })
        );
      } else {
        // Nothing to process, let's just do ghost tasks
        dispatch(
          processTaskOrders({
            taskOrder: [],
            dates: dates,
            override: true,
          })
        );
      }
    });

    return unsubTaskOrder;
  }

  function loadBraindump() {
    const brainDumpOrderRef = doc(
      db,
      "users",
      userId,
      "task_order",
      "brain_dump"
    );

    const unsubBraindumpOrder = onSnapshot(brainDumpOrderRef, (doc) => {
      var data = doc.data();

      if (doc.exists && !doc.metadata.hasPendingWrites) {
        if (data) {
          data.order = data.order.filter((item, index) => {
            return data.order.indexOf(item) === index;
          });

          dispatch(addBraindumpOrder(data));
        } else {
          dispatch(
            addBraindumpOrder({
              id: "brain_dump",
              order: [],
            })
          );
        }
      }
    });

    const braindumpQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", "==", null)
    );

    const unsubBraindump = onSnapshot(braindumpQuery, (querySnapshot) => {
      const tasks = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        if (!doc.metadata.hasPendingWrites) {
          if (change.type === "added") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
            });
          }
          if (change.type === "modified") {
            tasks.push({
              ...doc.data(),
              id: doc.id,
            });
          }
          /*
          if (change.type === "removed") {
            dispatch(removeTask({ taskId: change.doc.id }));
          } */
        }
      });

      if (tasks && tasks.length > 0) {
        dispatch(addTasks({ tasks: tasks }));
      } else {
        dispatch(manuallySetTasksLoading({ loading: false }));
      }
    });

    return { unsubBraindumpOrder, unsubBraindump };
  }

  function loadLabels() {
    const labelsQuery = query(collection(db, "users", userId, "labels"));

    const unsubLabels = onSnapshot(labelsQuery, (querySnapshot) => {
      const labels = [];

      querySnapshot.docChanges().forEach((change) => {
        var doc = change.doc;
        var data = doc.data();

        if (change.type === "added" || change.type === "modified") {
          labels.push({
            ...doc.data(),
            id: doc.id,
          });
        }

        if (change.type === "removed") {
          dispatch(removeLabel({ labelId: change.doc.id }));
        }
      });

      if (labels) {
        dispatch(addLabels({ labels: labels }));
      }
    });

    return unsubLabels;
  }

  function loadCurrentUser() {
    const docRef = doc(db, "users", userId);

    const unsub = onSnapshot(docRef, (doc) => {
      var data = doc.data();

      if (data) {
        var dataCopy = _.cloneDeep(data);

        // If there is an active timer, let's convert the date to a string
        if (dataCopy.active_timer) {
          dataCopy.active_timer.last_start_time = moment(
            dataCopy.active_timer.last_start_time.toDate()
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        // If there is a subscription, let's convert the date to a string
        if (dataCopy.pro_meta?.pro_expiration_date) {
          dataCopy.pro_meta.pro_expiration_date = moment(
            dataCopy.pro_meta.pro_expiration_date.toDate()
          ).format("YYYY-MM-DD HH:mm:ss");
        }

        dispatch(setCurrentUser(dataCopy));
      } else {
        dispatch(setCurrentUser({}));
      }
    });

    return unsub;
  }

  function loadRecurringTasks(dates) {
    const recurringTasksQuery = query(
      collection(db, "users", userId, "recurring_tasks")
    );

    const unsubRecurringTasks = onSnapshot(
      recurringTasksQuery,
      (querySnapshot) => {
        const recurringTasksAdded = [];
        const recurringTasksModified = [];

        querySnapshot.docChanges().forEach((change) => {
          var doc = change.doc;
          if (!doc.metadata.hasPendingWrites) {
            if (change.type === "added") {
              recurringTasksAdded.push({
                ...doc.data(),
                id: doc.id,
              });
            }
            if (change.type === "modified") {
              recurringTasksModified.push({
                ...doc.data(),
                id: doc.id,
              });
            }
            if (change.type === "removed") {
              dispatch(
                removeRecurringTask({
                  recurringTask: {
                    ...doc.data(),
                    id: doc.id,
                  },
                })
              );
            }
          }
        });

        if (recurringTasksAdded && recurringTasksAdded.length > 0) {
          dispatch(
            processRecurringTasks({
              recurringTasks: recurringTasksAdded,
              dates: dates,
              override: false,
            })
          );
        }

        if (recurringTasksModified && recurringTasksModified.length > 0) {
          dispatch(
            processRecurringTasks({
              recurringTasks: recurringTasksModified,
              dates: dates,
              override: true,
            })
          );
        }
      }
    );

    return unsubRecurringTasks;
  }

  useEffect(() => {
    if (userId) {
      dispatch(setUid(userId));
    }
    dispatch(loadInitialDates());
    const unsubCurrentUser = loadCurrentUser();

    const unsubLabels = loadLabels();

    const { unsubBraindump, unsubBraindumpOrder } = loadBraindump();

    return () => {
      unsubBraindump();
      unsubBraindumpOrder();
      unsubCurrentUser();
      unsubLabels();
    };
  }, [dispatch, userId]);

  function reloadData(dates) {
    const unsubTasks = loadTasksForDate(dates);

    const unsubTaskOrder = loadTaskOrderForDates(dates);

    const unsubRecurringTasks = loadRecurringTasks(dates);

    setActiveTaskQueries((activeTaskQueries) => {
      // Iterate through the active task queries and unsubscribe from them
      activeTaskQueries.forEach((unsub) => {
        unsub();
      });

      // Add the new ones to the list
      return [unsubTasks, unsubTaskOrder, unsubRecurringTasks];
    });
  }

  useEffect(() => {
    // If the last rollover date is not today, roll over the tasks
    if (currentUserLoaded && rollover_disabled === false) {
      if (last_rollover_date !== moment().startOf("day").format("YYYY-MM-DD")) {
        const functions = getFunctions();
        //   connectFunctionsEmulator(functions, "localhost", 5001);
        const rollOverTasks = httpsCallable(functions, "rollOverTasksV2");

        var systemTimezone = moment.tz.guess();
        var systemOffset = moment.tz(systemTimezone).utcOffset();

        // If the systemTimezone is null, default to Central Time
        if (!systemTimezone) {
          systemTimezone = "America/Chicago";
          systemOffset = -300;
        }

        console.log("rollover tasks");

        rollOverTasks({
          systemTimezone: systemTimezone,
          rollOverPosition: rollover_position,
        }).then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const data = result.data;
        });
      }
    }
  }, [last_rollover_date, currentUserLoaded, dispatch]);

  const debouncedSave = useCallback(
    debounce((dates) => reloadData(dates), 250),
    [] // will be created only once initially
  );

  useEffect(() => {
    debouncedSave(dates);
  }, [dates, debouncedSave]);

  /*
  useEffect(() => {
    if (dates.length > 0) {
      const ghostTasksLoadedForDates = dateRangesLoaded.ghostTasks;
      const taskOrdersLoadedForDates = dateRangesLoaded.taskOrders;

      if (recurringTasksLoaded) {
        if (taskOrdersLoadedForDates.length > ghostTasksLoadedForDates.length) {
          dispatch(
            loadRecurringTaskGhosts({
              dates,
            })
          );
        }
      }
    }
  }, [dates, dateRangesLoaded, recurringTasksLoaded]);
 */

  useInterval(
    () => {
      // Your custom logic here
      console.log("checking for a refresh");
      // If the lastRefreshed date is less than the start of today, let's refresh the data
      if (lastRefreshed.toDate() < moment().startOf("day").toDate()) {
        console.log("WE HAVE A REFRESH");
        dispatch(changeStartDate(moment(new Date()).format("YYYY-MM-DD")));
        dispatch(
          changeCalendarDate({
            date: moment(new Date()).format("YYYY-MM-DD"),
          })
        );
        dispatch(loadDatesFromStartDate(new Date().toString()));
        dispatch(refreshKabanCursor(moment(new Date()).format("YYYY-MM-DD")));

        setLastRefreshed(moment());

        // If the last rollover date is not today, roll over the tasks
        if (currentUserLoaded && rollover_disabled === false) {
          if (
            last_rollover_date !== moment().startOf("day").format("YYYY-MM-DD")
          ) {
            const functions = getFunctions();
            //   connectFunctionsEmulator(functions, "localhost", 5001);
            const rollOverTasks = httpsCallable(functions, "rollOverTasks");

            var systemTimezone = moment.tz.guess();
            var systemOffset = moment.tz(systemTimezone).utcOffset();

            rollOverTasks({
              systemOffset: systemOffset,
              rollOverPosition: rollover_position,
            }).then((result) => {
              // Read result of the Cloud Function.
              /** @type {any} */
              const data = result.data;
            });
          }
        }
      }
    },
    // Delay in milliseconds or null to stop it
    // Default to 1 hour
    1000 * 60
  );

  if (!currentUserLoaded) {
    return null;
  }

  return (
    <div>
      <Router>
        <SubscriptionSuccess />
        <CalendarSuccess />
        <Routes>
          <Route
            path="*"
            element={
              <DndProvider debugMode={true} backend={MouseBackEnd}>
                <Settings />
                <UpgradeModal />
                <TrialModal />

                <CardModal />
                <CreateTaskModal />
                <Default>
                  <DnDContainer />
                </Default>
                <Mobile>
                  <div className="mobile-container">
                    <div className="mobile-content">
                      {mobilePageActive === "settings" ? (
                        <div className="mobile-settings-page">
                          <SettingsContent />
                        </div>
                      ) : (
                        <DnDContainer />
                      )}
                    </div>
                    <div className="tabbar-container">
                      <TabBar />
                    </div>
                  </div>
                </Mobile>
              </DndProvider>
            }
          />

          <Route
            path="analytics"
            element={<Analytics reloadDataForDates={debouncedSave} />}
          />

          <Route path="signup" element={<Onboarding />} />

          <Route path="canny-sso" element={<CannyAuth userId={userId} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
