import "./Settings.css";
import { useState } from "react";

import { getInitials } from "../../utils";

import { useSelector } from "react-redux";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default function SettingsAvatar({ url, onUpload, name }) {
  const [uploading, setUploading] = useState(false);

  const uid = useSelector((state) => state.app.uid);

  async function uploadAvatar(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];

      const storage = getStorage();

      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, "images/" + uid + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            onUpload(downloadURL);
          });
        }
      );
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div className="settings-item" style={{ rowGap: "14px" }}>
      {url ? (
        <img src={url} alt="Avatar" className="user-avatar" />
      ) : (
        <div className="user-avatar"> {getInitials(name)}</div>
      )}

      <div className="same-line">
        <label className="button" htmlFor="single">
          {uploading ? "Uploading ..." : "Upload photo"}
          <input
            style={{
              visibility: "hidden",
              position: "absolute",
              width: "10px",
            }}
            type="file"
            id="single"
            accept="image/*"
            onChange={uploadAvatar}
            disabled={uploading}
          />
        </label>

        {url && (
          <div
            onClick={() => {
              onUpload(null);
            }}
            className="button"
          >
            Remove
          </div>
        )}
      </div>
    </div>
  );
}
