/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import "./Kanban.css";

import DatePicker from "../Sidebar/DatePicker";
import Labels from "../Labels";

import { useDispatch, useSelector } from "react-redux";
import { Switch, Popover } from "antd";

import {
  changeStartDate,
  loadDatesFromStartDate,
  refreshKabanCursor,
  updateCurrentUser,
  toggleSettings,
  setUpgradeVisible,
  loadPreviousDates,
  loadNextDates,
} from "../../redux/appSlice";

import { changeCalendarDate } from "../../redux/tasksSlice";

import moment from "moment";

import { getInitials } from "../../utils";

import { useSpring, animated as a } from "react-spring";
import HelpIcon from "../Generics/HelpIcon";
import { BsKanban, BsLayoutSidebarInsetReverse } from "react-icons/bs";
import {
  ArrowRightIcon,
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/24/outline";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { TbCalendarEvent, TbLayoutKanban } from "react-icons/tb";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import MiniToolbar from "../Calendar/MiniToolbar";
export default function TopbarActions({
  loadCalendarEvents,
  lastSync,
  setLastSync,
}) {
  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showLabels, setShowLabels] = useState(false);

  const date = useSelector((state) => state.tasks.calendarDate);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const {
    label_filters: activeLabels,
    hide_complete,
    hide_sidebar,
    hide_calendar_sidebar,
    avatar_url,
    name,
    pro_meta,
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  const labelsSpringProps = useSpring({
    opacity: showLabels ? 1 : 0,
  });

  const datePickerSpringProps = useSpring({
    opacity: showDatePicker ? 1 : 0,
  });

  return (
    <div className="kanban-topbar">
      <div className="topbar-left">
        {hide_sidebar ? (
          <ArrowSmallRightIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_sidebar: false,
                  },
                  previousValues: {
                    hide_sidebar: true,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        ) : (
          <ArrowSmallLeftIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_sidebar: true,
                  },
                  previousValues: {
                    hide_sidebar: false,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        )}

        {mode == "kanban" && (
          <>
            <div
              onClick={() => {
                if (mode == "calendar") {
                  dispatch(
                    changeCalendarDate({
                      date: moment().format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(changeStartDate(moment().format("YYYY-MM-DD")));
                  dispatch(loadDatesFromStartDate(Date().toString()));

                  dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
                }
              }}
              className="topbar-button"
            >
              Today
            </div>
          </>
        )}

        {mode == "calendar" && (
          <>
            <div className="calendar-month-header">
              <span>{moment(date).format("MMM YYYY")}</span>
            </div>

            <div
              onClick={() => {
                if (mode == "calendar") {
                  dispatch(
                    changeCalendarDate({
                      date: moment().format("YYYY-MM-DD"),
                    })
                  );
                } else {
                  dispatch(changeStartDate(moment().format("YYYY-MM-DD")));
                  dispatch(loadDatesFromStartDate(Date().toString()));

                  dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
                }
              }}
              className="topbar-button"
            >
              Today
            </div>

            <div className="week-navigation-buttons">
              <FiChevronLeft
                className="topbar-button nav-button"
                onClick={() => {
                  // Change start date
                  dispatch(
                    changeStartDate(
                      moment(date, "YYYY-MM-DD")
                        .subtract(7, "days")
                        .format("YYYY-MM-DD")
                    )
                  );

                  dispatch(loadPreviousDates());

                  dispatch(
                    changeCalendarDate({
                      date: moment(date, "YYYY-MM-DD")
                        .subtract(7, "days")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              />

              <FiChevronRight
                className="topbar-button nav-button"
                onClick={() => {
                  dispatch(
                    changeStartDate(
                      moment(date, "YYYY-MM-DD")
                        .add(7, "days")
                        .format("YYYY-MM-DD")
                    )
                  );

                  dispatch(loadNextDates());

                  dispatch(
                    changeCalendarDate({
                      date: moment(date, "YYYY-MM-DD")
                        .add(7, "days")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className="topbar-right">
        <div className="topbar-actions">
          {subscriptionActive && pro_meta?.pro_status === "trial" && (
            <div
              onClick={() => {
                dispatch(setUpgradeVisible(true));
              }}
              className="topbar-button trial-button"
            >
              Free Trial (
              {moment(
                pro_meta?.pro_expiration_date,
                "YYYY-MM-DD HH:mm:ss"
              ).fromNow(true)}{" "}
              left)
            </div>
          )}

          {mode == "calendar" && (
            <MiniToolbar
              loadCalendarEvents={loadCalendarEvents}
              lastSync={lastSync}
              setLastSync={setLastSync}
            />
          )}

          <Popover
            placement="bottom"
            trigger="click"
            content={
              <div>
                <Labels />

                <div className="hc-container">
                  Show Complete{" "}
                  <Switch
                    size="small"
                    checked={!hide_complete}
                    defaultChecked
                    onClick={() => {
                      // Update the current user's hide_complete value

                      window.mixpanel.track("Hide complete toggled", {
                        active: !hide_complete,
                      });

                      dispatch(
                        updateCurrentUser({
                          newValues: {
                            hide_complete: !hide_complete,
                          },
                          previousValues: {
                            hide_complete: hide_complete,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </div>
            }
          >
            <div
              onClick={() => {
                setShowLabels(!showLabels);
              }}
              className={`topbar-button filter 
                ${activeLabels?.length > 0 ? `active` : ""}`}
            >
              Filter{" "}
              {activeLabels?.length > 0 ? `(${activeLabels?.length})` : null}
            </div>
          </Popover>

          <div
            onClick={() => {

              
              dispatch(
                updateCurrentUser({
                  newValues: {
                    mode: mode == "calendar" ? "kanban" : "calendar",
                  },
                  previousValues: {
                    mode: mode,
                  },
                })
              );

              dispatch(refreshKabanCursor(moment().format("YYYY-MM-DD")));
            }}
            className="topbar-toggle"
          >
            <div className={mode == "calendar" ? "option active" : "option"}>
              <TbCalendarEvent className="topbar-button-icon" />
              <span>Calendar</span>
            </div>

            <div className={mode == "kanban" ? "option active" : "option"}>
              <TbLayoutKanban className="topbar-button-icon" />
              <span>Tasks</span>
            </div>
          </div>

          {avatar_url && (
            <img
              src={avatar_url}
              className="avatar-button"
              onClick={() => {
                dispatch(toggleSettings());
              }}
            />
          )}
          {!avatar_url && (
            <div
              onClick={() => {
                dispatch(toggleSettings());
              }}
              className="avatar-button"
            >
              {getInitials(name)}
            </div>
          )}
        </div>

        {hide_calendar_sidebar ? (
          <ArrowSmallLeftIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_calendar_sidebar: false,
                  },
                  previousValues: {
                    hide_calendar_sidebar: true,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        ) : (
          <ArrowRightIcon
            onClick={() => {
              dispatch(
                updateCurrentUser({
                  newValues: {
                    hide_calendar_sidebar: true,
                  },
                  previousValues: {
                    hide_calendar_sidebar: false,
                  },
                })
              );
            }}
            className="collapse-icon"
          />
        )}
      </div>
    </div>
  );
}
