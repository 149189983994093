import "./DatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Sheet, { SheetRef } from "react-modal-sheet";
import {
  SunIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";
import { ArchiveBoxIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import CompleteInput from "../../Kanban/Card/Components/CompleteInput";

import { updateTask, updateTaskOrder } from "../../../redux/tasksSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import _ from "lodash";

export default function DatePickerComponent({
  item,
  isOpen,
  setIsOpen,
  updateItemLocal,
}) {
  const dispatch = useDispatch();

  const taskOrder = useSelector((state) => state.tasks.order);
  const brainDumpOrder = useSelector(
    (state) => state.tasks?.order?.["brain_dump"]?.order
  );

  async function updateOrder(date, order) {
    dispatch(
      updateTaskOrder({
        date: date,
        order: order,
        previousOrder: taskOrder[date]?.order,
      })
    );
  }

  function moveTask(newDate) {
    // If updateItemLocal is passed, update the local item then exit
    if (updateItemLocal) {
      updateItemLocal(newDate);
      setIsOpen(false);
      return;
    }

    // Both dates are in the format YYYY-MM-DD already

    // Get date in format YYYY-MM-DD
    const dateString = moment(item.date).format("YYYY-MM-DD");

    var oldDate = dateString;

    // Don't allow movement if the source and destination are the same
    if (oldDate !== newDate) {
      // Let's deal with the removal first

      var orderToRemove = _.cloneDeep(taskOrder[oldDate || "brain_dump"]);

      const updatedOrderToRemove = orderToRemove.order.filter(
        (task) => task !== item.id
      );

      updateOrder(oldDate || "brain_dump", updatedOrderToRemove);

      // If not null, add it to the relevant date
      var orderToAddTask = _.cloneDeep(taskOrder[newDate || "brain_dump"]);

      var updatedNewOrder = [];

      if (orderToAddTask && orderToAddTask.order) {
        updatedNewOrder = orderToAddTask.order;
        updatedNewOrder.unshift(item.id);
      } else {
        updatedNewOrder = [item.id];
      }

      updateOrder(newDate || "brain_dump", updatedNewOrder);

      // Now let's update the task with the relevant date
      dispatch(
        updateTask({
          taskId: item.id,
          newData: {
            date: moment(newDate, "YYYY-MM-DD").toDate(),
          },
          currentTask: item,
        })
      );

      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  }

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="dp-content">
            <div className="dp-buttons">
              <div
                onClick={() => {
                  moveTask(null);
                }}
                className="dp-button"
              >
                <ArchiveBoxIcon className="dp-button-icon" />
                <span>Braindump</span>
              </div>
              <div
                onClick={() => {
                  moveTask(moment().format("YYYY-MM-DD"));
                }}
                className="dp-button"
              >
                <SunIcon className="dp-button-icon" />
                <span>Today</span>
              </div>
              <div
                onClick={() => {
                  moveTask(moment().add(1, "days").format("YYYY-MM-DD"));
                }}
                className="dp-button"
              >
                <ArrowRightCircleIcon className="dp-button-icon" />
                <span>Tomorrow</span>
              </div>
            </div>

            <div className="dp-calendar">
              <DatePicker
                selected={item.date}
                onChange={(date) => {
                  moveTask(moment(date).format("YYYY-MM-DD"));
                }}
                inline
              />
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}
