import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import Card from "../../Components/Kanban/Card";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Droppable from "../../Components/DnDContainer/Containers/Droppable";
import SortableCard from "../../Components/DnDContainer/Containers/Sortable";

function BraindumpBody({
  brainDumpOrder,
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
  hide_sidebar,
}) {
  const { data: taskData } = useSelector((state) => state.tasks);

  return (
    <Droppable braindump={true} id="brain_dump" hide_sidebar={hide_sidebar}>
      <SortableContext
        items={brainDumpOrder}
        strategy={verticalListSortingStrategy}
        id="brain_dump"
      >
        {brainDumpOrder.map((taskId, index) => {
          if (taskData[taskId]) {
            return (
              <SortableCard columnId={"brain_dump"} id={taskId} key={taskId}>
                <Card
                  key={taskId}
                  index={index}
                  item={taskData[taskId]}
                  activelySelected={indexSelected === index}
                  manuallySelectColumn={manuallySelectColumn}
                  navigatedViaKeyboard={navigatedViaKeyboard}
                  columnId="braindump"
                  setIndexSelected={setIndexSelected}
                />
              </SortableCard>
            );
          } else {
            return (
              <SortableCard columnId={"brain_dump"} id={taskId} key={taskId} />
            );
          }
        })}
      </SortableContext>
    </Droppable>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.brainDumpOrder.length === next.brainDumpOrder.length &&
    prev.brainDumpOrder.every(
      (taskId, index) => taskId === next.brainDumpOrder[index]
    ) &&
    prev.hide_sidebar === next.hide_sidebar &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(BraindumpBody, areEqual);
