import { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createLabel } from "../../../../../redux/labelsSlice";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import {
  setUpgradeVisible,
  setToastVisible,
} from "../../../../../redux/appSlice";

import { useHotkeys } from "react-hotkeys-hook";
import { Popover } from "antd";
import { ColorPicker } from "../../../../Labels/LabelManager";

export default function CreateLabel({
  searchTerm,
  setSearchTerm,
  setCreateLabelActive,
  updateLabel,
  inline,
  createLabelActive,
}) {
  const [colorPickerActive, setColorPickerActive] = useState(false);
  const [newColor, setNewColor] = useState("#e91e63");
  const [newLabel, setNewLabel] = useState(searchTerm);

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  useEffect(() => {
    if (createLabelActive) {
      inputRef.current.focus();
    }
  }, [createLabelActive]);

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  function createLabelLocal() {
    var newId = uuidv4();
    dispatch(
      createLabel({
        name: newLabel,
        color: newColor,
        id: newId,
      })
    );

    setNewColor("#e91e63");
    setNewLabel("");
    if (setSearchTerm) {
      setSearchTerm("");
    }

    setCreateLabelActive(false);

    if (updateLabel) {
      updateLabel(newId);
    }
  }

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      if (newLabel && newLabel != "") {
        if (!subscriptionActive) {
          dispatch(setUpgradeVisible(true));
        } else {
          createLabelLocal();
        }
      } else {
        dispatch(
          setToastVisible({
            toastType: "error",
            message:
              "Label name cannot be empty. Please enter a name and try again.",
          })
        );
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: createLabelActive,
    },
    [subscriptionActive]
  );

  return (
    <div className={`create-label-container ${inline ? "inline" : ""}`}>
      <div className={`label-input-container ${inline ? "inline" : ""}`}>
        <div className="label-color-picker">
          <Popover
            visible={colorPickerActive}
            onVisibleChange={(visible) => {
              setColorPickerActive(visible);
            }}
            trigger="click"
            placement="bottom"
            content={
              <div className="color-picker-container">
                <ColorPicker
                  color={newColor}
                  onChange={(color) => {
                    setNewColor(color);
                  }}
                />
              </div>
            }
          >
            <div className="color-picker-color-outer">
              <div
                onClick={() => {
                  setColorPickerActive(!colorPickerActive);
                }}
                className="color-picker-color"
                style={{
                  backgroundColor: newColor,
                }}
              />
            </div>
          </Popover>
        </div>
        <input
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
          placeholder="Label Name"
          className="label-name-input"
          ref={inputRef}
        />
      </div>

      <div className="create-label-footer">
        <div
          onClick={() => {
            setCreateLabelActive(false);
          }}
          className="create-label-cancel"
        >
          Cancel
        </div>
        <div
          onClick={() => {
            if (!subscriptionActive) {
              dispatch(setUpgradeVisible(true));
            } else {
              createLabelLocal();
            }
          }}
          className="create-label-create"
        >
          Create
        </div>
      </div>
    </div>
  );
}
