import "./Task.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Sheet, { SheetRef } from "react-modal-sheet";
import {
  TagIcon,
  ClockIcon,
  MoonIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, CalendarIcon } from "@heroicons/react/24/outline";

import CompleteInput from "../../Kanban/Card/Components/CompleteInput";

import { createTask } from "../../../redux/tasksSlice";

import DatePicker from "./DatePicker";

import LabelPicker from "./LabelPicker";
import DurationPickerComponent from "./DurationPicker";
import FocusModePicker from "./FocusModeDurationPicker";

import moment from "moment";

import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default function CreateTask({ isOpen, setIsOpen }) {
  const [itemEditable, setItemEditable] = useState({
    date: new Date(),
    id: uuidv4(),
    complete: false,
  });

  async function updateItemEditableValue(key, value, save = false) {
    const tempItemEditable = _.cloneDeep(itemEditable);

    tempItemEditable[key] = value;

    setItemEditable(tempItemEditable);
  }

  const dispatch = useDispatch();

  const { data: labels } = useSelector((state) => state.labels);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [labelPickerOpen, setLabelPickerOpen] = useState(false);
  const [actualTimePickerOpen, setActualTimePickerOpen] = useState(false);
  const [estimatedTimePickerOpen, setEstimatedTimePickerOpen] = useState(false);
  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header>
          {" "}
          <div className="tv-header">
            <div
              onClick={() => {
                setItemEditable({
                  date: new Date(),
                  id: uuidv4(),
                  complete: false,
                });
                setIsOpen(false);
              }}
              className="tv-cancel"
            >
              Cancel
            </div>
            <div className="tv-title">New Task</div>
            <div
              onClick={() => {
                dispatch(createTask(itemEditable));
                setItemEditable({
                  date: new Date(),
                  id: uuidv4(),
                  complete: false,
                });
                setIsOpen(false);
              }}
              className="tv-create"
            >
              Create
            </div>
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div className="tv-container">
            <div className="tv-top">
              <div className="complete-button">
                {" "}
                <CompleteInput
                  item={itemEditable}
                  complete={itemEditable?.complete}
                  updateCompleteLocal={(newValue) => {
                    updateItemEditableValue("complete", newValue, true);
                  }}
                />
              </div>
              <TextareaAutosize
                placeholder="Task Description"
                className="task-description"
                value={itemEditable?.description}
                onChange={(e) => {
                  updateItemEditableValue("description", e.target.value);
                }}
              />
            </div>
            <div className="tv-bottom">
              <div
                onClick={() => {
                  setDatePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <CalendarIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    {getDayValue(itemEditable)}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setLabelPickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  {itemEditable.label && labels[itemEditable.label] && (
                    <>
                      <TagIcon
                        style={{ color: labels[itemEditable.label].color }}
                        className="task-meta-row-icon"
                      />
                      <span
                        style={{ color: labels[itemEditable.label].color }}
                        className="task-meta-row-text"
                      >
                        {labels[itemEditable.label].name}
                      </span>
                    </>
                  )}

                  {(!itemEditable.label || !labels[itemEditable.label]) && (
                    <>
                      <TagIcon className="task-meta-row-icon" />
                      <span className="task-meta-row-text">No Label</span>
                    </>
                  )}
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setEstimatedTimePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <ClockIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    Estimated Time:{" "}
                    {!isNaN(itemEditable.estimated_time)
                      ? moment
                          .utc(itemEditable.estimated_time * 1000)
                          .format("H:mm")
                      : "0:00"}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>

              <div
                onClick={() => {
                  setActualTimePickerOpen(true);
                }}
                className="task-meta-row"
              >
                <div className="task-meta-descriptions">
                  <ClockIcon className="task-meta-row-icon" />
                  <span className="task-meta-row-text">
                    Actual Time:{" "}
                    {!isNaN(itemEditable.actual_time)
                      ? moment
                          .utc(itemEditable.actual_time * 1000)
                          .format("H:mm")
                      : "0:00"}
                  </span>
                </div>
                <ChevronRightIcon className="task-meta-arrow" />
              </div>
            </div>
          </div>

          <DatePicker
            item={itemEditable}
            isOpen={datePickerOpen}
            setIsOpen={setDatePickerOpen}
            updateItemLocal={(newValue) => {
              updateItemEditableValue("date", newValue, true);
            }}
          />
          <LabelPicker
            item={itemEditable}
            isOpen={labelPickerOpen}
            setIsOpen={setLabelPickerOpen}
            updateItemLocal={(newValue) => {
              updateItemEditableValue("label", newValue, true);
            }}
          />

          <DurationPickerComponent
            item={itemEditable}
            time={itemEditable.estimated_time}
            title="Estimated"
            isOpen={estimatedTimePickerOpen}
            setIsOpen={setEstimatedTimePickerOpen}
            updateItemLocal={(newValue) => {
              updateItemEditableValue("estimated_time", newValue, true);
            }}
          />

          <DurationPickerComponent
            item={itemEditable}
            time={itemEditable.actual_time}
            title="Actual"
            isOpen={actualTimePickerOpen}
            setIsOpen={setActualTimePickerOpen}
            updateItemLocal={(newValue) => {
              updateItemEditableValue("actual_time", newValue, true);
            }}
          />
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}

function getDayValue(itemEditable) {
  if (itemEditable.date == null) {
    return "Braindump";
  }

  var date = moment(itemEditable.date);
  if (date) {
    // Check if date is today
    if (date.isSame(moment(), "day")) {
      return "Today";
    }

    // Check if date is tomorrow
    if (date.isSame(moment().add(1, "day"), "day")) {
      return "Tomorrow";
    }

    // Return the date in a readable format
    return date.format("ddd, MMM Do");
  } else {
    return "Invalid date";
  }
}
