import { useState, useRef } from "react";
import CompleteInput from "../../Kanban/Card/Components/CompleteInput";
import LabelSelector from "../../Kanban/Card/Components/LabelSelector";

import TimeSection from "../../Kanban/Card/Components/TimeSection";
import { useDrag, useDrop } from "react-dnd";

import { updateTask } from "../../../redux/tasksSlice";

import "./Card.css";

import { useDispatch, useSelector } from "react-redux";

import TaskView from "../Task/TaskView";

import moment from "moment";
import { ItemTypes } from "../../DnDContainer/ItemTypes";

export default function Card({ item, index, onDragEnd, saveOrder, columnId }) {
  const ref = useRef(null);
  const [taskActive, setTaskActive] = useState(false);
  const [timeSectionActive, setTimeSectionActive] = useState(false);

  const [labelSelectorActive, setLabelSelectorActive] = useState(false);
  const { label_filters: activeLabels, hide_complete } = useSelector(
    (state) => state.app?.currentUser
  );

  const dispatch = useDispatch();

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.TASK,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      const sourceColumn = item.columnId;
      const destinationColumn = columnId;

      const taskDragged = item.id;

      // Don't replace items with themselves
      if (sourceColumn === destinationColumn && dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (sourceColumn === destinationColumn) {
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
      }
      // Time to actually perform the action
      onDragEnd({
        source: {
          droppableId: sourceColumn,
          index: dragIndex,
        },
        destination: {
          droppableId: destinationColumn,
          index: hoverIndex,
        },
        draggableId: taskDragged,
        type: "task",
        isHover: true,
      });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      item.columnId = destinationColumn;
    },
    drop(monitor) {
      if (saveOrder) {
        saveOrder(item, columnId);
      }
    },
  });

  const [{ isDragging, canDrag }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: () => {
      return { id: item.id, index, columnId };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      };
    },
    isDragging: (monitor) => {
      return item.id === monitor.getItem().id;
    },
  });

  const opacity = isDragging ? 0.25 : 1;

  drag(drop(ref));

  return (
    <div
      className="draggable-card-container"
      style={
        (activeLabels &&
          activeLabels.length !== 0 &&
          !activeLabels?.includes(item.label)) ||
        (item.complete && hide_complete)
          ? { display: "none" }
          : {}
      }
      ref={ref}
    >
      <div
        onClick={() => {
          setTaskActive(true);
        }}
        className={`card-container ${item.complete ? " complete" : ""}`}
      >
        <div className="card-header">
          <div className="card-description-non-editable">
            {" "}
            {item.description}
          </div>
          <div className="card-time-estimate">
            {!isNaN(item.estimated_time)
              ? moment.utc(item.estimated_time * 1000).format("H:mm")
              : "0:00"}
          </div>
        </div>
        <div className="card-footer">
          <CompleteInput item={item} complete={item?.complete} />
          <TaskView
            item={item}
            isOpen={taskActive}
            setIsOpen={setTaskActive}
            key={item.id + "task_view"}
          />
          <LabelSelector
            label={item?.label}
            item={item}
            labelPickerActive={labelSelectorActive}
            setLabelPickerActive={setLabelSelectorActive}
            disabled={true}
          />
        </div>
        {timeSectionActive && (
          <div className="card-time-content">
            <TimeSection item={item} />
          </div>
        )}
      </div>
    </div>
  );
}
