import React, { useEffect, useCallback, useRef } from "react";
import useState from "react-usestateref";
import Kanban from "../Kanban";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTask,
  updateTaskOrder,
  bulkUpdateTaskOrder,
  loadRecurringTaskGhosts,
} from "../../redux/tasksSlice";
import moment from "moment";
import { createPortal, unstable_batchedUpdates } from "react-dom";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

import _ from "lodash";

import { Mobile, Default } from "../../mediaUtils";

import { AdjustmentsIcon } from "@heroicons/react/24/outline";

import DayView from "../Mobile/DayView";
import Braindump from "../Mobile/Braindump";

import CreateTask from "../Mobile/Task/CreateTask";
import Filters from "../Mobile/Filters";

import FocusMode from "../Mobile/Task/FocusMode";
import { original } from "@reduxjs/toolkit";
import { DragControls, m } from "framer-motion";
import MiniDayView from "../Calendar/MiniDayView";
import MiniKanbanView from "../Kanban/MiniKanbanView";
import { useDragLayer } from "react-dnd";
import CardPreview from "../Kanban/Card/CardPreview";
import SubtaskPreview from "../Kanban/Card/Components/Subtasks/SubtaskPreview";
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  Modifier,
  useDroppable,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  AdjustmentsVerticalIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import WeekView from "../Calendar/WeekView";

class MyPointerSensor extends TouchSensor {
  static activators = [
    {
      eventName: "onTouchStart",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

function shouldHandleEvent(element) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "textarea",
    "select",
    "option",
  ];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}

export function restrictToBoundingRect(transform, rect, boundingRect) {
  const value = {
    ...transform,
  };

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top;
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom;
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left;
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right;
  }

  return value;
}

export const restrictToSecondScrollableAncestor = ({
  draggingNodeRect,
  transform,
  scrollableAncestorRects,
}) => {
  const firstScrollableAncestorRect = scrollableAncestorRects[0];

  if (!draggingNodeRect || !firstScrollableAncestorRect) {
    return transform;
  }

  return restrictToBoundingRect(
    transform,
    draggingNodeRect,
    firstScrollableAncestorRect
  );
};

function DnDContainer() {
  const date = useSelector((state) => state.tasks.calendarDate);
  const tasks = useSelector((state) => state.tasks.data);

  const mode = useSelector((state) => state.app.currentUser?.mode || "kanban");

  const taskOrder = useSelector((state) => state.tasks.order);

  const mobilePageActive = useSelector((state) => state.app.mobilePageActive);

  const [isDragging, setIsDragging] = useState(false);

  const [createTaskActive, setCreateTaskActive] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);

  const [taskOrderEditable, setTaskOrderEditable, taskOrderEditableRef] =
    useState({});

  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const [clonedItems, setClonedItems] = useState(null);

  function getParentContainer(collision, parentIds) {
    // If the collision id is in parentIds, return it
    if (parentIds.includes(collision.id)) {
      return collision.id;
    }

    return (
      collision.data.droppableContainer?.data?.current?.sortable?.containerId ||
      collision.id
    );
  }

  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in taskOrderEditable) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in taskOrderEditable
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);

      let overId = getFirstCollision(intersections, "id");
      const collisionIds = intersections.map((intersection) => intersection.id);

      // If there are 4 collision ids, get the first one whose parent is "brain_dump"
      if (collisionIds.length >= 2) {
        const brainDumpCollision = intersections.find(
          (intersection) =>
            getParentContainer(intersection, Object.keys(taskOrderEditable)) ===
            "brain_dump"
        );

        if (brainDumpCollision) {
          overId = brainDumpCollision.id;
        }

        // If CALENDAR is one of the collisions, return it
        if (collisionIds.includes("CALENDAR")) {
          overId = "CALENDAR";
        }
      }

      if (overId != null) {
        if (overId === "CALENDAR") {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app

          return [{ id: "CALENDAR" }];
        }

        if (overId in taskOrderEditable) {
          const containerItems = taskOrderEditable[overId].order || [];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, taskOrderEditable]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [taskOrderEditable]);

  useEffect(() => {
    // If taskOrder is different from taskOrderEditable, update taskOrderEditable
    if (!_.isEqual(taskOrder, taskOrderEditable)) {
      setTaskOrderEditable(taskOrder);
    }
  }, [taskOrder]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(
    mouseSensor,
    useSensor(MyPointerSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 5,
      },
    })
  );

  const findContainer = (id) => {
    if (Object.keys(taskOrderEditable).includes(id)) {
      return id;
    }

    return Object.keys(taskOrderEditable).find((key) =>
      taskOrderEditable[key]?.order.includes(id)
    );
  };

  const getIndex = (id) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = taskOrderEditable[container]?.order.indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setTaskOrderEditable(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  function getNewCompletionStatus(newDate, currentCompletionStatus) {
    // If newDate === "brain_dump", return false
    if (newDate === "brain_dump") {
      return false;
    }

    // If newDate is before the start of today, return true
    if (
      moment(newDate, "YYYY-MM-DD").toDate() < moment().startOf("day").toDate()
    ) {
      return true;
    }

    return currentCompletionStatus;
  }

  function saveOrder(originalTask, newDate) {
    window.mixpanel.track("Task moved", {
      task_id: originalTask.id,
      from: originalTask.date ? "date" : "braindump",
      to: newDate === "brain_dump" ? "braindump" : "date",
    });

    // Only update the task if the date is different
    const originalDateString = moment(originalTask.date).format("YYYY-MM-DD");

    if (originalDateString !== newDate) {
      dispatch(
        updateTask({
          taskId: originalTask.id,
          newData: {
            date:
              newDate === "brain_dump"
                ? null
                : moment(newDate, "YYYY-MM-DD").toDate(),
            complete: getNewCompletionStatus(newDate, originalTask.complete),
          },
          currentTask: originalTask,
          saveGhostOrder: false,
        })
      );
    }

    var taskOrderChanges = [];

    Object.keys(taskOrderEditableRef.current).map(function (key, index) {
      var originalTaskOrder = taskOrder[key];
      var newTaskOrder = taskOrderEditableRef.current[key];

      // If the original task order was null, we need to save
      if (
        originalTaskOrder == null ||
        !_.isEqual(originalTaskOrder, newTaskOrder)
      ) {
        // Save the new task order

        taskOrderChanges.push({
          date:
            key === "brain_dump"
              ? "brain_dump"
              : moment(key, "YYYY-MM-DD").toDate(),
          newOrder: newTaskOrder.order,
          previousOrder: originalTaskOrder?.order || [],
        });
      }
    });

    if (taskOrderChanges.length === 2) {
      // We are doing a multi update

      var bulkUpdate = {
        newOrder: [
          {
            date: taskOrderChanges[0].date,
            order: taskOrderChanges[0].newOrder,
          },
          {
            date: taskOrderChanges[1].date,
            order: taskOrderChanges[1].newOrder,
          },
        ],
        previousOrder: [
          {
            date: taskOrderChanges[0].date,
            order: taskOrderChanges[0].previousOrder,
          },
          {
            date: taskOrderChanges[1].date,
            order: taskOrderChanges[1].previousOrder,
          },
        ],
      };

      dispatch(bulkUpdateTaskOrder(bulkUpdate));
    } else {
      taskOrderChanges.forEach(function (taskOrderChange) {
        // Get date in format YYYY-MM-DD
        const dateString =
          taskOrderChange.date === "brain_dump"
            ? "brain_dump"
            : moment(taskOrderChange.date).format("YYYY-MM-DD");

        dispatch(
          updateTaskOrder({
            date: dateString,
            order: taskOrderChange.newOrder,
            previousOrder: taskOrderChange.previousOrder,
          })
        );
      });
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const dropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.5",
        },
      },
    }),
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  function resetOrder() {
    // Reset the task order
    setTaskOrderEditable(taskOrder);
  }

  return (
    <>
      <FocusMode />
      <Default>
        <div id={"app-container"} className="app-container">
          <DndContext
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            autoScroll={{
              threshold: {
                // Left and right 10% of the scroll container activate scrolling
                x: 0,
                // Top and bottom 25% of the scroll container activate scrolling
                y: 0.25,
              },
              // Accelerate slower than the default value (10)
              acceleration: 5,
              // Auto-scroll every 10ms instead of the default value of 5ms
              interval: 5,
              canScroll(element) {
                return true;
              },
            }}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
            onDragStart={({ active }) => {
              setActiveId(active.id);
              setClonedItems(taskOrderEditable);
              setIsDragging(true);
            }}
            onDragOver={({ active, over }) => {
              const overId = over?.id;

              if (overId == null || overId === "CALENDAR") {
                return;
              }

              const overContainer = findContainer(overId);
              const activeContainer = findContainer(active.id);

              if (!overContainer || !activeContainer) {
                return;
              }

              if (activeContainer !== overContainer) {
                setTaskOrderEditable((taskOrders) => {
                  const activeItems = taskOrders[activeContainer]?.order || [];
                  const overItems = taskOrders[overContainer]?.order || [];
                  const overIndex = overItems.indexOf(overId);
                  const activeIndex = activeItems.indexOf(active.id);

                  let newIndex;

                  if (overId in taskOrders) {
                    newIndex = overItems.length + 1;
                  } else {
                    const isBelowOverItem =
                      over &&
                      active.rect.current.translated &&
                      active.rect.current.translated.top >
                        over.rect.top + over.rect.height;

                    const modifier = isBelowOverItem ? 1 : 0;

                    newIndex =
                      overIndex >= 0
                        ? overIndex + modifier
                        : overItems.length + 1;
                  }

                  recentlyMovedToNewContainer.current = true;

                  return {
                    ...taskOrders,
                    [activeContainer]: {
                      ...taskOrders[activeContainer],
                      order: taskOrders[activeContainer]?.order.filter(
                        (item) => item !== active.id
                      ),
                    },
                    [overContainer]: {
                      ...taskOrders[overContainer],
                      order: [
                        ...taskOrders[overContainer]?.order.slice(0, newIndex),
                        taskOrders[activeContainer]?.order[activeIndex],
                        ...taskOrders[overContainer]?.order.slice(
                          newIndex,
                          taskOrders[overContainer]?.order.length
                        ),
                      ],
                    },
                  };
                });
              }
            }}
            onDragEnd={({ active, over }) => {
              const overId = over?.id;

              setIsDragging(false);
              const activeContainer = findContainer(active.id);

              if (!activeContainer) {
                setActiveId(null);
                return;
              }

              if (overId == null) {
                setActiveId(null);
                return;
              }

              if (overId === "CALENDAR") {
                if (clonedItems) {
                  // Reset items to their original state in case items have been
                  // Dragged across containers
                  setTaskOrderEditable(clonedItems);
                }

                setActiveId(null);
                setClonedItems(null);
                setActiveId(null);

                return;
              }

              /*
            if (overId === "PLACEHOLDER") {
              const newContainerId = getNextContainerId();

              unstable_batchedUpdates(() => {
                setContainers((containers) => [...containers, newContainerId]);
                setItems((items) => ({
                  ...items,
                  [activeContainer]: items[activeContainer].filter(
                    (id) => id !== activeId
                  ),
                  [newContainerId]: [active.id],
                }));
                setActiveId(null);
              });
              return;
            } */

              const overContainer = findContainer(overId);

              if (overContainer) {
                const activeIndex = taskOrderEditable[
                  activeContainer
                ]?.order.indexOf(active.id);
                const overIndex =
                  taskOrderEditable[overContainer]?.order.indexOf(overId);

                if (activeIndex !== overIndex) {
                  setTaskOrderEditable((taskOrders) => ({
                    ...taskOrders,
                    [overContainer]: {
                      ...taskOrders[overContainer],
                      order: arrayMove(
                        taskOrders[overContainer]?.order,
                        activeIndex,
                        overIndex
                      ),
                    },
                  }));
                }
              }

              const taskMoved = tasks[active.id];
              saveOrder(taskMoved, activeContainer);

              setActiveId(null);
            }}
            //   cancelDrop={cancelDrop}
            onDragCancel={onDragCancel}
            //     modifiers={[restrictToSecondScrollableAncestor]}
          >
            {mode === "kanban" ? (
              <Kanban isDragging={isDragging} taskOrder={taskOrderEditable} />
            ) : (
              <WeekView />
            )}

            <Sidebar
              brainDumpOrder={taskOrderEditable?.["brain_dump"]?.order}
            />

            {mode === "kanban" ? (
              <MiniDayView />
            ) : (
              <MiniKanbanView
                orderEditable={taskOrderEditable?.[date]?.order}
                date={date}
              />
            )}

            {createPortal(
              <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
                {activeId ? <CardPreview taskId={activeId} /> : null}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </div>
      </Default>

      <Mobile>
        {mobilePageActive === "braindump" ? (
          <Braindump
            brainDumpOrder={taskOrderEditable?.["brain_dump"]?.order}
            isDragging={isDragging}
            saveOrder={saveOrder}
          />
        ) : (
          <DayView
            isDragging={isDragging}
            saveOrder={saveOrder}
            order={taskOrderEditable}
          />
        )}

        <div className="floating-buttons-mobile">
          <div
            onClick={() => {
              setFiltersActive(!filtersActive);
            }}
            className="floating-button-mobile filter"
          >
            {" "}
            <AdjustmentsVerticalIcon className="floating-button-mobile-icon" />
          </div>

          <CreateTask
            isOpen={createTaskActive}
            setIsOpen={setCreateTaskActive}
          />

          <Filters isOpen={filtersActive} setIsOpen={setFiltersActive} />
          <div
            onClick={() => {
              setCreateTaskActive(true);
            }}
            className="floating-button-mobile"
          >
            <PlusSmallIcon className="floating-button-mobile-icon" />
          </div>
        </div>
      </Mobile>
    </>
  );
}

export const CustomDragLayer = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}
      >
        {itemType === "task" ? (
          <CardPreview taskId={item.id} />
        ) : (
          <SubtaskPreview taskId={item.taskId} subtaskId={item.id} />
        )}
      </div>
    </div>
  );
};

const layerStyles = {
  position: "fixed",
  userSelect: "none",
  zIndex: 100000,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export function snapToGrid(x, y) {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

export default React.memo(DnDContainer, (prev, next) => {
  return true;
});
