import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { updateCurrentUser } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import { Select, Switch } from "antd";
const { Option } = Select;

export default function Customize() {
  const {
    move_task_on_complete_disabled = false,
    new_task_position = "top",
    rollover_disabled = false,
    rollover_position = "bottom",
    focus_mode_type = "timer",
    auto_complete_on_subtasks_disabled = false,
  } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  function updateUserSettings(newValues, oldValues) {
    Object.keys(newValues).forEach(function (key) {
      var value = newValues[key];
      window.mixpanel.track("Settings updated", {
        type: key,
        value: value,
      });
    });

    dispatch(
      updateCurrentUser({
        newValues: newValues,
        previousValues: oldValues,
      })
    );
  }

  return (
    <div>
      <div className="settings personal">
        <div className="settings-item">
          <div className="settings-item-title">New Task Position</div>
          <div className="settings-item-value">
            Add new tasks to the
            <Select
              size="small"
              style={{ marginLeft: "10px" }}
              value={new_task_position}
              onChange={(value) => {
                updateUserSettings(
                  { new_task_position: value },
                  { new_task_position: new_task_position }
                );
              }}
            >
              <Option value="top">Top of list</Option>
              <Option value="bottom">Bottom of list</Option>
            </Select>
          </div>
        </div>

        <Default>
          <div className="settings-rowdivider" />
        </Default>

        <div className="settings-item">
          <div className="settings-item-title">Task Rollover</div>
          <div className="settings-item-value">
            Roll-over tasks to the next day
            <Switch
              size="small"
              style={{ marginLeft: "10px" }}
              checked={!rollover_disabled}
              onChange={(checked) => {
                updateUserSettings(
                  { rollover_disabled: !checked },
                  { rollover_disabled: rollover_disabled }
                );
              }}
            />
          </div>
          <div className="settings-item-value">
            Roll over tasks to the
            <Select
              size="small"
              style={{ marginLeft: "10px" }}
              value={rollover_position}
              onChange={(value) => {
                updateUserSettings(
                  { rollover_position: value },
                  { rollover_position: rollover_position }
                );
              }}
            >
              <Option value="top">Top of list</Option>
              <Option value="bottom">Bottom of list</Option>
            </Select>
          </div>
        </div>

        <Default>
          <div className="settings-rowdivider" />
        </Default>

        <div className="settings-item">
          <div className="settings-item-title">After Task Completion</div>
          <div className="settings-item-value">
            Move tasks (and subtasks) to the bottom of the list on complete
            <Switch
              size="small"
              style={{ marginLeft: "10px" }}
              checked={!move_task_on_complete_disabled}
              onChange={(checked) => {
                updateUserSettings(
                  { move_task_on_complete_disabled: !checked },
                  {
                    move_task_on_complete_disabled:
                      move_task_on_complete_disabled,
                  }
                );
              }}
            />
          </div>
          <div className="settings-item-value">
            Mark tasks as complete when subtasks are complete
            <Switch
              size="small"
              style={{ marginLeft: "10px" }}
              checked={!auto_complete_on_subtasks_disabled}
              onChange={(checked) => {
                updateUserSettings(
                  { auto_complete_on_subtasks_disabled: !checked },
                  {
                    auto_complete_on_subtasks_disabled:
                      auto_complete_on_subtasks_disabled,
                  }
                );
              }}
            />
          </div>
        </div>

        <Default>
          <div className="settings-rowdivider" />
        </Default>
        <div className="settings-item">
          <div className="settings-item-title">Focus Mode</div>

          <div className="settings-item-value">
            Enter focus mode in
            <Select
              size="small"
              style={{ marginLeft: "10px" }}
              value={focus_mode_type}
              onChange={(value) => {
                updateUserSettings(
                  { focus_mode_type: value },
                  { focus_mode_type: focus_mode_type }
                );
              }}
            >
              <Option value="timer">Timer mode (ex: Focus for 25 mins)</Option>
              <Option value="stopwatch">Stopwatch mode (unlimited time)</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
