import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDaysDiff } from "../utils";
import moment from "moment";

import { db } from "../firebase";

import { doc, setDoc } from "firebase/firestore";

import _ from "lodash";

const initialState = {
  dates: [],
  startDate: moment().format("YYYY-MM-DD"),
  kanbanRefreshCursor: moment().format("YYYY-MM-DD"),
  labelManagerVisible: false,
  settingsVisible: false,
  subscriptionActive: false,
  currentUser: {
    last_rollover_date: null,
    mode: "kanban",
  },
  uid: null,
  currentUserLoaded: false,
  mobilePageActive: "tasks",
  cardModalActiveFor: null,
  toastVisible: null,
  upgradeVisible: false,
  columnSelected: moment().format("YYYY-MM-DD"),
  isOverCalendar: false,
  createTaskModalActive: {
    active: false,
    date: null,
  }
};

// Update the current user
export const updateCurrentUser = createAsyncThunk(
  "app/updateCurrentUser",
  async ({ newValues, previousValues }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    const newValuesCopy = _.cloneDeep(newValues);

    if (newValues.active_timer?.last_start_time) {
      newValuesCopy.active_timer.last_start_time = moment(
        newValues.active_timer.last_start_time,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
    }

    if (newValues.pro_meta?.pro_expiration_date) {
      newValuesCopy.pro_meta.pro_expiration_date = moment(
        newValues.pro_meta.pro_expiration_date,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
    }

    // Easily track if filters are being used
    if (newValues.label_filters) {
      window.mixpanel.track("Filters edited", {
        active: newValues.label_filters.length,
      });
    }

    try {
      await setDoc(doc(db, "users", userId), newValuesCopy, {
        merge: true,
      });

      return { newValues, previousValues };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUid: (state, action) => {
      state.uid = action.payload;
    },
    setColumnSelected: (state, action) => {
      state.columnSelected = action.payload;
    },
    selectNextColumn: (state) => {
      // Get the current columnSelected
      const current = state.columnSelected;

      if (current !== "braindump" && current !== null) {
        // Convert from string to date
        const currentDate = moment(current, "YYYY-MM-DD");

        // Increment to the next day
        const nextDate = currentDate.add(1, "days");

        // Set the new columnSelected
        state.columnSelected = nextDate.format("YYYY-MM-DD");
      }
    },
    selectPreviousColumn: (state) => {
      // Get the current columnSelected
      const current = state.columnSelected;

      if (current !== "braindump" && current !== null) {
        // Convert from string to date
        const currentDate = moment(current, "YYYY-MM-DD");

        // Increment to the previous day
        const nextDate = currentDate.subtract(1, "days");

        // Set the new columnSelected
        state.columnSelected = nextDate.format("YYYY-MM-DD");
      }
    },
    setUpgradeVisible: (state, action) => {
      state.upgradeVisible = action.payload;
    },
    setSubscriptionActive: (state, action) => {
      state.subscriptionActive = action.payload;
    },
    setMobilePageActive: (state, action) => {
      state.mobilePageActive = action.payload;
    },
    setCardModalActive: (state, action) => {
      state.cardModalActiveFor = action.payload;
    },
    changeDates(state, action) {
      state.dates = action.dates;
    },
    toggleLabelManager(state, action) {
      state.labelManagerVisible = !state.labelManagerVisible;
    },
    toggleSettings(state, action) {
      state.settingsVisible = !state.settingsVisible;
    },
    loadNextDates(state) {
      var nextWeek = moment(state.dates[state.dates.length - 1], "YYYY-MM-DD")
        .add(7, "days")
        .format("YYYY-MM-DD");
      const newDates = getDaysDiff(state.dates[7], nextWeek);

      state.dates = newDates;
    },
    loadPreviousDates(state) {
      var lastWeek = moment(state.dates[0], "YYYY-MM-DD")
        .add(-7, "days")
        .format("YYYY-MM-DD");
      const newDates = getDaysDiff(lastWeek, state.dates[7]);

      state.dates = newDates;
    },
    loadInitialDates(state) {
      var lastWeek = moment().add(-7, "days").format("YYYY-MM-DD");
      var nextWeek = moment().add(7, "days").format("YYYY-MM-DD");
      const intialDateRange = getDaysDiff(lastWeek, nextWeek);

      state.dates = intialDateRange;
    },
    loadDatesFromStartToEnd(state, action) {
      const startDate = action.payload.startDate;
      const endDate = action.payload.endDate;

      const intialDateRange = getDaysDiff(startDate, endDate);

      state.dates = intialDateRange;
    },
    loadInDates(state, action) {
      const dates = action.payload.dates;

      state.dates = dates;
    },
    loadDatesFromStartDate(state, action) {
      const newStartDate = moment(action.payload).toDate();

      var lastWeek = moment(newStartDate).add(-7, "days").format("YYYY-MM-DD");
      var nextWeek = moment(newStartDate).add(7, "days").format("YYYY-MM-DD");
      const intialDateRange = getDaysDiff(lastWeek, nextWeek);

      state.startDate = moment(action.payload).format("YYYY-MM-DD");
      state.dates = intialDateRange;
    },
    changeStartDate(state, action) {
      state.startDate = action.payload;
    },
    changeIsOverCalendar(state, action) {
      state.isOverCalendar = action.payload;
    },
    refreshKabanCursor(state, action) {
      state.kanbanRefreshCursor = action.payload;
    },
    setCreateTaskModalActive(state, action) {
      state.createTaskModalActive = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUserLoaded = true;

      var pro_expiration_date = action.payload?.pro_meta?.pro_expiration_date;
      var pro_status = action.payload?.pro_meta?.pro_status;

      window.mixpanel.people.set({
        pro_source: action.payload?.pro_meta?.pro_source, // only reserved properties need the $
        pro_status: action.payload?.pro_meta?.pro_status, // use human-readable names
      });

      if (
        pro_expiration_date != null &&
        moment(pro_expiration_date, "YYYY-MM-DD HH:mm:ss") > moment(new Date())
      ) {
        state.subscriptionActive = true;
      } else {
        state.subscriptionActive = false;
      }

      // If this is a lifetime user, override and set subscription to active
      if (pro_status === "lifetime") {
        state.subscriptionActive = true;
      }

      state.currentUser = action.payload;
    },
    setToastVisible(state, action) {
      const payload = action.payload;
      if (payload && payload.toastType) {
        state.toastVisible = {
          type: payload.toastType,
          message: payload.message,
        };
      } else {
        state.toastVisible = null;
      }
    },
  },
  extraReducers: {
    [updateCurrentUser.pending]: (state, action) => {
      // Let's just update the store
      const { newValues } = action.meta.arg;

      state.currentUser = { ...state.currentUser, ...newValues };
    },
    [updateCurrentUser.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousValues } = action.meta.arg;
      state.currentUser = { ...state.currentUser, ...previousValues };
    },
  },
});

export const {
  changeDates,
  loadInitialDates,
  loadNextDates,
  loadPreviousDates,
  loadDatesFromStartDate,
  changeStartDate,
  changeIsOverCalendar,
  refreshKabanCursor,
  toggleLabelManager,
  toggleSettings,
  setMobilePageActive,
  setCardModalActive,
  setUid,
  setCurrentUser,
  setToastVisible,
  setSubscriptionActive,
  setUpgradeVisible,
  setColumnSelected,
  selectNextColumn,
  selectPreviousColumn,
  loadDatesFromStartToEnd,
  loadInDates,
  setCreateTaskModalActive
} = appSlice.actions;
export default appSlice.reducer;
