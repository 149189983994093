import "./FocusMode.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import Sheet from "react-modal-sheet";
import { Modal } from "antd";
import "react-datepicker/dist/react-datepicker.css";

import {
  CheckIcon,
  SearchIcon,
  ArrowSmRightIcon,
  PlayIcon,
  ClockIcon,
  MoonIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import {
  SunIcon,
  ArrowCircleRightIcon,
  ArchiveIcon,
} from "@heroicons/react/24/solid";

import moment from "moment";

import { Mobile, Default } from "../../../mediaUtils";

import { toggleLabelManager } from "../../../redux/appSlice";

import { updateTask } from "../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../redux/appSlice";

import DurationPickerInput from "../../Kanban/Card/Components/DurationPicker/DurationPickerInput";

export default function FocusModeDurationPicker({
  item,
  isOpen,
  setIsOpen,
  setTaskViewOpen,
}) {
  const [timeEditable, setTimeEditable] = useState(1500);

  const [hours, setHours] = useState(secondsToHms(1500)?.hours);
  const [minutes, setMinutes] = useState(secondsToHms(1500)?.minutes);

  const [inlineActive, setInlineActive] = useState(false);

  const dispatch = useDispatch();

  const startFocusMode = (type = "timer") => {
    // Stop the current timer then let's clear the active timer on the user

    // Update the actual_time on the task
    const active_timer = {
      task_id: item.id,
      active: true,
      focus_time_original: timeEditable,
      focus_time_left: type === "timer" ? timeEditable : 0,
      last_start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      type: type,
    };

    window.mixpanel.track("Focus mode entered", {
      start_duration: active_timer.focus_time_original,
      task_id: item.id,
      type: type,
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );

    setIsOpen(false);
  };

  useEffect(() => {
    // If the estimated time is updated, let's update the hours and minutes
    if (!inlineActive) {
      const totalSeconds =
        parseInt(hours, 10) * 60 * 60 + parseInt(minutes, 10) * 60;

      setTimeEditable(totalSeconds);
    }
  }, [inlineActive]);

  return (
    <>
      <Default>
        <Modal
          title={null}
          footer={null}
          closeIcon={null}
          closable={false}
          width={400}
          visible={isOpen}
          onOk={() => {
            setIsOpen(false);
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
        >
          <div className="duration-picker-container-mobile">
            <div className="dp-title">How long would you like to focus?</div>
            <DurationPickerInput
              hours={hours}
              minutes={minutes}
              setHours={setHours}
              setMinutes={setMinutes}
              visible={isOpen}
              time={timeEditable}
              setVisible={setIsOpen}
              inline={true}
              inlineActive={inlineActive}
              setInlineActive={setInlineActive}
            />
            <div
              onClick={() => {
                // Let's gooooo

                setIsOpen(false);
                startFocusMode("timer");
              }}
              className="dp-button"
              style={{ marginTop: "20px" }}
            >
              <PlayIcon className="dp-button-icon" />
              <span>Enter Focus Mode</span>
            </div>
            <div
              onClick={() => {
                startFocusMode("stopwatch");
              }}
              className="dp-button-small"
            >
              or set a timer with unlimited time
            </div>
          </div>
        </Modal>
      </Default>
      <Mobile>
        <Sheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          initialSnap={1}
          snapPoints={[800, 500, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="duration-picker-container-mobile">
                <div className="dp-title">
                  How long would you like to focus?
                </div>
                <DurationPickerInput
                  hours={hours}
                  minutes={minutes}
                  setHours={setHours}
                  setMinutes={setMinutes}
                  visible={isOpen}
                  time={timeEditable}
                  setVisible={setIsOpen}
                  inline={true}
                  inlineActive={inlineActive}
                  setInlineActive={setInlineActive}
                />
                <div
                  onClick={() => {
                    // Let's gooooo

                    setIsOpen(false);
                    startFocusMode("timer");
                  }}
                  className="dp-button"
                >
                  <PlayIcon className="dp-button-icon" />
                  <span>Enter Focus Mode</span>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(false);
            }}
          />
        </Sheet>
      </Mobile>
    </>
  );
}

function secondsToHms(d) {
  if (!d) {
    return null;
  }

  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}
