import "./DurationPicker.css";

import Sheet from "react-modal-sheet";

import "react-datepicker/dist/react-datepicker.css";

import gbicon from "../../../images/gb1-icon.jpeg";

import { focusModeBackgrounds } from "../../../utils";
import { Mobile, Default } from "../../../mediaUtils";
import { Modal } from "antd";

export default function FocusBGPicker({ isOpen, setIsOpen, setBg }) {
  return (
    <>
      <Default>
        <Modal
          title={null}
          footer={null}
          closeIcon={null}
          closable={false}
          width={400}
          visible={isOpen}
          onOk={() => {
            setIsOpen(false);
          }}
          onCancel={() => {
            setIsOpen(false);
          }}
          zIndex={100000000}
        >
          <div className="duration-picker-container-mobile">
            <div className="dp-title">Choose a background</div>
            <div className="bg-options">
              {Object.values(focusModeBackgrounds).map((data, index) => (
                <BGOption
                  key={data.id}
                  setIsOpen={setIsOpen}
                  data={data}
                  setBg={setBg}
                />
              ))}
            </div>
          </div>
        </Modal>
      </Default>
      <Mobile>
        <Sheet
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          initialSnap={1}
          snapPoints={[800, 500, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div className="duration-picker-container-mobile">
                <div className="dp-title">Choose a background</div>
                <div className="bg-options">
                  {Object.values(focusModeBackgrounds).map((data, index) => (
                    <BGOption
                      key={data.id}
                      setIsOpen={setIsOpen}
                      data={data}
                      setBg={setBg}
                    />
                  ))}
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(false);
            }}
          />
        </Sheet>
      </Mobile>
    </>
  );
}

function BGOption({ setIsOpen, data, setBg }) {
  return (
    <div
      onClick={() => {
        setBg(data);
        setIsOpen(false);
      }}
      className="bg-option"
    >
      <img className="bg-icon" src={data.image} />
      <span>{data.name}</span>
    </div>
  );
}
