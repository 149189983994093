import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Column.css";

import Card from "../Card";
import CreateCard from "../Card/CreateCard";

import { useHotkeys } from "react-hotkeys-hook";
import { useInViewport } from "react-in-viewport";

import { setColumnSelected } from "../../../redux/appSlice";
import ColumnHeader from "./ColumnHeader";
import moment from "moment";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Droppable from "../../DnDContainer/Containers/Droppable";
import SortableCard from "../../DnDContainer/Containers/Sortable";

function ColumnBody({
  order,
  date,
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
  mini,
}) {
  const { data: taskData } = useSelector((state) => state.tasks);

  return (
    <Droppable id={date} mini={mini}>
      <SortableContext
        id={date}
        items={order}
        strategy={verticalListSortingStrategy}
      >
        {order.map((taskId, index) => {
          if (taskData[taskId]) {
            return (
              <SortableCard columnId={date} id={taskId} key={taskId}>
                <Card
                  index={index}
                  item={taskData[taskId]}
                  key={taskId + "_card"}
                  activelySelected={indexSelected === index}
                  manuallySelectColumn={manuallySelectColumn}
                  navigatedViaKeyboard={navigatedViaKeyboard}
                  columnId={date}
                  setIndexSelected={setIndexSelected}
                  isDragging={true}
                />
              </SortableCard>
            );
          } else {
            return <SortableCard columnId={date} id={taskId} key={taskId} />;
          }
        })}
      </SortableContext>
    </Droppable>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.order.length === next.order.length &&
    prev.order.every((taskId, index) => taskId === next.order[index]) &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(ColumnBody, areEqual);
