import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { Modal } from "antd";
import { AiOutlineApple, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { Checkbox } from "antd";

import { updateCurrentUser, setUpgradeVisible } from "../../redux/appSlice";
import _, { set } from "lodash";
import { FcGoogle } from "react-icons/fc";
import { TbPlus } from "react-icons/tb";
import { BsPlus } from "react-icons/bs";
import { googleServerUrl } from "../../utils";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { RiAppStoreFill, RiAppStoreLine } from "react-icons/ri";
import { FaAppStoreIos } from "react-icons/fa";

export default function AddCalendar({ children }) {
  const userId = useSelector((state) => state.app.uid);

  const [modalActive, setModalActive] = useState(false);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const dispatch = useDispatch();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal
        title={null}
        footer={null}
        closeIcon={null}
        closable={false}
        width={500}
        visible={modalActive}
        onCancel={() => {
          setModalActive(false);
        }}
      >
        <div className="add-calendar-modal">
          <div className="integration-preview">
            <img
              className="integration-image"
              src={require("../../images/cal_integcation.png")}
            />
            <div className="integration-title">
              Google/Apple Calendar events next to your time-boxed tasks 🤯
            </div>
          </div>
          <div className="add-calendar-container primary">
            <div className="add-calendar-text">
              <FcGoogle className="gcal-icon" />
              <span>Google Calendar</span>
            </div>
            <div
              onClick={() => {
                // Use axios to call google login in a new window
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  window.open(
                    googleServerUrl + "/googleLogin?userId=" + userId
                  );
                }
              }}
              className="button"
            >
              Connect
            </div>
          </div>
          <div className="add-calendar-container primary">
            <div className="add-calendar-text">
              <AiOutlineApple className="gcal-icon" />
              <span>Apple Calendar</span>
              <span className="ios-chip">
                <FaAppStoreIos className="ios-icon" />
                iOS Only
              </span>
            </div>
            <div className="button disabled">Connect</div>
          </div>
        </div>{" "}
      </Modal>
      {!children && (
        <div
          onClick={(e) => {
            // Stop propagation of the click event
            setModalActive(true);
          }}
          className="last-sync-text"
        >
          <BsPlus className="last-sync-icon" />
          <span>Add calendar account</span>
        </div>
      )}
      {children && (
        <div
          onClick={(e) => {
            // Stop propagation of the click event
            setModalActive(true);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
