import { useEffect, useState } from "react";
import { Dropdown, Menu, Modal } from "antd";
import {
  deleteTask,
  updateTask,
  updateTaskOrder,
} from "../../redux/tasksSlice";
import { setCardModalActive } from "../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";

import "./CardModal.css";

import TextareaAutosize from "react-textarea-autosize";

import DurationPicker from "../Kanban/Card/Components/DurationPicker";
import CompleteInput from "../Kanban/Card/Components/CompleteInput";
import LabelSelector from "../Kanban/Card/Components/LabelSelector";
import DatePickerDropdown from "../Sidebar/DatePicker/DatePickerDropdown";
import Subtasks from "../Kanban/Card/Components/Subtasks";

import _ from "lodash";
import moment from "moment";
import { FiMoreVertical, FiX } from "react-icons/fi";

import styled from "@emotion/styled";
import { TbCalendar, TbRepeat } from "react-icons/tb";
import { RiTimeLine } from "react-icons/ri";

import RecurringInput from "../Kanban/Card/Components/RecurringInput";
import {
  MdArrowDropDownCircle,
  MdOutlineArrowDropDownCircle,
} from "react-icons/md";
import { TiArrowRepeat } from "react-icons/ti";

export default function CardModal(labelManager) {
  const { cardModalActiveFor } = useSelector((state) => state.app);
  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const dispatch = useDispatch();

  const item = useSelector(
    (state) => state.tasks.data[state.app.cardModalActiveFor]
  );

  const [descriptionEditable, setDescriptionEditable] = useState(
    item?.description || ""
  );

  const [notesEditable, setNotesEditable] = useState(item?.notes || "");

  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);

  const taskOrder = useSelector((state) => state.tasks.order);

  async function updateOrder(date, order) {
    dispatch(
      updateTaskOrder({
        date: date,
        order: order,
        previousOrder: taskOrder[date]?.order,
      })
    );
  }

  async function updateItemEditableValue(key, value, save = false) {
    if (key === "date") {
      // If key is "date", we need to update the task order as well
      var oldDate = moment(item?.date).format("YYYY-MM-DD");
      var newDate = moment(value).format("YYYY-MM-DD");

      if (oldDate !== newDate) {
        // Let's deal with the removal first

        var orderToRemove = _.cloneDeep(taskOrder[oldDate || "brain_dump"]);

        // TODO: NEED TO ACCOUNT FOR UPDATING ORDER IF WE HAVE A NON LOADED DATE
        const updatedOrderToRemove = orderToRemove.order
          ? orderToRemove.order.filter((task) => task !== item?.id)
          : [];

        updateOrder(oldDate || "brain_dump", updatedOrderToRemove);

        // If not null, add it to the relevant date
        var orderToAddTask = _.cloneDeep(taskOrder[newDate || "brain_dump"]);

        var updatedNewOrder = [];

        if (orderToAddTask && orderToAddTask.order) {
          updatedNewOrder = orderToAddTask.order;
          updatedNewOrder.unshift(item.id);
        } else {
          updatedNewOrder = [item.id];
        }

        updateOrder(newDate || "brain_dump", updatedNewOrder);
      }
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            date: value,
            start: value,
          },
        })
      );
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            [key]: value,
          },
        })
      );
    }
  }

  useEffect(() => {
    setDescriptionEditable(item?.description || "");
    setNotesEditable(item?.notes || "");
  }, [item]);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <span
          onClick={() => {
            dispatch(
              deleteTask({
                taskId: item?.id,
                currentTask: item,
              })
            );
          }}
        >
          Delete
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={600}
      open={cardModalActiveFor !== null && item !== null}
      onOk={() => {
        dispatch(setCardModalActive(null));
      }}
      onCancel={() => {
        dispatch(setCardModalActive(null));
      }}
      className="amazing-modal"
    >
      <div className="create-task-modal">
        <div className="card-modal-header editing">
          <CompleteInput
            item={item}
            complete={item?.complete}
            style={{
              width: "20px",
              height: "20px",
              flex: "0 0 20px",
              marginTop: "13px",
              borderRadius: "5px",
            }}
            noCheckbox={true}
          />

          <TextareaAutosize
            onBlur={() => {
              dispatch(
                updateTask({
                  taskId: item?.id,
                  currentTask: item,
                  newData: {
                    description: descriptionEditable,
                  },
                })
              );
            }}
            placeholder="Enter a description"
            className="card-modal-description"
            value={descriptionEditable}
            onChange={(e) => {
              setDescriptionEditable(e.target.value);
            }}
            onKeyPress={(e) => {
              // Detect if enter is pressed and save the task
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            style={{}}
          />

          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="more-icon-container">
              <FiMoreVertical />
            </div>
          </Dropdown>
        </div>

        <div className="card-modal-body">
          <div className="modal-card-properties">
            <div className="modal-property">
              <div className="title">
                <TbCalendar className="icon" />
                Task date
              </div>
              <div className="value">
                <DatePickerDropdown
                  dateSelected={item?.start || item?.date || null}
                  isVisible={datePickerVisible}
                  setIsVisible={setDatePickerVisible}
                  onDateChange={(date) => {
                    //   setDatePickerVisible(false);
                    updateItemEditableValue("date", date, true);
                  }}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Esimated time
              </div>
              <div className="value">
                <DurationPicker
                  time={
                    !isNaN(item?.estimated_time) ? item?.estimated_time : null
                  }
                  item={item}
                  title="Estimated"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("estimated_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={estimatedTimeActive}
                  setDurationPickerActive={setEstimatedTimeActive}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Actual time
              </div>
              <div className="value">
                <DurationPicker
                  time={!isNaN(item?.actual_time) ? item?.actual_time : null}
                  item={item}
                  title="Actual"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("actual_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={actualTimeActive}
                  setDurationPickerActive={setActualTimeActive}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Label
              </div>
              <div className="value">
                <LabelSelector
                  label={item?.label}
                  item={item}
                  labelPickerActive={labelPickerActive}
                  setLabelPickerActive={setLabelPickerActive}
                  buttonMode={true}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <TiArrowRepeat className="icon" />
                Repeats
              </div>
              <div className="value">
                <RecurringInput
                  item={item}
                  recurringTaskMenuOpen={recurringTaskMenuOpen}
                  setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                  modalMode={true}
                />
              </div>
            </div>
          </div>

          <div className="modal-card-body-notes">
            <div className="modal-card-body-notes-header">Notes</div>
            <TextareaAutosize
              onBlur={() => {
                dispatch(
                  updateTask({
                    taskId: item?.id,
                    currentTask: item,
                    newData: {
                      notes: notesEditable,
                    },
                  })
                );
              }}
              placeholder="Add any notes to the task"
              className="card-modal-notes"
              value={notesEditable}
              onChange={(e) => {
                setNotesEditable(e.target.value);
              }}
              style={{}}
            />
          </div>
          <div
            className="modal-card-body-notes subtasks"
            style={{
              paddingBottom: "0px",
              borderTop: "none",
            }}
          >
            <div
              className="modal-card-body-notes-header"
              style={{ marginBottom: "0px" }}
            >
              Subtasks
            </div>
            <Subtasks
              item={item}
              activelySelected={true}
              subtasksActive={true}
              setSubtasksActive={null}
              modalMode={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const ModalWrapper = styled.div`
  .ant-modal-body {
    padding: 15px important!;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
`;
