import React, { useEffect, useRef, useState } from "react";

import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export default function Droppable({
  children,
  id,
  braindump = false,
  hide_sidebar = false,
  mini = false,
}) {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div
      className={`draggable-container ${braindump || mini ? "braindump" : ""}`}
      ref={setNodeRef}
      style={{
        width: braindump && hide_sidebar ? "0px" : "100%",
      }}
    >
      {children}
    </div>
  );
}
