import "./CompleteInput.css";
import React from "react";

import { CheckIcon } from "@heroicons/react/24/solid";

import { useDispatch, useSelector } from "react-redux";

import {
  updateTask,
  moveTaskToBottom,
  moveTaskToBottomFromBrainDump,
  moveTaskToBottomOfIncomplete,
} from "../../../../../redux/tasksSlice";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import { BsCheck, BsCheck2, BsCheckLg } from "react-icons/bs";

export default function CompleteInput({
  noCheckbox = false,
  item,
  updateCompleteLocal,
  complete,
  style,
  activelySelected,
}) {
  const dispatch = useDispatch();

  const { move_task_on_complete_disabled = false } = useSelector(
    (state) => state.app.currentUser
  );

  function updateComplete(newValue) {
    if (updateCompleteLocal) {
      updateCompleteLocal(newValue);
    } else {
      if (newValue == true) {
        window.mixpanel.track("Task completed", {
          task_id: item.id,
          task_creation_date: item.created_at,
        });
      } else {
        window.mixpanel.track("Task uncompleted", {
          task_id: item.id,
        });
      }
      // If there is no date, then we are marking something from the braindump as complete
      // So let's set the date to today
      // Then move it out of the brain dump and at the bottom of today's order
      if (!item.date) {
        // This is a brain dump task
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              complete: newValue,
              completed_at: newValue ? new Date() : null,
              date: new Date(),
            },
          })
        );

        // Move task to bottom from brain dup
        dispatch(
          moveTaskToBottomFromBrainDump({
            taskId: item.id,
          })
        );
      } else {
        dispatch(
          updateTask({
            taskId: item.id,
            currentTask: item,
            newData: {
              completed_at: newValue ? new Date() : null,
              complete: newValue,
            },
          })
        );

        if (!move_task_on_complete_disabled) {
          // If we are marking as complete, move it to the bottom of the order

          // Get date in format YYYY-MM-DD
          const dateString = moment(item.date).format("YYYY-MM-DD");

          if (newValue) {
            dispatch(
              moveTaskToBottom({
                taskId: item.id,
                date: dateString,
              })
            );
          } else {
            // We are marking as incomplete, move it to the bottom of the incomplete tasks
            dispatch(
              moveTaskToBottomOfIncomplete({
                taskId: item.id,
                date: dateString,
              })
            );
          }
        }
      }
    }
  }

  useHotkeys(
    "c",
    (e) => {
      e.preventDefault();

      if (complete) {
        updateComplete(false);
      } else {
        updateComplete(true);
      }
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected, complete]
  );

  return (
    <>
      {complete && (
        <>
          {noCheckbox ? (
            <BsCheck
              onClick={(event) => {
                event.stopPropagation();
                updateComplete(false);
              }}
              className="card-complete-button complete"
              style={style}
              data-no-dnd="true"
            />
          ) : (
            <BsCheck
              onClick={(event) => {
                event.stopPropagation();
                updateComplete(false);
              }}
              className="card-complete-button round complete"
              style={style}
              data-no-dnd="true"
            />
          )}
        </>
      )}

      {!complete && (
        <>
          {noCheckbox ? (
            <div
              onClick={(event) => {
                event.stopPropagation();
                updateComplete(true);
              }}
              className="card-complete-button incomplete"
              style={style}
              data-no-dnd="true"
            />
          ) : (
            <BsCheck
              onClick={(event) => {
                event.stopPropagation();
                updateComplete(true);
              }}
              className="card-complete-button round incomplete"
              style={style}
              data-no-dnd="true"
            />
          )}
        </>
      )}
    </>
  );
}
