import { useEffect, useState } from "react";
import { Modal, Popover } from "antd";

import { setToastVisible, toggleLabelManager } from "../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

import UpdateLabel from "./UpdateLable";
import CreateLabel from "../Kanban/Card/Components/LabelSelector/CreateLabel";
import { createLabel } from "../../redux/labelsSlice";

import { setUpgradeVisible } from "../../redux/appSlice";
import UpgradeBanner from "../Upgrade/UpgradeBanner";

import { FiMoreHorizontal } from "react-icons/fi";

import { BiChevronLeft, BiPencil, BiSearch, BiTrash } from "react-icons/bi";
import { updateLabel, deleteLabel } from "../../redux/labelsSlice";
import { ChromePicker, TwitterPicker } from "react-color";
import { HexColorPicker, HexColorInput } from "react-colorful";

import { v4 as uuidv4 } from "uuid";

import { MdColorize } from "react-icons/md";

import { BsCheck } from "react-icons/bs";

export default function LabelManager(labelManager) {
  const dispatch = useDispatch();

  const { data: labels, loading } = useSelector((state) => state.labels);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const [createLabelActive, setCreateLabelActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="lm">
      <UpgradeBanner featureName="Labels" />

      <div className="lm-body">
        <div className="lm-header">
          <div className="search-bar">
            <BiSearch className="search-bar-icon" />
            <input
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="search-bar-input"
              placeholder="Search labels"
              type="text"
            />
          </div>
          <div
            className="button"
            onClick={() => {
              if (!subscriptionActive) {
                dispatch(setUpgradeVisible(true));
              } else {
                setCreateLabelActive(true);
              }
            }}
          >
            Create Label
          </div>
        </div>

        {createLabelActive && (
          <LabelCreator setCreateLabelActive={setCreateLabelActive} />
        )}

        {Object.values(labels)
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .filter((label) => {
            if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          })
          .map((label) => {
            return <LabelEditable key={"label-" + label.id} label={label} />;
          })}
      </div>
    </div>
  );
}

function LabelCreator({ setCreateLabelActive }) {
  const dispatch = useDispatch();

  const [labelEditable, setLabelEditable] = useState({
    name: "",
    color: "#000000",
    id: uuidv4(),
  });

  const [colorPickerActive, setColorPickerActive] = useState(false);

  return (
    <div className="label-editable editing">
      <div className="meta">
        <Popover
          visible={colorPickerActive}
          onVisibleChange={(visible) => {
            setColorPickerActive(visible);
          }}
          trigger="click"
          placement="bottom"
          content={
            <div className="color-picker-container">
              <ColorPicker
                color={labelEditable.color}
                onChange={(color) => {
                  setLabelEditable({
                    ...labelEditable,
                    color: color,
                  });
                }}
              />
            </div>
          }
        >
          <div
            onClick={() => {
              setColorPickerActive(!colorPickerActive);
            }}
            className="label-color-picker"
          >
            <div className="color-picker-color-outer">
              <div
                className="color-picker-color"
                style={{
                  backgroundColor: labelEditable.color,
                }}
              />
            </div>
          </div>
        </Popover>

        <input
          value={labelEditable.name}
          onChange={(e) =>
            setLabelEditable({
              ...labelEditable,
              name: e.target.value,
            })
          }
          placeholder="Label Name"
          className="label-name-input"
        />
      </div>

      <div
        className="action-menu-items"
        style={{
          columnGap: "8px",
        }}
      >
        <div
          onClick={() => {
            // Reset the label
            setLabelEditable({
              name: "",
              color: "#000000",
              id: uuidv4(),
            });
            setCreateLabelActive(false);
          }}
          className="button"
        >
          Cancel
        </div>
        <div
          onClick={() => {
            // Check if the name is blank
            if (labelEditable.name === "") {
              dispatch(
                setToastVisible({
                  toastType: "error",
                  message: "Label name cannot be blank",
                })
              );
              return;
            }

            dispatch(createLabel(labelEditable));
            setCreateLabelActive(false);
            dispatch(
              setToastVisible({
                toastType: "success",
                message: "Label created",
              })
            );
          }}
          className="button primary"
        >
          Create
        </div>
      </div>
    </div>
  );
}

function LabelEditable({ key, label }) {
  const [colorPickerActive, setColorPickerActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [labelEditable, setLabelEditable] = useState(label);

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelEditable(label);
  }, [label]);

  if (!isEditing) {
    return (
      <div className="label-editable">
        <div className="meta">
          <div
            className="label-editable-color"
            style={{ backgroundColor: label.color }}
          />
          <div className="label-editable-name">{label.name}</div>
        </div>

        <div className="action-menu-items">
          <div
            onClick={() => {
              setIsEditing(true);
            }}
            className="action-menu"
          >
            <BiPencil className="label-editable-icon" />
          </div>
          <div
            onClick={() => {
              dispatch(
                deleteLabel({
                  labelId: label.id,
                  currentLabel: label,
                })
              );
            }}
            className="action-menu"
          >
            <BiTrash className="label-editable-icon" />
          </div>
        </div>
      </div>
    );
  }

  if (isEditing) {
    return (
      <div className="label-editable editing">
        <div className="meta">
          <Popover
            placement="bottom"
            visible={colorPickerActive}
            onVisibleChange={(visible) => {
              setColorPickerActive(visible);
            }}
            content={
              <ColorPicker
                color={labelEditable.color}
                onChange={(color) => {
                  setLabelEditable({
                    ...labelEditable,
                    color: color,
                  });
                }}
              />
            }
            // Make sure click outside closes the color picker
            trigger="click"
          >
            <div
              onClick={() => {
                setColorPickerActive(!colorPickerActive);
              }}
              className="label-color-picker"
            >
              <div className="color-picker-color-outer">
                <div
                  className="color-picker-color"
                  style={{
                    backgroundColor: labelEditable.color,
                  }}
                />
              </div>
            </div>
          </Popover>
          <input
            value={labelEditable.name}
            onChange={(e) =>
              setLabelEditable({
                ...labelEditable,
                name: e.target.value,
              })
            }
            placeholder="Label Name"
            className="label-name-input"
          />
        </div>

        <div
          className="action-menu-items"
          style={{
            columnGap: "8px",
          }}
        >
          <div
            onClick={() => {
              setIsEditing(false);
            }}
            className="button"
          >
            Cancel
          </div>
          <div
            onClick={() => {
              dispatch(
                updateLabel({
                  labelId: label.id,
                  newData: labelEditable,
                  previousLabel: label,
                })
              );
              setIsEditing(false);
            }}
            className="button primary"
          >
            Save
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export function ColorPicker({ color, onChange }) {
  const [customPickerActive, setCustomPickerActive] = useState(false);

  // Beautiful beautiful colors from Tailwinds
  const colors = [
    "#A3A3A3",
    "#FF5865",
    "#FF9558",
    "#FFE148",
    "#56e099",
    "#58a9ff",
    "#ae58ff",
    "#ff58ec",
  ];

  return (
    <div className="color-picker-container">
      {customPickerActive ? (
        <div className="custom-color-picker-contianer">
          <div className="header">
            <div
              onClick={() => {
                setCustomPickerActive(false);
              }}
              className="picker-back-button"
            >
              <BiChevronLeft className="chevron" />
              Back
            </div>
          </div>
          <HexColorPicker color={color} onChange={onChange} />
          <div className="hex-input-container">
            <div>HEX #</div>
            <HexColorInput
              color={color}
              onChange={onChange}
              className="hexColorInput"
            />
          </div>
        </div>
      ) : (
        <div className="color-picker-row">
          {/* A row of colors */}
          {colors.map((colorOption) => {
            return (
              <div
                onClick={() => {
                  onChange(colorOption);
                }}
              >
                <div
                  className="option"
                  style={{
                    backgroundColor: colorOption,
                  }}
                >
                  {color === colorOption && <BsCheck className="checked" />}
                </div>
              </div>
            );
          })}

          <div className="palette-container">
            <div
              onClick={() => {
                setCustomPickerActive(true);
              }}
              className="option palette"
              style={{
                backgroundColor: color,
              }}
            >
              <MdColorize className="color-picker-icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
