import React from "react";
import { useSelector } from "react-redux";
import "./Calendar.css";

import CalendarSettings from "./CalendarSettings";
import AddCalendar from "./AddCalendar";

import { IoSync } from "react-icons/io5";

import Moment from "react-moment";
import { Tooltip } from "antd";
import { last } from "lodash";

export default function MiniToolbar({
  loadCalendarEvents,
  lastSync,
  setLastSync,
}) {
  const {
    hide_calendar_sidebar,
    move_task_on_complete_disabled = false,
    active_calendars = {},
    calendar_accounts,
  } = useSelector((state) => state.app.currentUser);

  return (
    <div className="mini-toolbar-calendar">
      {calendar_accounts && (
        <div>
          <div
            onClick={(e) => {
              loadCalendarEvents(active_calendars);
            }}
            className="last-sync-icon-container"
          >
            <Tooltip
              title={
                lastSync ? (
                  <span>
                    Last synced{" "}
                    <Moment interval={1000} fromNow>
                      {lastSync}
                    </Moment>{" "}
                  </span>
                ) : (
                  <span>Syncing...</span>
                )
              }
            >
              <IoSync
                className={
                  lastSync ? "last-sync-icon" : "last-sync-icon animated"
                }
              />
            </Tooltip>
          </div>
        </div>
      )}

      <CalendarSettings />
    </div>
  );
}
