import React, { useState, useEffect, useRef } from "react";
import "./Card.css";

import TextareaAutosize from "react-textarea-autosize";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import RecurringInput from "./Components/RecurringInput";
import TimeSection from "./Components/TimeSection";
import Subtasks from "./Components/Subtasks";
import { useInViewport } from "react-in-viewport";

import { updateTask, deleteTask } from "../../../redux/tasksSlice";

import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";

import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../DnDContainer/ItemTypes";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

export default function CardPreview({ taskId }) {
  const item = useSelector((state) => state.tasks.data[taskId]);

  if (!item) return null;

  return (
    <div id={taskId} className="card-drag-preview">
      <div className={`card-container ${item?.complete ? " complete" : ""}`}>
        <div className="card-header">
          <div className="card-description">{item?.description}</div>

          <div className="card-time-estimate">
            {!isNaN(item.estimated_time)
              ? moment.utc(item.estimated_time * 1000).format("H:mm")
              : "0:00"}
          </div>
        </div>
        <div className="card-footer">
          <div className="card-left-buttons">
            <CompleteInput item={item} complete={item?.complete} />
            <RecurringInput item={item} />
            <SubtasksIcon item={item} preview={true} />
          </div>

          <LabelSelector
            label={item?.label}
            key={item.id + "_label_selector"}
            item={item}
          />
        </div>
        <TimeSection item={item} />

        <Subtasks item={item} />
      </div>
    </div>
  );
}
