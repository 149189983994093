import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { LogoutIcon } from "@heroicons/react/24/outline";

import { updateCurrentUser } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import SettingsAvatar from "./SettingsAvatar";

import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

import { getAuth, signOut } from "firebase/auth";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export default function Account() {
  const { currentUser } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [nameTemp, setNameTemp] = useState(currentUser.name);

  const [changePasswordActive, setChangePasswordActive] = useState(false);
  const [changeEmailActive, setChangeEmailActive] = useState(false);

  useEffect(() => {
    setNameTemp(currentUser.name);
  }, [currentUser]);

  const auth = getAuth();
  const user = auth.currentUser;

  return (
    <div>
      {changeEmailActive && (
        <ChangeEmail setChangeEmailActive={setChangeEmailActive} />
      )}
      {changePasswordActive && (
        <ChangePassword setChangePasswordActive={setChangePasswordActive} />
      )}

      {!changeEmailActive && !changePasswordActive && (
        <div className="settings">
          <SettingsAvatar
            url={currentUser?.avatar_url}
            onUpload={(url) => {
              dispatch(
                updateCurrentUser({
                  newValues: { avatar_url: url },
                  previousValues: { avatar_url: currentUser?.avatar_url },
                })
              );
            }}
            name={currentUser.name}
          />
          <div className="settings-item">
            <div className="settings-item-title">Name</div>
            <input
              className="settings-item-input"
              value={nameTemp}
              onChange={(e) => {
                setNameTemp(e.target.value);
              }}
              placeholder="Name"
              onBlur={() => {
                // Update user with name
                if (currentUser.name !== nameTemp) {
                  dispatch(
                    updateCurrentUser({
                      newValues: { name: nameTemp },
                      previousValues: { name: currentUser.name },
                    })
                  );
                }
              }}
            />
          </div>
          <div className="settings-item">
            <div className="settings-item-title">Email</div>
            <div className="sl">
              <Default>
                {" "}
                <div>{user.email}</div>
                <div
                  onClick={() => {
                    setChangeEmailActive(true);
                  }}
                  className="button"
                >
                  Change Email
                </div>
              </Default>
              <Mobile>
                <div
                  onClick={() => {
                    setChangeEmailActive(true);
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>{user.email}</div>
                  <ChevronRightIcon className="chevron-right-icon" />
                </div>
              </Mobile>
            </div>
          </div>
          <Default>
            <div className="settings-item">
              <div className="settings-item-title">Password</div>
              <div className="sl">
                <div
                  onClick={() => {
                    setChangePasswordActive(true);
                  }}
                  className="button"
                >
                  Change Password
                </div>
              </div>
            </div>
          </Default>

          <Mobile>
            <div
              onClick={() => {
                setChangePasswordActive(true);
              }}
              className="settings-item"
            >
              <div className="settings-item-title">Password</div>
              <div className="sl">
                <ChevronRightIcon className="chevron-right-icon" />
              </div>
            </div>
          </Mobile>
        </div>
      )}
    </div>
  );
}
