import React, { useState, useEffect, useRef } from "react";
import "./Card.css";

import TextareaAutosize from "react-textarea-autosize";

import { ClipboardListIcon, TrashIcon } from "@heroicons/react/24/outline";

import CompleteInput from "./Components/CompleteInput";
import LabelSelector from "./Components/LabelSelector";
import RecurringInput from "./Components/RecurringInput";
import TimeSection from "./Components/TimeSection";
import Subtasks from "./Components/Subtasks";
import { useInViewport } from "react-in-viewport";

import { updateTask, deleteTask } from "../../../redux/tasksSlice";

import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";

import SubtasksIcon from "./Components/Subtasks/SubtaskIcon";

import { ItemTypes } from "../../DnDContainer/ItemTypes";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import _ from "lodash";
import { setCardModalActive } from "../../../redux/appSlice";

function Card({
  item,
  index,
  activelySelected,
  manuallySelectColumn,
  navigatedViaKeyboard = false,
  columnId,
}) {
  const ref = useRef(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(ref);

  const dispatch = useDispatch();
  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const [timeSectionActive, setTimeSectionActive] = useState(false);
  const [subtasksActive, setSubtasksActive] = useState(false);
  const [descriptionEditable, setDescriptionEditable] = useState(
    item?.description || ""
  );
  const taskInputRef = useRef(null);

  const { label_filters: activeLabels, hide_complete } = useSelector(
    (state) => state.app?.currentUser
  );

  useEffect(() => {
    if (navigatedViaKeyboard && activelySelected && !inViewport) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [activelySelected, inViewport, navigatedViaKeyboard, ref]);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);

  useHotkeys(
    "cmd+l",
    (e) => {
      e.preventDefault();

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      if (timeSectionActive) {
        setTimeSectionActive(false);
      }

      if (subtasksActive) {
        setSubtasksActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, labelPickerActive, subtasksActive, timeSectionActive]
  );

  useHotkeys(
    "cmd+i",
    (e) => {
      e.preventDefault();

      if (timeSectionActive) {
        setTimeSectionActive(false);
        // Let's focus on input
      } else {
        setTimeSectionActive(true);
      }

      // ... set up our own saving dialog.
      if (subtasksActive) {
        setSubtasksActive(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
      }
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, timeSectionActive, subtasksActive, labelPickerActive]
  );

  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();

      if (subtasksActive) {
        setSubtasksActive(false);
      } else {
        setSubtasksActive(true);
      }

      if (timeSectionActive) {
        setTimeSectionActive(false);
      }

      if (labelPickerActive) {
        setLabelPickerActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected, subtasksActive, timeSectionActive, labelPickerActive]
  );

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      // if taskInputRef is focused, unfocus
      if (taskInputRef.current.contains(document.activeElement)) {
        taskInputRef.current.blur();
      } else {
        // if taskInputRef is not focused, focus

        if (!labelPickerActive && !timeSectionActive && !subtasksActive) {
          taskInputRef.current.focus();
        }
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        activelySelected &&
        !labelPickerActive &&
        !timeSectionActive &&
        !subtasksActive,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      activelySelected,
      labelPickerActive,
      taskInputRef,
      subtasksActive,
      timeSectionActive,
    ]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      // if taskInputRef is focused, unfocus
      if (taskInputRef.current.contains(document.activeElement)) {
        taskInputRef.current.blur();
      }

      if (!labelPickerActive) {
        manuallySelectColumn(columnId, -1);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, taskInputRef, labelPickerActive]
  );

  useHotkeys(
    "cmd+backspace, ctrl+backspace",
    (e) => {
      e.preventDefault();

      dispatch(deleteTask({ taskId: item.id, currentTask: item }));

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
    },
    [activelySelected]
  );

  useEffect(() => {
    if (item.description && item.description !== descriptionEditable) {
      setDescriptionEditable(item.description);
    }
  }, [item?.description]);

  useEffect(() => {
    // Close everything is item is marked as complete
    if (item.complete) {
      setLabelPickerActive(false);
      setTimeSectionActive(false);
      setSubtasksActive(false);
    }
  }, [item?.complete]);

  function generateItemStartTime(item) {
    var startTime = null;
    var itemDate = null;

    if (item?.start?.toDate) {
      startTime = item?.start?.toDate();
    }

    if (item?.date?.toDate) {
      itemDate = item?.date?.toDate();
    }

    if (item?.start && moment(item?.start).isValid()) {
      startTime = item?.start;
    }

    if (item?.date && moment(item?.date).isValid()) {
      itemDate = item?.date;
    }

    if (!startTime || !itemDate) {
      return null;
    }

    // If startTime and date are the day, it is valid
    if (moment(startTime).isSame(itemDate, "day")) {
      return moment(startTime).format("h:mma");
    }

    return null;
  }

  return (
    <Popover
      placement="right"
      title={null}
      content={
        <div className="card-context-menu">
          <div
            onClick={() => {
              dispatch(deleteTask({ taskId: item.id, currentTask: item }));
            }}
            className="context-menu-row"
          >
            <TrashIcon className="context-mmenu-row-icon" />
            Delete
          </div>
        </div>
      }
      trigger="contextMenu"
      id="card-context-menu"
    >
      <div
        taskId={item.id}
        className={`card-container ${item.complete ? " complete" : ""} ${
          activelySelected ? " activelySelected" : ""
        }`}
        onMouseEnter={() => {
          if (activelySelected) {
            return;
          }
          if (manuallySelectColumn && !navigatedViaKeyboard) {
            manuallySelectColumn(columnId, index);
          }
        }}
        onMouseLeave={() => {
          if (
            activelySelected &&
            manuallySelectColumn &&
            !navigatedViaKeyboard
          ) {
            if (!taskInputRef.current.contains(document.activeElement)) {
              manuallySelectColumn(columnId, -1);
            }
          }
        }}
        onClick={(e) => {
          // Don't active if any children buttons are clicked
          if (
            e.target.className.includes("card-container") ||
            e.target.className.includes("card-header") ||
            e.target.className.includes("card-content") ||
            e.target.className.includes("card-footer") ||
            e.target.className.includes("card-left-buttons")
          ) {
            dispatch(setCardModalActive(item.id));
          }
        }}
        id={item.id}
        style={
          (activeLabels &&
            activeLabels.length !== 0 &&
            !activeLabels?.includes(item.label)) ||
          (item.complete && hide_complete)
            ? { display: "none" }
            : {}
        }
        ref={ref}
      >
        <div className="card-header">
          <TextareaAutosize
            onBlur={() => {
              if (item.description !== descriptionEditable) {
                dispatch(
                  updateTask({
                    taskId: item?.id,
                    currentTask: item,
                    newData: {
                      description: descriptionEditable,
                    },
                  })
                );
              }
            }}
            onFocus={() => {
              if (activelySelected) {
                return;
              }
              if (manuallySelectColumn && !navigatedViaKeyboard) {
                manuallySelectColumn(columnId, index);
              }
            }}
            ref={taskInputRef}
            className="card-description"
            value={descriptionEditable}
            onChange={(e) => {
              setDescriptionEditable(e.target.value);
            }}
            data-no-dnd="true"
          />

          <div
            onClick={(event) => {
              setTimeSectionActive(!timeSectionActive);
            }}
            className="card-time-estimate"
            data-no-dnd="true"
          >
            {!isNaN(item.estimated_time)
              ? moment.utc(item.estimated_time * 1000).format("H:mm")
              : "0:00"}
          </div>
        </div>
        <div className="card-footer">
          <div className="card-left-buttons">
            <CompleteInput
              item={item}
              complete={item?.complete}
              activelySelected={activelySelected}
            />
            {generateItemStartTime(item) && (
              <div className="task-time">{generateItemStartTime(item)}</div>
            )}

            <RecurringInput
              item={item}
              recurringTaskMenuOpen={recurringTaskMenuOpen}
              setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
            />
            <SubtasksIcon
              action={() => {
                setSubtasksActive(!subtasksActive);
              }}
              item={item}
            />
          </div>

          <LabelSelector
            label={item?.label}
            key={item.id + "_label_selector"}
            item={item}
            labelPickerActive={labelPickerActive}
            setLabelPickerActive={setLabelPickerActive}
          />
        </div>
        <TimeSection
          item={item}
          activelySelected={activelySelected}
          timeSectionActive={timeSectionActive}
          setTimeSectionActive={setTimeSectionActive}
        />

        <Subtasks
          item={item}
          activelySelected={activelySelected}
          subtasksActive={subtasksActive}
          setSubtasksActive={setSubtasksActive}
          modalMode={false}
        />
      </div>
    </Popover>
  );
}
// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check the item prop using lodash _.isEqual
  return (
    _.isEqual(prev.item, next.item) &&
    prev.isDragging === next.isDragging &&
    prev.activelySelected === next.activelySelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard &&
    prev.index === next.index
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(Card, areEqual);
