import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
} from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Select, Switch } from "antd";
import { FcGoogle } from "react-icons/fc";
import { TbPlus } from "react-icons/tb";

import axios from "axios";
import { googleServerUrl } from "../../utils";
import { AiOutlineApple } from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";

const { Option } = Select;

export default function Calendars() {
  const { currentUser } = useSelector((state) => state.app);
  const userId = useSelector((state) => state.app.uid);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const dispatch = useDispatch();

  function disconnectCalendar(accountId) {
    axios
      .get(`${googleServerUrl}/removeGoogleAccount`, {
        params: {
          userId: userId,
          accountId: accountId,
        },
      })
      .then((response) => {
        dispatch(
          setToastVisible({
            toastType: "success",
            message: "Google account disconnected",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          setToastVisible({
            toastType: "success",
            message: "Error disconnecting account, please contact support",
          })
        );
      });
  }
  return (
    <div>
      <div className="settings">
        <Default>
          {currentUser.calendar_accounts &&
            Object.values(currentUser.calendar_accounts).map((account) => {
              return (
                <div className="add-calendar-container added">
                  <div className="add-calendar-text added">
                    <FcGoogle className="gcal-icon" />
                    <span>{account.email}</span>
                  </div>
                  <div
                    onClick={() => {
                      disconnectCalendar(account.id);
                    }}
                    className="disconnect-button"
                  >
                    Disconnect
                  </div>
                </div>
              );
            })}

          {currentUser.calendar_accounts && (
            <div
              onClick={() => {
                // Use axios to call google login in a new window
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  window.open(
                    googleServerUrl + "/googleLogin?userId=" + userId
                  );
                }
              }}
              className="add-calendar-container"
            >
              <div className="add-calendar-text">
                <FcGoogle className="gcal-icon" />
                <span>Connect a Google Calendar account</span>
              </div>
              <TbPlus className="plus-icon" />
            </div>
          )}

          {!currentUser.calendar_accounts && (
            <>
              <div
                className="integration-preview"
                style={{ marginTop: "15px" }}
              >
                <img
                  className="integration-image"
                  src={require("../../images/cal_integcation.png")}
                />
                <div>
                  <div className="integration-title">
                    Google/Apple Calendar events next to your time-boxed tasks
                    🤯
                  </div>
                </div>
              </div>
              <div className="add-calendar-container primary">
                <div className="add-calendar-text">
                  <FcGoogle className="gcal-icon" />
                  <span>Google Calendar</span>
                </div>

                <div
                  onClick={() => {
                    // Use axios to call google login in a new window
                    if (!subscriptionActive) {
                      dispatch(setUpgradeVisible(true));
                    } else {
                      window.open(
                        googleServerUrl + "/googleLogin?userId=" + userId
                      );
                    }
                  }}
                  className="button"
                >
                  Connect
                </div>
              </div>
              <div className="add-calendar-container primary">
                <div className="add-calendar-text">
                  <AiOutlineApple className="gcal-icon" />
                  <span>Apple Calendar</span>
                  <span className="ios-chip">
                    <FaAppStoreIos className="ios-icon" />
                    iOS Only
                  </span>
                </div>
                <div className="button disabled">Connect</div>
              </div>
            </>
          )}
        </Default>
      </div>
    </div>
  );
}
