import "./RecurringInput.css";
import { useState, useRef, useEffect } from "react";
import { BiTrash, BiStopCircle, BiCopy, BiRightArrowAlt } from "react-icons/bi";
import { TiArrowRepeat } from "react-icons/ti";
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsCheck } from "react-icons/bs";
import {
  getRecurrenceText,
  weekdays,
  months,
  ordinal_suffix_of,
} from "../../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { RRule } from "rrule";
import moment from "moment";
import {
  stopRecurringTask,
  deleteAllRecurringInstances,
  updateIncompleteInstances,
  createRecurringTask,
} from "../../../../../redux/recurringTasksSlice";

import { addMinutes } from "date-fns";

import { setUpgradeVisible } from "../../../../../redux/appSlice";

import {
  updateTask,
  updateRecurringTask,
} from "../../../../../redux/tasksSlice";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { generateTemplateFromTask } from "../../../../../utils";

export default function RecurringTaskMenu({
  task,
  setRecurringTaskMenuOpen,
  recurringTaskLocal,
  updateRecurringLocal,
  creation,
}) {
  const dispatch = useDispatch();

  const [recurrencePickerActive, setRecurrencePickerActive] = useState(false);
  const recurringTask = useSelector(
    (state) => state.tasks.recurringTasks[task.recurring_id]
  );

  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  function createRecurringTaskFromRule(recurrenceRule) {
    if (!subscriptionActive) {
      dispatch(setUpgradeVisible(true));
    } else {
      if (recurrenceRule) {
        var newRecurringTask = {
          id: uuidv4(),
          rrule: recurrenceRule.toString(),
          branched_tasks: [task.id],
          exclusions: [moment(task.date).format("YYYY-MM-DD")],
          task_template: generateTemplateFromTask(task),
        };

        if (creation) {
          updateRecurringLocal(newRecurringTask);
        } else {
          dispatch(createRecurringTask({ recurringTask: newRecurringTask }));
          // Let's update the task and make it recurring
          dispatch(
            updateTask({
              taskId: task.id,
              currentTask: task,
              newData: {
                recurring: true,
                recurring_id: newRecurringTask.id,
              },
            })
          );
        }
      } else {
        if (creation) {
          updateRecurringLocal(null);
        }
      }
    }
  }

  function generateRecurringOptions() {
    // Get the date of the task
    const tzOffset = new Date().getTimezoneOffset();

    // local Monday to utc Monday
    const toRRuleInput = (date) => {
      return addMinutes(date, -tzOffset); // using date-fn lib here
    };

    var date = task.date;

    // convert date to string with z at the end
    var dateStr = moment(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";


   // date = new Date(dateStr);



    if (!date) {
      return null;
    }

    // Get the day of the week [0-6]
    const dayOfWeek = date.getDay();

    // dayOfWeekWhere0IsMonday
    const dayOfWeekWhere0IsMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    // Get the day of the month [1-31]
    const dayOfMonth = date.getDate();

    // Get the ordinal number of the week of the month [1-5]
    var weekOfMonth = Math.ceil(dayOfMonth / 7);

    // If the day is the last day of the month, set the ordinal number to -1
    if (dayOfMonth === getLastDayOccurence(date, dayOfWeek).getDate()) {
      weekOfMonth = -1;
    }

    const dailyRule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: toRRuleInput(date),
    });

    const weeklyRule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: toRRuleInput(date),
      byweekday: [dayOfWeekWhere0IsMonday],
    });

    const biweeklyRule = new RRule({
      freq: RRule.WEEKLY,
      interval: 2,
      dtstart: toRRuleInput(date),
      byweekday: [dayOfWeekWhere0IsMonday],
    });

    const weekdayRule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: toRRuleInput(date),
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    });

    const weekendRule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: toRRuleInput(date),
      byweekday: [RRule.SA, RRule.SU],
    });

    const monthlyRule = new RRule({
      freq: RRule.MONTHLY,
      dtstart: toRRuleInput(date),
      interval: 1,
      byweekday: [dayOfWeekWhere0IsMonday],
      bysetpos: [weekOfMonth],
    });

    const yearlyRule = new RRule({
      freq: RRule.YEARLY,
      dtstart: toRRuleInput(date),
      interval: 1,
      bymonth: date.getMonth() + 1,
      bymonthday: dayOfMonth,
    });

    var options = [
      {
        title: "Does not repeat",
        recurrenceRule: null,
        subTitle: "",
      },
      {
        title: "Every day",
        recurrenceRule: dailyRule,
        subTitle: "",
      },
      {
        title: "Every weekday",
        subTitle: "(Mon - Fri)",
        recurrenceRule: weekdayRule,
      },
      {
        title: "Every week",
        subTitle: `(on ${weekdays[dayOfWeek]})`,
        recurrenceRule: weeklyRule,
      },
      {
        title: "Every 2 weeks",
        subTitle: `(on ${weekdays[dayOfWeek]})`,
        recurrenceRule: biweeklyRule,
      },
      {
        title: "Every month",
        subTitle: `(on the ${
          weekOfMonth == -1 ? "last" : ordinal_suffix_of(weekOfMonth)
        } ${weekdays[dayOfWeek]})`,
        recurrenceRule: monthlyRule,
      },
      {
        title: "Every year",
        subTitle: `(on ${months[date.getMonth()]} ${ordinal_suffix_of(
          dayOfMonth
        )})`,
        recurrenceRule: yearlyRule,
      },
    ];

    // If the date is on the weekend, replace 3rd option with "Every weekend"
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      options[2] = {
        title: "Every weekend day",
        subTitle: "(Sat & Sun)",
        recurrenceRule: weekendRule,
      };
    }

    return options;
  }

  const options = generateRecurringOptions();

  const recurrenceText = getRecurrenceText({
    recurringTask: creation ? recurringTaskLocal : recurringTask,
  });

  if (!options) {
    return (
      <div className="ellie-menu">
        Tasks in the braindump cannot be recurring
      </div>
    );
  }

  return (
    <div className="ellie-menu" data-no-dnd="true">
      {(recurrencePickerActive || !task.recurring || creation) && (
        <>
          <div className="ellie-menu-header">
            <div className="ellie-menu-header-text">
              {task.recurring ? "Update recurrence" : "Make task recurring"}
            </div>
            {creation ||
              (task.recurring && (
                <IoCloseCircleSharp
                  onClick={() => {
                    setRecurrencePickerActive(false);
                  }}
                  className="ellie-menu-close-icon"
                />
              ))}
          </div>
          {options.map((option, index) => (
            <div
              key={option.recurrenceRule?.toString() || "no-recurrence"}
              onClick={() => {
                // Get rrule string
                const rrule = option.recurrenceRule
                  ? option.recurrenceRule.toString()
                  : null;

                // If this is a recurring task, we need to update the recurring task with the new recurrence rule
                if (!creation && task.recurring) {
                  if (rrule) {
                    dispatch(
                      updateRecurringTask({
                        recurringTaskId: recurringTask.id,
                        currentRecurringTask: recurringTask,
                        newData: {
                          rrule: rrule,
                        },
                      })
                    );
                  } else {
                    // We are making it not repeating, basically delete

                    // Stop recurring
                    dispatch(
                      stopRecurringTask({
                        recurringTask: recurringTask,
                        taskToStop: task,
                      })
                    );

                    setRecurringTaskMenuOpen(false);
                  }
                } else {
                  // If it is not, let's create a new recurring task
                  createRecurringTaskFromRule(rrule);
                  setRecurringTaskMenuOpen(false);
                }
              }}
              className="ellie-menu-item"
            >
              <div className="elie-menu-item-text">
                {option.title}
                <span className="elie-menu-item-subtitle">
                  {option.subTitle}
                </span>
              </div>
              {recurrenceText.replace(/\s/g, "") ==
                `${option.title} ${option.subTitle}`.replace(/\s/g, "") && (
                <BsCheck className="ellie-menu-item-check" />
              )}
            </div>
          ))}
        </>
      )}
      {!creation && task.recurring && !recurrencePickerActive && (
        <>
          <div
            onClick={() => {
              setRecurrencePickerActive(true);
            }}
            className="ellie-menu-item clickable"
          >
            <TiArrowRepeat className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">{recurrenceText}</div>
            <BiRightArrowAlt className="ellie-menu-item-arrow" />
          </div>
          <div
            onClick={() => {
              dispatch(
                stopRecurringTask({
                  recurringTask: recurringTask,
                  taskToStop: task,
                })
              );

              setRecurringTaskMenuOpen(false);
            }}
            className="ellie-menu-item"
          >
            {" "}
            <BiStopCircle className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">Stop repeating</div>
          </div>
          <div
            onClick={() => {
              dispatch(
                updateIncompleteInstances({
                  recurringTask: recurringTask,
                  taskToMatch: task,
                })
              );
              setRecurringTaskMenuOpen(false);
            }}
            className="ellie-menu-item"
          >
            <BiCopy className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">
              {" "}
              Update all incomplete tasks to match this task
            </div>
          </div>
          <div
            onClick={() => {
              dispatch(
                deleteAllRecurringInstances({
                  recurringTask: recurringTask,
                })
              );
              setRecurringTaskMenuOpen(false);
            }}
            className="ellie-menu-item"
          >
            <BiTrash className="ellie-menu-item-icon" />
            <div className="elie-menu-item-text">Delete all instances</div>
          </div>
        </>
      )}
    </div>
  );
}

// Function to get the last instance of a month
function getLastDayOccurence(date, dayOfWeek) {
  var result = moment(date).endOf("month");

  while (result.day() !== dayOfWeek) {
    result.subtract(1, "day");
  }

  return result.toDate();
}
