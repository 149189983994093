import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { ChevronRightIcon, ArrowSmLeftIcon } from "@heroicons/react/24/outline";

import AddTaskCard from "../Kanban/Column/AddTaskCard";

import Card from "../Kanban/Card";

import { updateCurrentUser, setColumnSelected } from "../../redux/appSlice";

import CreateCard from "../Kanban/Card/CreateCard";
import { Resizable } from "re-resizable";
import { useSpring, animated as a } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import logo from "../../images/ellie-solid.svg";
import BraindumpBody from "./BraindumpBody";

export default function Sidebar({ brainDumpOrder = [], onDragEnd, saveOrder }) {
  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);
  const columnRef = useRef(null);

  const columnSelected = useSelector(
    (state) => state.app.columnSelected === "braindump"
  );
  const { data: tasks } = useSelector((state) => state.tasks);

  const { hide_sidebar, sidebar_width = 280 } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();

  const [createActive, setCreateActive] = useState(null);

  useHotkeys("b", (e) => {
    e.preventDefault();

    // Set create active to today's date
    setCreateActive(true);

    // ... set up our own saving dialog.
  });

  const sidebarSpringProps = useSpring({
    width: hide_sidebar ? 0 : sidebar_width || 280,
    overflow: hide_sidebar ? "hidden" : "visible",
  });

  const sidebarToggleSpringProps = useSpring({
    opacity: hide_sidebar ? 1 : 0,
    display: hide_sidebar ? "flex" : "none",
  });

  const [indexSelected, setIndexSelected] = useState(null);

  useEffect(() => {
    if (createActive) {
      setIndexSelected(null);
    } else {
      if (!columnSelected && indexSelected !== null) {
        setIndexSelected(null);
      }

      if (columnSelected && indexSelected === null) {
        setIndexSelected(0);
      }
    }
  }, [indexSelected, createActive]);

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();

      var filteredBraindump = brainDumpOrder.filter((taskId) => {
        if (tasks[taskId]) {
          return true;
        }
        return false;
      });

      if (indexSelected === null) {
        setIndexSelected(0);
      } else if (indexSelected < filteredBraindump.length - 1) {
        setNavigatedViaKeyboard(true);
        setIndexSelected(indexSelected + 1);
      }

      // ... set up our own saving dialog.P
    },
    {
      enabled: columnSelected && !createActive,
    },
    [indexSelected, columnSelected, tasks, brainDumpOrder]
  );

  useHotkeys("shift+b", (e) => {
    e.preventDefault();

    // Set create active to today's date
    setIndexSelected(0);
    dispatch(setColumnSelected("braindump"));

    // ... set up our own saving dialog.
  });

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();

      if (indexSelected === null) {
        return;
      }

      if (indexSelected > 0) {
        setNavigatedViaKeyboard(true);
        setIndexSelected(indexSelected - 1);

        var filteredBraindump = brainDumpOrder.filter((taskId) => {
          if (tasks[taskId]) {
            return true;
          }
          return false;
        });
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: columnSelected && !createActive,
    },
    [indexSelected, columnSelected, brainDumpOrder]
  );

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  useEffect(() => {
    if (!columnSelected && indexSelected !== null) {
      setIndexSelected(null);
    }
  }, [columnSelected]);

  /*
  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: "task",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      };
    },
    hover(item, monitor) {
      const isOverInner = monitor.isOver();
      const isOverCurrentInner = monitor.isOver({ shallow: true });

      if (!columnRef.current || !isOverCurrentInner) {
        return;
      }

      const sourceColumn = item.columnId;
      const destinationColumn = "braindump";

      const taskDragged = item.id;

      // Don't replace items with themselves
      if (sourceColumn === destinationColumn) {
        return;
      }

      var orderFiltered = brainDumpOrder.filter((taskId) => {
        if (tasks[taskId]) {
          return true;
        }
        return false;
      });

      const dragIndex = item.index;
      const hoverIndex = orderFiltered?.length || 0; // destination index

      // Time to actually perform the action
      onDragEnd({
        source: {
          droppableId: sourceColumn,
          index: dragIndex,
        },
        destination: {
          droppableId: destinationColumn,
          index: hoverIndex,
        },
        draggableId: taskDragged,
        type: "task",
        isHover: true,
      });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      item.columnId = destinationColumn;
    },
    drop(item, monitor) {
      const isOverCurrent = monitor.isOver({ shallow: true });

      if (isOverCurrent && saveOrder) {
        saveOrder(tasks[item.id], "braindump");
      }
    },
  });

  drop(columnRef); */

  return (
    <a.div
      className="sidebar"
      style={sidebarSpringProps}
      onMouseMove={() => {
        if (navigatedViaKeyboard) {
          setNavigatedViaKeyboard(false);
        }
      }}
    >
      <Resizable
        size={{ width: sidebar_width || 280, height: "100vh" }}
        minHeight={"100vh"}
        className="sidebar-container"
        maxWidth={"400px"}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResize={(e, direction, ref, d) => {
          const newWidth = (sidebar_width || 280) + d.width;

          if (newWidth < 180) {
            // Let's set the width to 0px

            dispatch(
              updateCurrentUser({
                newValues: {
                  hide_sidebar: true,
                },
                previousValues: {
                  hide_sidebar: false,
                },
              })
            );
          }
        }}
        onResizeStop={(e, direction, ref, d) => {
          const newWidth = (sidebar_width || 280) + d.width;

          dispatch(
            updateCurrentUser({
              newValues: {
                sidebar_width: newWidth,
              },
              previousValues: {
                sidebar_width: sidebar_width || 280,
              },
            })
          );
        }}
      >
        <div className="braindump-topbar">
          <img src={logo} alt="logo" className="sidebar-logo" />
        </div>
        <div className="braindump-header">
          <div className="braindump-header-title-container">
            <div className="braindump-header-title">🧠 Brain Dump</div>
          </div>

          <AddTaskCard setCreateActive={setCreateActive} />
          {createActive && (
            <CreateCard
              setCreateActive={setCreateActive}
              setNavigatedViaKeyboard={setNavigatedViaKeyboard}
            />
          )}
        </div>

        <BraindumpBody
          brainDumpOrder={brainDumpOrder}
          indexSelected={indexSelected}
          manuallySelectColumn={manuallySelectColumn}
          navigatedViaKeyboard={navigatedViaKeyboard}
          setIndexSelected={setIndexSelected}
          hide_sidebar={hide_sidebar}
        />
      </Resizable>
    </a.div>
  );
}
