import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

import { useMediaQuery } from "react-responsive";

import { XMarkIcon } from "@heroicons/react/24/outline";

import success from "../../images/success.gif";

import confetti from "canvas-confetti";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CalendarSuccess() {
  let query = useQuery();

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [calendarEmail, setCalendarEmail] = useState("");

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    var calendarEmail = query.get("calendarAdded");

    const calendarError = query.get("calendarError");

    if (calendarEmail) {
      window.mixpanel.track("Calendar linked", {
        source: "Web",
        type: "Google",
      });

      navigate("/");
      setShow(true);
      setCalendarEmail(calendarEmail);
    }

    if (calendarError) {
      window.mixpanel.track("Calendar error", {
        source: "Web",
        reason: calendarError,
        type: "Google",
      });
      navigate("/");
    }
  }, [query]);

  return (
    <div>
      <Modal
        closable={false}
        footer={null}
        style={{ top: isMobile ? "20px" : "30px" }}
        bodyStyle={{ padding: "0px" }}
        className={"light-theme"}
        visible={show}
        width={450}
        zIndex={10000000000}
        onCancel={() => setShow(false)}
      >
        <div className="upgrade-container">

          <div className="top-half" style={{ padding: "20px" }}>
            <div
              onClick={() => {
                setShow(false);
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="success-h1">🥳 Google calendar added</div>
            <div className="success-h2">
              {calendarEmail} was successfully linked, you can now use it in the
              timebox mode. <br />
              <br /> Pro tip: you can click the "eye" icon on the bottom right
              of the timebox to toggle your calendars
            </div>
          </div>
          <img className="success-image" src={success} />
        </div>
      </Modal>
    </div>
  );
}
