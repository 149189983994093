import React, { useState, useRef, useEffect } from "react";

import "./CardModal.css";
import _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import CompleteInput from "../Kanban/Card/Components/CompleteInput";
import LabelSelector from "../Kanban/Card/Components/LabelSelector";
import Subtasks from "../Kanban/Card/Components/Subtasks";
import SubtasksIcon from "../Kanban/Card/Components/Subtasks/SubtaskIcon";

import { createTask } from "../../redux/tasksSlice";
import { createRecurringTask } from "../../redux/recurringTasksSlice";

import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { RiCloseLine, RiTimeLine } from "react-icons/ri";

import {
  setToastVisible,
  setColumnSelected,
  setCreateTaskModalActive,
} from "../../redux/appSlice";

import TimeSection from "../Kanban/Card/Components//TimeSection";
import { useHotkeys } from "react-hotkeys-hook";
import RecurringInput from "../Kanban/Card/Components//RecurringInput";
import DurationPicker from "../Kanban/Card/Components/DurationPicker";
import { generateTemplateFromTask } from "../../utils";
import { Modal } from "antd";
import { TbCalendar, TbTag } from "react-icons/tb";
import DatePickerDropdown from "../Sidebar/DatePicker/DatePickerDropdown";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";

export default function CreateTaskModal({}) {
  const { active, date, start_date, duration } = useSelector(
    (state) => state.app.createTaskModalActive || {}
  );

  const { label_filters: activeLabels } = useSelector(
    (state) => state.app?.currentUser
  );

  const dispatch = useDispatch();
  const [itemEditable, setItemEditable] = useState({
    date: start_date ? start_date : new Date(),
    id: uuidv4(),
    complete: false,
    start: start_date,
    estimated_time: duration,
  });

  useEffect(() => {
    if (active) {
      setItemEditable({
        ...itemEditable,
        date: start_date ? start_date : new Date(),
        start: start_date,
        estimated_time: duration,
      });
    }
  }, [active, date, start_date, duration]);

  const [recurringTaskMenuOpen, setRecurringTaskMenuOpen] = useState(false);
  const [recurringTask, setRecurringTask] = useState(null);

  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [labelPickerActive, setLabelPickerActive] = useState(false);
  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);
  const taskInputRef = useRef(null);
  async function updateItemEditableValue(key, value, save = false) {
    setItemEditable((tempItemEditable) => {
      return {
        ...tempItemEditable,
        [key]: value,
      };
    });
  }

  const [subtasksActive, setSubtasksActive] = useState(false);

  useHotkeys(
    "cmd+l",
    (e) => {
      e.preventDefault();

      if (labelPickerActive) {
        setLabelPickerActive(false);
        // Let's focus on input
      } else {
        setLabelPickerActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, labelPickerActive]
  );

  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();

      if (subtasksActive) {
        setSubtasksActive(false);
      } else {
        setSubtasksActive(true);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
    },
    [active, subtasksActive]
  );

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      if (!isBlank(itemEditable.description)) {
        createTaskFunction(true);
      } else {
        dispatch(setCreateTaskModalActive(null));
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        active && !labelPickerActive && !estimatedTimeActive && !subtasksActive
          ? true
          : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [
      active,
      itemEditable,
      taskInputRef,
      labelPickerActive,
      estimatedTimeActive,
      subtasksActive,
    ]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      dispatch(setCreateTaskModalActive(null));

      // ... set up our own saving dialog.
    },
    {
      enabled: active ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [active, taskInputRef]
  );

  useEffect(() => {
    // If there is no label selected, but there is 1 label in activeLabels, select it
    if (!itemEditable.label && activeLabels && activeLabels.length === 1) {
      updateItemEditableValue("label", activeLabels[0]);
    }
  }, [activeLabels?.length, itemEditable.label]);

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  useEffect(() => {
    if (itemEditable.recurring && recurringTask) {
      updateItemEditableValue("recurring_id", recurringTask.id, true);
    } else {
      updateItemEditableValue("recurring_id", null, true);
    }
  }, [itemEditable.recurring, recurringTask]);

  function createTaskFunction() {
    // If task description is not blank
    if (!isBlank(itemEditable.description)) {
      // If no labels are selected for this item, and there is more than 1 label filter active,
      // then notify the user that the item was hidden
      if (itemEditable.label == null && activeLabels?.length > 1) {
        dispatch(
          setToastVisible({
            toastType: "success",
            message:
              "Task was created but hidden because you have active filters",
          })
        );
      }

      // Also create the recurring task, if there is one
      if (recurringTask) {
        const template = generateTemplateFromTask(itemEditable);
        const recurringTaskTemp = _.cloneDeep(recurringTask);
        recurringTaskTemp.task_template = template;
        dispatch(createRecurringTask({ recurringTask: recurringTaskTemp }));
      }

      // Let's go ahead and create
      dispatch(createTask(itemEditable));

      setRecurringTask(null);

      setItemEditable({
        date: date ? moment(date, "YYYY-MM-DD").toDate() : null,
        description: "",
        estimated_time: null,
        label: null,
        id: uuidv4(),
        complete: false,
      });
      dispatch(setCreateTaskModalActive(null));
    } else {
      dispatch(
        setToastVisible({
          toastType: "error",
          message: "Description cannot be blank. Please enter a description.",
        })
      );
    }
  }

  return (
    <Modal
      title={null}
      footer={null}
      closeIcon={null}
      closable={false}
      width={600}
      visible={active}
      destroyOnClose={true}
      onOk={() => {
        dispatch(setCreateTaskModalActive(null));
      }}
      onCancel={() => {
        dispatch(setCreateTaskModalActive(null));
      }}
      className="amazing-modal"
    >
      <div className="create-task-modal">
        <div className="card-modal-header editing">
          <TextareaAutosize
            className="card-modal-description create-textarea"
            ref={taskInputRef}
            value={itemEditable.description}
            onChange={(e) => {
              updateItemEditableValue("description", e.target.value);
            }}
            placeholder="Enter a description"
            autoFocus
            onKeyPress={(e) => {
              // Detect if enter is pressed and save the task
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            style={{}}
          />
        </div>

        <div className="card-modal-body">
          <div className="modal-card-properties">
            <div className="modal-property">
              <div className="title">
                <TbCalendar className="icon" />
                Task date
              </div>
              <div className="value">
                <DatePickerDropdown
                  dateSelected={itemEditable.date}
                  isVisible={datePickerVisible}
                  setIsVisible={setDatePickerVisible}
                  onDateChange={(date) => {
                    updateItemEditableValue("date", date, true);
                    updateItemEditableValue("start", date, true);
                    //   setDatePickerVisible(false);
                  }}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Esimated time
              </div>
              <div className="value">
                <DurationPicker
                  time={
                    !isNaN(itemEditable.estimated_time)
                      ? itemEditable.estimated_time
                      : null
                  }
                  item={itemEditable}
                  title="Estimated"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("estimated_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={estimatedTimeActive}
                  setDurationPickerActive={setEstimatedTimeActive}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <RiTimeLine className="icon" />
                Actual time
              </div>
              <div className="value">
                <DurationPicker
                  time={
                    !isNaN(itemEditable.actual_time)
                      ? itemEditable.actual_time
                      : null
                  }
                  item={itemEditable}
                  title="Actual"
                  updateTimeLocal={(newValue) => {
                    updateItemEditableValue("actual_time", newValue, true);
                  }}
                  buttonMode={true}
                  durationPickerActive={actualTimeActive}
                  setDurationPickerActive={setActualTimeActive}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Label
              </div>
              <div className="value">
                <LabelSelector
                  label={itemEditable.label}
                  updateLabelLocal={(newValue) => {
                    updateItemEditableValue("label", newValue, true);
                  }}
                  labelPickerActive={labelPickerActive}
                  setLabelPickerActive={setLabelPickerActive}
                  buttonMode={true}
                />
              </div>
            </div>

            <div className="modal-property">
              <div className="title">
                <MdOutlineArrowDropDownCircle className="icon" />
                Repeats
              </div>
              <div className="value">
                <RecurringInput
                  item={itemEditable}
                  recurringTaskLocal={recurringTask}
                  updateRecurringLocal={(newValue) => {
                    if (newValue) {
                      // There is a recurring task, let's set the itemEditable
                      updateItemEditableValue("recurring", true, true);
                    } else {
                      // There is no recurring task, let's set the itemEditable
                      updateItemEditableValue("recurring", false, true);
                    }
                    setRecurringTask(newValue);
                  }}
                  creation={true}
                  recurringTaskMenuOpen={recurringTaskMenuOpen}
                  setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
                  modalMode={true}
                />
              </div>
            </div>
          </div>

          <div className="modal-card-body-notes">
            <div className="modal-card-body-notes-header">Notes</div>
            <TextareaAutosize
              placeholder="Add any notes to the task"
              className="card-modal-notes"
              value={itemEditable?.notes}
              onChange={(e) => {
                updateItemEditableValue("notes", e.target.value);
              }}
              style={{}}
            />
          </div>

          <div
            className="modal-card-body-notes subtasks"
            style={{
              paddingBottom: "0px",
              borderTop: "none",
            }}
          >
            <div
              className="modal-card-body-notes-header"
              style={{ marginBottom: "0px" }}
            >
              Subtasks
            </div>
            <Subtasks
              item={itemEditable}
              updateSubtasksLocal={(newValue) => {
                updateItemEditableValue("subtasks", newValue, true);
              }}
              subtasksActive={true}
              setSubtasksActive={null}
              activelySelected={true}
              modalMode={true}
            />
          </div>

          <div className="mcf-footer">
            <div
              onClick={() => {
                dispatch(setCreateTaskModalActive(null));
              }}
              className="cancel-button"
            >
              Cancel
            </div>
            <div
              onClick={() => {
                createTaskFunction();
              }}
              className="button primary"
            >
              Create
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
