import "./Settings.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CogIcon,
  SupportIcon,
  AdjustmentsIcon,
  TagIcon,
  UserIcon,
  SparklesIcon,
  CalendarIcon,
  HeartIcon,
  HiCommandLine,
  CommandLineIcon,
  Cog6ToothIcon,
  CakeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/solid";

import { GrMagic } from "react-icons/gr";

import Account from "./Account";
import Customize from "./Customize";
import LabelManager from "../Labels/LabelManager";
import ManageSubscription from "./ManageSubscription";
import Support from "./Support";
import Calendars from "./Calendars";

import { Default, Mobile } from "../../mediaUtils";

import { getAuth, signOut } from "firebase/auth";
import { getInitials } from "../../utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IoClose } from "react-icons/io5";
import {
  ArrowLeftOnRectangleIcon,
  LogoutIcon,
} from "@heroicons/react/24/outline";

import { toggleSettings } from "../../redux/appSlice";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import KeyboardShortcuts from "../Generics/KeyboardShortcuts";

export default function SettingsContent() {
  const dispatch = useDispatch();
  const [active, setActive] = useState("account");

  const { labelManagerVisible } = useSelector((state) => state.app);

  const { avatar_url, name } = useSelector((state) => state.app.currentUser);

  useEffect(() => {
    if (labelManagerVisible) {
      setActive("labels");
    } else {
      setActive("account");
    }
  }, [labelManagerVisible]);

  const auth = getAuth();
  const user = auth.currentUser;

  const types = {
    account: "Account Settings",
    subscription: "Membership & Billing",
    labels: "Labels",
    customization: "Customize Ellie",
    support: "Help & Support",
    calendars: "Your Calendars",
  };

  const [shortcutsVisible, setShortcutsVisible] = useState(false);

  useEffect(() => {
    window.Canny("initChangelog", {
      appID: "637d2ce47a741d0ff12b8c61",
      position: "top",
      align: "right",
    });
  }, []);

  return (
    <div className="settings-container">
      <KeyboardShortcuts
        visible={shortcutsVisible}
        setVisible={setShortcutsVisible}
      />

      <Default>
        <div className="settings-sidebar">
          <div className="settings-sidebar-header">
            <div className="settings-sidebar-header-left">
              {avatar_url ? (
                <img
                  className="settings-sidebar-avatar"
                  src={avatar_url}
                  alt="avatar"
                />
              ) : (
                <div className="settings-sidebar-avatar-initials">
                  {getInitials(name)}
                </div>
              )}
            </div>
            <div className="settings-sidebar-header-right">
              <div className="settings-sidebar-header-right-name">{name}</div>
              <div className="settings-sidebar-header-right-email">
                {user.email}
              </div>
            </div>
          </div>

          <div className="settings-section">
            <div className="settings-section-header">User settings</div>
            <div
              onClick={() => {
                setActive("account");
              }}
              className={
                "settings-sidebar-item" +
                (active === "account" ? " active" : "")
              }
            >
              <Cog6ToothIcon className="settings-sidebar-icon" />
              Account Settings
            </div>
            <div
              onClick={() => {
                setActive("subscription");
              }}
              className={
                "settings-sidebar-item" +
                (active === "subscription" ? " active" : "")
              }
            >
              <SparklesIcon className="settings-sidebar-icon" />
              Subscription
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div className="settings-section-header">App settings</div>
            <div
              onClick={() => {
                setActive("customization");
              }}
              className={
                "settings-sidebar-item" +
                (active === "customization" ? " active" : "")
              }
            >
              <HeartIcon className="settings-sidebar-icon" />
              Personalization
            </div>
            <div
              onClick={() => {
                setActive("calendars");
              }}
              className={
                "settings-sidebar-item" +
                (active === "calendars" ? " active" : "")
              }
            >
              {" "}
              <CalendarIcon className="settings-sidebar-icon" />
              Calendars
            </div>

            <div
              onClick={() => {
                setActive("labels");
              }}
              className={
                "settings-sidebar-item" + (active === "labels" ? " active" : "")
              }
            >
              <TagIcon className="settings-sidebar-icon" />
              Labels
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div
              onClick={() => {
                setShortcutsVisible(true);
              }}
              className={"settings-sidebar-item"}
            >
              {" "}
              <CommandLineIcon className="settings-sidebar-icon" />
              Keyboard shortcuts
            </div>

            <a data-canny-changelog className={"settings-sidebar-item"}>
              <CakeIcon className="settings-sidebar-icon" />
              What's new
            </a>

            <div
              onClick={() => {
                setActive("support");
              }}
              className={
                "settings-sidebar-item" +
                (active === "support" ? " active" : "")
              }
            >
              {" "}
              <LifebuoyIcon className="settings-sidebar-icon" />
              Help & Support
            </div>

            <a
              href="https://feedback.ellieplanner.com/feature-requests"
              target="_blank"
              className={"settings-sidebar-item"}
            >
              <ChatBubbleOvalLeftEllipsisIcon className="settings-sidebar-icon" />
              Give feedback
            </a>
          </div>

          <div className="settings-section-divider" />

          <div
            onClick={async () => {
              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                })
                .catch((error) => {
                  // An error happened.
                });
            }}
            className="log-out-button"
          >
            <ArrowLeftOnRectangleIcon className="log-out-icon" />
            Log out
          </div>
        </div>

        <div className="settings-content">
          <div className="settings-content-header">
            <div>{types[active]}</div>
            <div
              onClick={() => {
                dispatch(toggleSettings());
              }}
              className="close-icon-container"
            >
              <IoClose className="close-icon" />
            </div>
          </div>
          <div className="content">
            {active === "account" && <Account />}
            {active === "customization" && <Customize />}
            {active === "labels" && <LabelManager />}
            {active === "support" && <Support />}
            {active === "subscription" && <ManageSubscription />}
            {active === "calendars" && <Calendars />}
          </div>
        </div>
      </Default>

      <Mobile>
        <div>
          <div className="account-nav-bar">
            {active !== "account" && (
              <div
                onClick={() => {
                  setActive("account");
                }}
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ChevronLeftIcon style={{ height: "25px" }} />
                <span>Back</span>
              </div>
            )}

            {active === "account" && <div style={{ width: "100px" }} />}

            <div>{pageTitles[active]}</div>

            <div style={{ width: "100px" }} />
          </div>

          {active === "account" && (
            <div className="account-page">
              <div className="account-page-header">
                {avatar_url && (
                  <img src={avatar_url} className="settings-avatar" />
                )}
                {!avatar_url && (
                  <div className="settings-avatar">{getInitials(name)}</div>
                )}

                <div className="settings-name">{name}</div>
                <div className="settings-email">{user.email}</div>
              </div>

              <div className="account-page-table">
                <SettingsCell
                  icon={
                    <UserIcon
                      className="account-page-cell-icon"
                      style={{ backgroundColor: "#878787" }}
                    />
                  }
                  title="Name"
                  description="Name, email, password, etc..."
                  onClick={() => {
                    setActive("my-account");
                  }}
                />

                <SettingsCell
                  icon={
                    <SparklesIcon
                      className="account-page-cell-icon"
                      style={{ backgroundColor: "#ffc800" }}
                    />
                  }
                  title="Subscription"
                  description="Manage your Ellie Subscription"
                  onClick={() => {
                    setActive("subscription");
                  }}
                />

                <SettingsCell
                  icon={
                    <TagIcon
                      className="account-page-cell-icon"
                      style={{ backgroundColor: "#00c506" }}
                    />
                  }
                  title="Labels"
                  description="Labels help you organize your tasks"
                  onClick={() => {
                    setActive("labels");
                  }}
                />

                <SettingsCell
                  icon={
                    <AdjustmentsVerticalIcon
                      className="account-page-cell-icon"
                      style={{ backgroundColor: "#2569ff" }}
                    />
                  }
                  title="Personalization"
                  description="Customize Ellie to fit your workflow"
                  onClick={() => {
                    setActive("customization");
                  }}
                />

                <SettingsCell
                  icon={
                    <LifebuoyIcon
                      className="account-page-cell-icon"
                      style={{ backgroundColor: "#6e25ff" }}
                    />
                  }
                  title="Help & Support"
                  description="View our resources and contact support"
                  onClick={() => {
                    setActive("support");
                  }}
                />
              </div>

              <div
                style={{ marginTop: "20px" }}
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      // Sign-out successful.
                    })
                    .catch((error) => {
                      // An error happened.
                    });
                }}
                className="settings-item"
              >
                <div className="settings-item-title">Log Out</div>
                <div className="sl">
                  <ChevronRightIcon className="chevron-right-icon" />
                </div>
              </div>
            </div>
          )}

          <div>
            {active === "my-account" && <Account />}
            {active === "customization" && <Customize />}
            {active === "labels" && <LabelManager />}
            {active === "subscription" && <ManageSubscription />}
            {active === "support" && <Support />}
            {active === "calendars" && <Calendars />}
          </div>
        </div>
      </Mobile>
    </div>
  );
}

const pageTitles = {
  account: "More",
  "my-account": "My Account",
  labels: "Manage Labels",
  customization: "Personalization",
  support: "Help & Support",
};

export function SettingsCell({ icon, title, description, onClick }) {
  return (
    <div onClick={onClick} className="account-page-cell">
      <div className="account-page-cell-left">
        {icon}
        <div className="account-page-meta">
          <div className="account-page-cell-title">{title}</div>
          <div className="account-page-cell-description">{description}</div>
        </div>
      </div>
      <ChevronRightIcon className="chevron-right-icon" />
    </div>
  );
}
