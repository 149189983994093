import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePicker.css";

import { useSelector, useDispatch } from "react-redux";

import {
  changeStartDate,
  loadDatesFromStartDate,
  refreshKabanCursor,
} from "../../../redux/appSlice";

export default function CustomDatePicker() {
  const { startDate } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const onChange = (dates) => {
    const [start] = dates;

    dispatch(changeStartDate(moment(start).format("YYYY-MM-DD")));
    dispatch(loadDatesFromStartDate(start.toString()));
    dispatch(refreshKabanCursor(moment(start).format("YYYY-MM-DD")));
  };

  return (
    <div className="date-picker-popover">
      <DatePicker
        selected={moment(startDate, "YYYY-MM-DD").toDate()}
        onChange={onChange}
        startDate={moment(startDate, "YYYY-MM-DD").toDate()}
        endDate={moment(startDate, "YYYY-MM-DD").add(5, "days").toDate()}
        selectsRange
        inline
      />
    </div>
  );
}
