import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import { updateCurrentUser } from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Select, Switch } from "antd";
const { Option } = Select;

export default function Support() {
  const { currentUser } = useSelector((state) => state.app);
  const userId = useSelector((state) => state.app.uid);
  const dispatch = useDispatch();

  return (
    <div>
      <div className="settings">
        <Default>
          <div className="settings-item">
            <div className="settings-item-title">Changelog</div>
            <div className="settings-item-value">
              <a
                href="https://feedback.ellieplanner.com/"
                className="settings-item-link"
              >
                What's new + What's upcoming{" "}
                <ArrowRightIcon className="settings-item-link-icon" />{" "}
              </a>
            </div>
          </div>

          <Default>
            <div className="settings-rowdivider" />
          </Default>

          <div className="settings-item">
            <div className="settings-item-title">Contact Support</div>
            <a href="mailto:chris@aloa.co" className="settings-item-link">
              chris@aloa.co{" "}
              <ArrowRightIcon className="settings-item-link-icon" />{" "}
            </a>
          </div>

          <Default>
            <div className="settings-rowdivider" />
          </Default>

          <div onClick={() => {}} className="settings-item">
            <div className="settings-item-title">User ID (for support)</div>
            <div className="settings-item-value">{userId}</div>
          </div>

          <Default>
            <div className="settings-rowdivider" />
          </Default>

          <div className="settings-item">
            <div className="settings-item-title">App Version</div>
            <div className="settings-item-value">1.37</div>
          </div>
        </Default>

        <Mobile>
          <div
            onClick={() => {
              window.open("https://feedback.ellieplanner.com/", "_blank");
            }}
            className="settings-item"
          >
            <div className="settings-item-title">
              {" "}
              What's new + What's upcoming
            </div>
            <div className="sl">
              <ChevronRightIcon className="chevron-right-icon" />
            </div>
          </div>

          <div
            onClick={() => {
              window.open("mailto:chris@aloa.co", "_blank");
            }}
            className="settings-item"
          >
            <div className="settings-item-title">Contact Support</div>
            <div className="sl">
              <ChevronRightIcon className="chevron-right-icon" />
            </div>
          </div>

          <div onClick={() => {}} className="settings-item">
            <div className="settings-item-title">User ID (for support)</div>
            <div className="sl">{userId}</div>
          </div>

          <div onClick={() => {}} className="settings-item">
            <div className="settings-item-title">App Version</div>
            <div className="sl">8</div>
          </div>
        </Mobile>
      </div>
    </div>
  );
}
