/* eslint-disable jsx-a11y/alt-text */

import Labels from "../../Labels";

import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";

import { updateCurrentUser } from "../../../redux/appSlice";

import Sheet from "react-modal-sheet";

export default function Filters({ isOpen, setIsOpen }) {
  const { hide_complete } = useSelector((state) => state.app.currentUser);

  const dispatch = useDispatch();

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      initialSnap={1}
      snapPoints={[800, 500, 0]}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="hc-container">
            Show Complete{" "}
            <Switch
              size="medium"
              checked={!hide_complete}
              defaultChecked
              onClick={() => {
                // Update the current user's hide_complete value
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      hide_complete: !hide_complete,
                    },
                    previousValues: {
                      hide_complete: hide_complete,
                    },
                  })
                );
              }}
            />
          </div>
          <div className="box">
            <Labels />
          </div>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      />
    </Sheet>
  );
}
