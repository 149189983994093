import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../../images/logo.svg";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";

export default function Onboarding({}) {
  const [step, setStep] = useState(1);

  const [useCases, setUseCases] = useState([]);
  const [name, setName] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(null);
  const navigate = useNavigate();

  const uid = useSelector((state) => state.app.uid);

  const finishSignup = async () => {
    await setDoc(
      doc(db, "users", uid),
      {
        name: name,
        avatar_url: avatarUrl,
        use_case: useCases,
      },
      {
        merge: true,
      }
    );

    window.mixpanel.track("Sign up step 3 completed", { source: "Web" });

    navigate("/");
  };

  return (
    <div className="auth-page">
    <div className="auth-left">
      <div className="auth-left-container">
        <img src={logo} className="auth-logo" alt="Ellie logo" />

        <div className="auth-modal-container">
      {step == 1 && (
        <>
          <h1 className="header">How do you plan on using Ellie?</h1>
          <p className="description">(You can select multiple)</p>
          <div className="multi-select-options">
            <SelectionOption
              id="school"
              icon="📚"
              title="For school"
              active={useCases.includes("school")}
              toggleActive={(id) => {
                if (useCases.includes(id)) {
                  setUseCases(useCases.filter((useCase) => useCase !== id));
                } else {
                  setUseCases([...useCases, id]);
                }
              }}
            />

            <SelectionOption
              id="work"
              icon="💼"
              title="For work"
              active={useCases.includes("work")}
              toggleActive={(id) => {
                if (useCases.includes(id)) {
                  setUseCases(useCases.filter((useCase) => useCase !== id));
                } else {
                  setUseCases([...useCases, id]);
                }
              }}
            />

            <SelectionOption
              id="personal"
              icon="🏠"
              title="For personal use"
              active={useCases.includes("personal")}
              toggleActive={(id) => {
                if (useCases.includes(id)) {
                  setUseCases(useCases.filter((useCase) => useCase !== id));
                } else {
                  setUseCases([...useCases, id]);
                }
              }}
            />
          </div>

          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                window.mixpanel.track("Sign up step 2 completed", {
                  source: "Web",
                  cases: useCases,
                });
                setStep(2);
              }}
              className="auth-button"
            >
              <span>Continue</span>
            </button>
          </div>
        </>
      )}

      {step == 2 && (
        <>
          <div className="profile-info">
            <h1 className="header">Let's add some details</h1>
            <UploadAvatar
              url={avatarUrl}
              setUrl={setAvatarUrl}
              uid={"avatars"}
            />

            <div>
              <div>Your name</div>
              <input
                className="auth-input"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              onClick={(e) => {
                e.preventDefault();

                finishSignup();
              }}
              className="auth-button"
            >
              <span>Continue</span>
            </button>
          </div>
        </>
      )}
    </div>
      </div>
    </div>
    <div className="auth-right"></div>
  </div>


  );
}

function SelectionOption({ id, icon, title, active, toggleActive }) {
  return (
    <div
      onClick={() => {
        toggleActive(id);
      }}
      className={`multi-select-option ${active ? "active" : ""}`}
    >
      <div className="option-description">
        <div className="multi-select-icon">{icon}</div>
        <div>{title}</div>
      </div>
      <div className={`option-circle ${active ? "active" : ""}`}>
        {active && <BsCheck />}
      </div>
    </div>
  );
}

function UploadAvatar({ url, setUrl, uid }) {
  const [uploading, setUploading] = useState(false);

  async function uploadAvatar(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];

      const storage = getStorage();

      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, "images/" + uid + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setUrl(downloadURL);
          });
        }
      );
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div className="upload-avatar">
      {url ? (
        <img src={url} alt="Avatar" className="empty-avatar" />
      ) : (
        <img
          src={require("../../../images/empty_avatar@3x.png")}
          className="empty-avatar"
        />
      )}

      <label className="upload-avatar-text" htmlFor="single">
        {uploading ? "Uploading ..." : "Upload avatar"}
        <input
          style={{
            visibility: "hidden",
            position: "absolute",
            width: "10px",
          }}
          type="file"
          id="single"
          accept="image/*"
          onChange={uploadAvatar}
          disabled={uploading}
        />
      </label>
    </div>
  );
}
