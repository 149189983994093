import React, { useState } from "react";
import "./DayView.css";

import { useSelector } from "react-redux";

import Card from "../Card";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

import moment from "moment";

import AddTaskCard from "../../Kanban/Column/AddTaskCard";

import CreateCard from "../../Kanban/Card/CreateCard";

export default function DayView({ isDragging, onDragEnd, saveOrder, order }) {
  const { data: taskData } = useSelector((state) => state.tasks);

  const [createActive, setCreateActive] = useState(false);

  const [activeDate, setActiveDate] = useState(moment().format("YYYY-MM-DD"));

  return (
    <div className="day-view">
      <div className="day-view-header">
        <ChevronLeftIcon
          onClick={() => {
            // Set active date to previous day
            setActiveDate(
              moment(activeDate, "YYYY-MM-DD")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
            );
          }}
          className="date-nav-button"
        />
        <div className="column-date-container">
          <div className="column-day-text">
            {moment(activeDate, "YYYY-MM-DD").format("ddd")}
          </div>
          <div className="column-date-text">
            {moment(activeDate, "YYYY-MM-DD").format("MMM D")}
          </div>
          {moment(activeDate, "YYYY-MM-DD").isSame(moment(), "d") && (
            <div className="today-text">Today</div>
          )}
        </div>
        <ChevronRightIcon
          onClick={() => {
            // Set active date to the next day
            setActiveDate(
              moment(activeDate, "YYYY-MM-DD")
                .add(1, "day")
                .format("YYYY-MM-DD")
            );
          }}
          className="date-nav-button"
        />
      </div>

      <div className="draggable-container">
        {order[activeDate] &&
          order[activeDate].order &&
          order[activeDate].order
            .filter((taskId) => {
              if (taskData[taskId]) {
                return true;
              }
              return false;
            })
            .map((taskId, index) => {
              return (
                <Card
                  index={index}
                  item={taskData[taskId]}
                  key={taskId + "_card"}
                  isDragging={isDragging}
                  onDragEnd={onDragEnd}
                  saveOrder={saveOrder}
                  columnId={activeDate}
                />
              );
            })}
      </div>
    </div>
  );
}
